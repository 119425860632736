import React, { FC, useEffect, useState } from "react";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  FontSizes,
  IButtonStyles,
  Label,
  mergeStyles,
  PrimaryButton,
  Stack,
  Text,
  TextField,
} from "@fluentui/react";
import { Document, Project } from "../../models";
import Loader from "../../components/Loader";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import {
  getFilesInFolders,
  getPDF,
  getPrecedentDocuments,
  grantPermissionFolder,
} from "../../modules/redux/Documents/document";
import { getAllProjectDetails } from "../../modules/redux/Projects/project";
import "./index.css";
import { ProjectDetailsCard } from "../../components/Cards/ProjectDetailsCard";
import { DocsInProject } from "./DocsInProject";
import FadeTransition from "../../components/FadeTransition";
import { HorizontalStack, VerticalStack } from "../../components/Stack";
import DragAndDropComp from "./dragAndDrop";
import HelpIcon from "../../assets/Icons/svg/helpIcon";
import BlueCrossIcon from "../../assets/Icons/svg/blueCrossicon";
import { colorPallete } from "../../assets/color";

const DocumentPage: FC = () => {
  const { t } = useTranslation();
  let { projectId } = useParams();
  const location = useLocation();
  const [params, setParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const [getHelp, setGetHelp] = useState<boolean>(false);
  const projectState = useAppSelector(
    (state: { fetchProjectDetails: any }) => state.fetchProjectDetails
  );
  const documentState = useAppSelector(
    (state: { fetchFilesInFolder: any }) => state.fetchFilesInFolder
  );
  const pdfState = useAppSelector((state: { fetchPDF: any }) => state.fetchPDF);
  const [projectData, setProjectData] = useState<Project | null>(null);
  const [docsData, setDocsData] = useState<Document[]>([]);
  const [permsission, setPermission] = useState<boolean>(false);
  const created = location.state && location.state.created && "open";


  const handleGrantPermission = async () => {
    if (projectId) {
      dispatch(grantPermissionFolder(parseInt(projectId, 10))).then(
        (res: any) => {
          if (res?.payload?.code === 200) {
            setPermission(false);
            window.location.replace(`/home/${projectId}`);
          }
        }
      );
    }
  };
  useEffect(() => {
    if (params.get("ftl") === "True") {
      setPermission(true);
      handleGrantPermission();
    }
  }, [params]);

  useEffect(() => {
    if (projectState.data) {
      setProjectData(projectState.data);
    }
  }, [projectState.isLoading]);

  useEffect(() => {
    if (projectId) {
      dispatch(getAllProjectDetails(parseInt(projectId, 10)));
      dispatch(getFilesInFolders(parseInt(projectId, 10)));
    }
  }, []);
  function refreshData() {
    dispatch(
      getFilesInFolders(projectData?.projectId ? projectData?.projectId : 0)
    );
    dispatch(
      getPrecedentDocuments({
        limit: 30,
        offset: 0,
      })
    );
  }

  useEffect(() => {
    if (
      documentState?.data[0]?.rootDocumentName &&
      documentState.data[0]?.driveItemId
    ) {
      setDocsData(documentState.data);
    } else {
      setDocsData([]);
    }
  }, [documentState.data]);

  const projectErrorJSX = projectState.isLoading ? (
    <>
      <Stack className={mergeStyles(styles.loader)}>
        <Stack style={{ margin: "2%" }}>
          <Loader />
        </Stack>
        <Text
          className={mergeStyles({
            fontSize: FontSizes.size32,
            fontFamily: "Poppins",
          })}
        >
          {t("loading")}
        </Text>
      </Stack>
    </>
  ) : !projectState.isLoading && projectState.error !== "" ? (
    <>
      <Stack className={mergeStyles(styles.loader)}>
        <Text className={mergeStyles(styles.textStyles)}>{t("loader")}</Text>
        <PrimaryButton
          styles={retryButtonStyles}
          onClick={() =>
            dispatch(getAllProjectDetails(projectData?.projectId!))
          }
        >
          {t("retry")}
        </PrimaryButton>
      </Stack>
    </>
  ) : null;

  function handleOnDrag(e: React.DragEvent, file: string) {
    e.dataTransfer.setData("file", file);
  }



  const HelpBox = () => {
    return (
      <Stack className="helpBox">
        <VerticalStack className="helpHeadContainer">
          <HorizontalStack className="helpHeading">
            <Text className="helpHeadText">{t("need_help")}</Text>
            <Stack
              onClick={() => {
                setGetHelp(!getHelp);
              }}
              style={{ cursor: "pointer" }}
            >
              <BlueCrossIcon />
            </Stack>
          </HorizontalStack>
          <Text className="helpDescription">{t("help_desc")}</Text>
        </VerticalStack>
        <VerticalStack className="fieldWrapper">
          <Label className="helpLabel" required>
            {t("full_name")}
          </Label>
          <TextField
            styles={{
              root: {
                height: "40px",
                backgroundColor: "#F8F8F8",
                borderRadius: "5px",
                width: "100%",
                padding: "4px",
                fontSize: "14px",
                fontWeight: 400,
                lineHeight: "16px",
                fontFamily: "Poppins",
                border: "1px solid var(--LIGHT-BLUE, #E8ECF4)",
              },
              field: {
                backgroundColor: "#F8F8F8",
                fontSize: "14px",
                fontWeight: 400,
                lineHeight: "16px",
                fontFamily: "Poppins",
                selectors: {
                  "::placeholder": {
                    color: "#717070",
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "16px",
                    fontFamily: "Poppins",
                  },
                },
              },
            }}
            borderless
            placeholder={String(t("enter_full_name"))}
          />
        </VerticalStack>
        <VerticalStack className="fieldWrapper">
          <Label className="helpLabel" required>
            {t("email")}
          </Label>
          <TextField
            styles={{
              root: {
                height: "40px",
                backgroundColor: "#F8F8F8",
                borderRadius: "5px",
                width: "100%",
                padding: "4px",
                fontSize: "14px",
                fontWeight: 400,
                lineHeight: "16px",
                fontFamily: "Poppins",
                border: "1px solid var(--LIGHT-BLUE, #E8ECF4)",
              },
              field: {
                backgroundColor: "#F8F8F8",
                fontSize: "14px",
                fontWeight: 400,
                lineHeight: "16px",
                fontFamily: "Poppins",
                selectors: {
                  "::placeholder": {
                    color: "#717070",
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "16px",
                    fontFamily: "Poppins",
                  },
                },
              },
            }}
            borderless
            placeholder={String(t("enter_email"))}
          />
        </VerticalStack>
        <VerticalStack className="fieldWrapper">
          <Label className="helpLabel" required>
            {t("message")}
          </Label>
          <TextField
            styles={{
              root: {
                minHeight: "90px",
                backgroundColor: "#F8F8F8",
                borderRadius: "5px",
                width: "100%",
                padding: "4px",
                fontSize: "14px",
                fontWeight: 400,
                lineHeight: "16px",
                fontFamily: "Poppins",
                border: "1px solid var(--LIGHT-BLUE, #E8ECF4)",
              },
              field: {
                backgroundColor: "#F8F8F8",
                fontSize: "14px",
                fontWeight: 400,
                lineHeight: "16px",
                fontFamily: "Poppins",
                height: "90px",
                selectors: {
                  "::placeholder": {
                    color: "#717070",
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "16px",
                    fontFamily: "Poppins",
                  },
                },
              },
            }}
            borderless
            multiline={true}
            resizable={false}
            placeholder={String(t("problem"))}
          />
        </VerticalStack>
        <Text className="getHelpButton">{t("get_help")}</Text>
      </Stack>
    );
  };
  return (
    <FadeTransition>
      {permsission ? (
        <Stack className={mergeStyles(styles.loader)}>
          <Stack style={{ margin: "2%" }}>
            <Loader />
          </Stack>
          <Text
            className={mergeStyles({
              fontSize: FontSizes.size32,
              fontFamily: "Poppins",
            })}
          >
            {t("loading")}
          </Text>
        </Stack>
      ) : projectErrorJSX ? (
        projectErrorJSX
      ) : projectState.data && projectData?.projectName ? (
        <>
          <VerticalStack style={{ ...styles.container, position: "relative" }}>
            <ProjectDetailsCard projectData={projectData} />
            <div className="LightGreyCard">
              <DragAndDropComp
                created={created}
                projectId={projectData?.projectId!}
                refresh={refreshData}
                data={docsData}
                folderName={projectData?.projectName}
              />
              <DocsInProject
                projectData={projectData}
                drag={handleOnDrag}
                refresh={refreshData}
              />
            </div>
            <Stack className="helpContainer">
              {getHelp ? <HelpBox /> : null}
              <Stack
                onClick={() => {
                  setGetHelp(!getHelp);
                }}
                style={{ cursor: "pointer" }}
              >
                <HelpIcon />
              </Stack>
            </Stack>
          </VerticalStack>
        </>
      ) : (
        <Stack
          style={{
            width: "100%",
            alignItems: "center",
            alignSelf: "center",
            marginTop: "10%",
            height: "380px",
            justifyContent: "center",
          }}
        >
          <Text
            style={{
              fontSize: "24px",
              lineHeight: "36px",
              fontWeight: 600,
              fontFamily: "Poppins",
              color: colorPallete.black1,
            }}
          >
            {typeof projectState.data === "string" && projectState.data}
          </Text>
        </Stack>
      )}
    </FadeTransition>
  );
};
export default DocumentPage;
const retryButtonStyles: IButtonStyles = {
  root: {
    width: "20px",
    margin: "1%",
    alignItems: "center",
  },
};
const styles = {
  container: {
    width: "100%",
    alignItems: "center",
    overflow: "hidden",
    marginTop: "1rem",
    backgroundColor: "",
  },
  loader: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    alignItems: "center",
    paddingTop: "10%",
  },
  textStyles: {
    fontSize: FontSizes.size24,
    fontFamily: "Poppins",
  },
};
