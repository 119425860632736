import { FC, ReactElement, useEffect, useRef } from "react";
import { Login } from "@microsoft/mgt-react";
import { Providers, ProviderState } from "@microsoft/mgt-element";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppDispatch } from "../../hooks/hooks";
import {
  grantPermission,
  setGrantPermission,
} from "../../modules/redux/GrantPermissionSlice/grantPermission";
import { ApiClient } from "../../network/ApiClient";
import { ApiEndpoints } from "../../network/ApiEndpoints";
import OpenFile from "../../navigation/NoobOrPro";
import { grantPermissionFolder } from "../../modules/redux/Documents/document";
import "./index.css";

const Signin: FC<any> = (): ReactElement => {
  const navigate = useNavigate();
  const [params, setParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const loginHint = params.get("loginHint");
  const user = JSON.parse(localStorage.getItem("user")!);

  function getParamValue(param:any) {
    let link = localStorage.getItem("LINK")!;
    let url = new URL(link);
    const urlParams = new URLSearchParams(url.search);
    return urlParams.get(param);
}

  async function handleLogin(redirect_id:any, ftl:any, projectId:any){
    const links = await ApiClient.get<any>(
      ApiEndpoints.getDocumentLinks(),
      {
        encryptedRedirectId: redirect_id,
      },
      {}
    );
    if (links?.data?.documentUrl) {
      if (
        ftl === "True" &&
        projectId
      ) {
        navigate("home");
        dispatch(setGrantPermission());
          dispatch(grantPermissionFolder(parseInt(projectId, 10)))
            .then((res: any) => {
              console.log("res zz", res);
              localStorage.removeItem("LINK");
              if (res?.payload?.code === 200 && links) {
                  dispatch(
                    grantPermissionFolder(parseInt(projectId, 10))
                  ).then(() => {
                    dispatch(grantPermission());
                    OpenFile(
                      links?.data?.documentUrl,
                      links?.data?.documentWebUrl
                    );
                  });
              } else {
                dispatch(grantPermission());
                navigate("home");
              }
            })
            .catch((res: any) => {
              console.log("catch", res);
              localStorage.removeItem("LINK");
              navigate("home");
              OpenFile(
                links?.data?.documentUrl,
                links?.data?.documentWebUrl
              );
            });
      } else if (redirect_id) {
        navigate("home");
        localStorage.removeItem("LINK");
        OpenFile(links?.data?.documentUrl, links?.data?.documentWebUrl);
      }
    } else {
      navigate("pagenotfound");
    }
  }


  const loginCompleted = async (res: any) => {
    if (Providers.globalProvider.state === ProviderState.SignedIn) {
      const response = await ApiClient.get<any>(ApiEndpoints.userDetails());
      localStorage.setItem("user", JSON.stringify(response));
      localStorage.setItem("loginHint", "");
      if (params.has("redirect_id")) {
       handleLogin(params.get("redirect_id"),params.get("ftl"),params.get("projectId"));
      }
      else if(localStorage.getItem("LINK")){
        console.log("Params cleared :: Checking local storage")
        handleLogin(getParamValue("redirect_id"), getParamValue("ftl"), getParamValue("projectId"));
      }
      else if (window.location.pathname === "/") {
        navigate("home");
      }
    }
  };

  async function login() {
    await Providers.globalProvider.login!().catch((error: any) => {
      console.log("Error in login :: ", error);
    });
  }

  useEffect(() => {
    if (
      (params.has("redirect_id") || params.has("ftl")) &&
      params.has("loginHint") &&
      Providers.globalProvider.state !== ProviderState.SignedIn
    ) {
      if (user && Providers.globalProvider.getAllAccounts!().length > 0) {
        user?.email !== loginHint && login();
      } else if (Providers.globalProvider.getAllAccounts!().length == 0) {
        login();
      }
    }
  }, []);

  const loginInitiated = () => {};
  const logoutCompleted = async () => {
    localStorage.setItem("loginHint", "");
    sessionStorage.removeItem("Access-Token");
  };

  const loginFailed = (err: any) => {
    console.log("Login Failed", err);
  };

  return (
    <Login
      id="loginButton"
      loginCompleted={loginCompleted}
      logoutCompleted={logoutCompleted}
      loginInitiated={loginInitiated}
      loginFailed={loginFailed}
      className="login"
    />
  );
};

export default Signin;
