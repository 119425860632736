import React from "react";

export default function Cross() {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
      >
        <circle cx="6" cy="6" r="6" fill="#18468F" />
        <rect
          x="7.98633"
          y="3.75"
          width="0.816973"
          height="5.99114"
          rx="0.408487"
          transform="rotate(45 7.98633 3.75)"
          fill="white"
        />
        <rect
          x="8.56445"
          y="7.98828"
          width="0.816973"
          height="5.99114"
          rx="0.408487"
          transform="rotate(135 8.56445 7.98828)"
          fill="white"
        />
      </svg>
    </div>
  );
}
