import { FC, useState, useCallback, useEffect, useMemo } from "react";
import { FormModal } from "../Modal";
import { HorizontalStack, VerticalStack } from "../../Stack";
import { DocumentService } from "../../../services/DocumentService";
import { BtFileObject } from "../../../models";
import { useTranslation } from "react-i18next";
import {
  FontIcon,
  Label,
  Link,
  mergeStyles,
  Separator,
  Stack,
  Text,
} from "@fluentui/react";
import Loader from "../../Loader";
import {
  createTemplate,
  createTemplateVersion,
  getFilesInFolders,
  getPrecedentDocuments,
} from "../../../modules/redux/Documents/document";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import { getAllProjects } from "../../../modules/redux/Projects/project";
import { useDropzone } from "react-dropzone";
import { TextForBody, TextForPageHeaders } from "../../Text";
import DocumentList from "../../Cards/DocumentList";
import { colorPallete } from "../../../assets/color";
import UploadDocumentIcon from "../../../assets/Icons/svg/uploadDocumentIcon";
import CustomDropdown from "../../CustomDropDown";
import WordDocIcon from "../../../assets/Icons/svg/wordDocIcon";
import "./index.css";
import Buttons from "./components/buttons";
import AddNewVersion from "./components/addNewVersion";
import { useNavigate } from "react-router-dom";

export const CreateDocumentModal: FC<any> = (props) => {
  const [folderName, setFolderName] = useState("");
  const [fileNameError, setFileNameError] = useState(false);
  const [modal, setModal] = useState<boolean>(false);
  const [upload, setUpload] = useState<boolean>(false);
  const [msg, setMsg] = useState<string>("");
  const [mulitpleFiles, setMultipleFiles] = useState<BtFileObject[]>([]);
  const [showResponse, setShowResponse] = useState<boolean>(false);
  const [responseData, setResponseData] = useState<any>([]);
  const [docError, setDocError] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [newVersion, setNewVersion] = useState(false);
  const [addDocument, setAddDocument] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [error1, setError] = useState(false);
  const [folderNameError, setFolderNameError] = useState<boolean>(false);
  const [projectData, setProjectData] = useState<any>([]);
  const [docsData, setDocsData] = useState<Document[]>([]);
  const [documentName, setDocumentName] = useState<string>("");
  const [documentNameError, setDocumentNameError] = useState<boolean>(false);
  const [rootDocumentId, setRootDocumentId] = useState<any>(0);
  const [projectId, setProjectId] = useState<any>(0);
  const [multipleFilesError, setMultipleFilesError] = useState<boolean>(false);
  const [documentItem, setDocumentItem] = useState<Document | any>();
  const { isLoading, data, error, metadata } = useAppSelector(
    (state: any) => state.fetchProjects
  );
  const [singleFileError, setSingleFileError] = useState<boolean>(false);

  useEffect(() => {
    if (data && data.length > 0) {
      setProjectData(data);
    } else {
      setProjectData([]);
    }
  }, [data, metadata]);

  useEffect(() => {
    if (data.length === 0)
      dispatch(
        getAllProjects({
          limit: 12,
          offset: 0,
        })
      );
  }, []);

  useEffect(() => {
    if (props?.folderName && props?.projectId) {
      setFolderName(props?.folderName);
      setProjectId(props?.projectId)
    }
  }, [props]);

  useEffect(() => {
    let updated = projectData.filter(
      (item: any) => item.projectName == folderName
    );
    setDocumentItem(null);
    setProjectId(updated[0]?.projectId);
    if (updated[0]?.documents?.length > 0) {
      setDocsData(updated[0]?.documents);
    } else {
      setDocsData([]);
    }
  }, [folderName]);

  useEffect(() => {
    if (mulitpleFiles.length > 0) {
      setMultipleFilesError(false);
      setError(false);
      setSingleFileError(false);
    }
  }, [mulitpleFiles]);

  useEffect(() => {
    var count = 0;
    mulitpleFiles.forEach((file) => {
      if (file.fileName.endsWith(".DOC") || file.fileName.endsWith(".doc")) {
        count += 1;
      }
    });
    if (nameError) {
      var check: boolean = false;
      mulitpleFiles.forEach((btFile: BtFileObject) => {
        if (btFile.fileName.length === 0) check = true;
      });
      setNameError(check);
    }
    if (count > 0) {
      setDocError(true);
    } else {
      setDocError(false);
    }
  }, [mulitpleFiles]);
  useEffect(() => {
    if (!props.copy) {
      addMultipleFiles(props.file);
    }
  }, [props.file]);

  const navigate = useNavigate();
  const redirectFolder = (projectId: number | undefined) => {
      navigate(`/home/${projectId}`);
  };

  function addMultipleFiles(files: File[]) {
    setFileNameError(false);
    let incomingFiles: BtFileObject[] = [];
    files.forEach((fObject: File) => {
      incomingFiles.push({
        fileName: fObject.name,
        file: fObject,
      });
    });

    setMultipleFiles((prevFiles) => {
      let result = [...prevFiles, ...incomingFiles];
      const uniqueArr = result.filter(
        (value, index, array) =>
          array.findIndex((curr) => value.fileName === curr.fileName) === index
      );
      return uniqueArr;
    });
    setMultipleFilesError(false);
    setSingleFileError(false);
  }

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    acceptedFiles,
  } = useDropzone({
    noDragEventsBubbling: true,
    multiple: true,
    onDrop: (acceptedFiles) => {
      addMultipleFiles(acceptedFiles);
    },
  });

  const style = useMemo(
    () => ({
      ...(isFocused ? styles.focusedStyle : {}),
      ...(isDragAccept ? styles.acceptStyle : {}),
      ...(isDragReject ? styles.rejectStyle : {}),
      ...{ height: "100%", width: "100%" },
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  const uploadFile = async (props?: any) => {
    var check: boolean = false;
    mulitpleFiles.forEach((btFile: BtFileObject) => {
      if (btFile.fileName.length === 0) {
        check = true;
      }
    });
    if (String(folderName).length === 0) {
      setFolderNameError(true);
      return;
    }
    if (newVersion && String(documentName).length == 0) {
      setDocumentNameError(true);
      return;
    }
    if (mulitpleFiles.length === 0) {
      setError(true);
      return;
    }
    if (check) {
      setNameError(true);
      return;
    }
    if (
      !newVersion &&
      !error1 &&
      String(folderName).length !== 0 &&
      mulitpleFiles.length > 0 &&
      !docError
    ) {
      setModal(true);
      await DocumentService.upload(projectId, mulitpleFiles).then(
        (res) => {
          setMsg("Success");
          setMultipleFiles([]);
          setUpload(true);
          setResponseData(res);
          setShowResponse(true);
          dispatch(
            getPrecedentDocuments({
              limit: 21,
              offset: 0,
            })
          );
          dispatch(getAllProjects({}));
          setTimeout(() => {
            dispatch(getFilesInFolders(projectId)).then(() => {
              redirectFolder(projectId);
            });
            disable();
          }, 2000);
        })
        .catch((res: any) => {
         setUpload(true);
          res ? setMsg(res) : setMsg("upload_failed");
          setTimeout(() => {
            setUpload(false);
            setModal(false);
            disable();
          }, 2000);
        });
      setNameError(false);
      setFileNameError(false);
      setDocError(false);
      setFileNameError(false);
    }
    if (
      newVersion &&
      !error1 &&
      String(folderName).length !== 0 &&
      String(folderName).length !== 0 &&
      mulitpleFiles.length !== 1 &&
      !confirm
    ) {
      setSingleFileError(true);
    } else if (
      newVersion &&
      !error1 &&
      String(folderName).length !== 0 &&
      String(folderName).length !== 0 &&
      mulitpleFiles.length === 1 &&
      props !== "confirmed"
    ) {
      setAddDocument(true);
    }
    if (
      newVersion &&
      !error1 &&
      String(folderName).length !== 0 &&
      String(folderName).length !== 0 &&
      mulitpleFiles.length === 1 &&
      props === "confirmed"
    ) {
      let obj = {
        folderName: folderName,
        mulitpleFiles: mulitpleFiles,
        rootDocumentId: rootDocumentId,
      };
      setModal(true);
      await DocumentService.uploadNewVersion(mulitpleFiles, rootDocumentId)
        .then((res) => {
          setMsg("Success");
          setMultipleFiles([]);
          setUpload(true);
          setResponseData(res);
          setShowResponse(true);
          dispatch(
            getPrecedentDocuments({
              limit: 21,
              offset: 0,
            })
          );
          dispatch(getAllProjects({}));
          setTimeout(() => {
            dispatch(getFilesInFolders(projectId)).then(() => {
              redirectFolder(projectId);
            });
            disable();
          }, 2000);
        })
        .catch((res) => {
          setUpload(true);
          res ? setMsg(res) : setMsg("upload_failed");
          setTimeout(() => {
            setUpload(false);
            setModal(false);
            disable();
          }, 2000);
        });
      setNameError(false);
      setFileNameError(false);
      setDocError(false);
      setFileNameError(false);
    }
  };

  const copyDocument = () => {
    if (String(folderName).length === 0) {
      setFolderNameError(true);
      return;
    }
    if (newVersion && String(documentName).length == 0) {
      setDocumentNameError(true);
      return;
    }
    if (!newVersion && String(folderName).length !== 0) {
      let obj = {
        projectId: projectId,
        driveItemId: props?.document.driveItemId,
      };
      setModal(true);
      dispatch(createTemplate(obj))
        .then((res: any) => {
          setMsg("Success");
          setMultipleFiles([]);
          setUpload(true);
          setResponseData([res?.payload]);
          setShowResponse(true);
          setTimeout(() => {
            dispatch(
              getPrecedentDocuments({
                limit: 21,
                offset: 0,
              })
            );
            dispatch(getAllProjects({}));
            dispatch(getFilesInFolders(projectId)).then(() => {
              redirectFolder(projectId);
            });
            disable();
          }, 2000);
        })
        .catch((res: any) => {
          setUpload(true);
          res ? setMsg(res) : setMsg("upload_failed");
          setTimeout(() => {
            setUpload(false);
            setModal(false);
            disable();
          }, 2000);
        });
    } else if (
      newVersion &&
      String(folderName).length !== 0 &&
      String(documentName).length !== 0
    ) {
      let obj = {
        rootDocumentId: documentItem.rootDocumentId,
        driveItemId: props?.document.driveItemId,
      };
      setModal(true);
      dispatch(createTemplateVersion(obj))
        .then((res: any) => {
          setMsg("Success");
          setMultipleFiles([]);
          setUpload(true);
          setResponseData([res?.payload]);
          setShowResponse(true);
          setTimeout(() => {
            dispatch(
              getPrecedentDocuments({
                limit: 21,
                offset: 0,
              })
            );
            dispatch(getAllProjects({}));
            dispatch(getFilesInFolders(projectId)).then(() => {
              redirectFolder(projectId);
            });
            disable();
          }, 2000);
        })
        .catch((res: any) => {
          setUpload(true);
          res ? setMsg(res) : setMsg("upload_failed");
          setTimeout(() => {
            setUpload(false);
            setModal(false);
            disable();
          }, 2000);
        });
    }
  };

  function handleCLick() {
    if (props?.copy) {
      return copyDocument();
    } else {
      return uploadFile();
    }
  }
  function disable() {
    setModal(false);
    setMultipleFiles([]);
    props.hideModal();
    setFileNameError(false);
    setNameError(false);
    setFileNameError(false);
    setNewVersion(false);
    setMsg("");
    setUpload(false);
    setSingleFileError(false);
    setDocumentItem(null);
    setFolderName("");
  }

  const response = (data: any) => {
    return (
      <Stack className={mergeStyles(styles.responseContainer)}>
        {data.map(
          (res: { code: number; data: File | null; message: string }) => {
            return res.code === 201 || res.code === 200 ? (
              <>
                <Stack
                  className={mergeStyles(styles.success)}
                  key={res.message}
                >
                  <Stack style={{ alignItems: "center", paddingRight: "15px" }}>
                    <FontIcon
                      iconName="SkypeCircleCheck"
                      style={{
                        fontSize: "25px",
                        backgroundColor: "white",
                        color: colorPallete.btGreen,
                      }}
                    />
                  </Stack>
                  <Stack>
                    <TextForBody className={mergeStyles(styles.successMessage)}>
                      {res.message}
                    </TextForBody>
                  </Stack>
                </Stack>
              </>
            ) : (
              <>
                <Stack className={mergeStyles(styles.failed)} key={res.message}>
                  <Stack style={{ alignItems: "center", paddingRight: "15px" }}>
                    <FontIcon
                      iconName="StatusErrorFull"
                      style={{
                        fontSize: "25px",
                        backgroundColor: colorPallete.white,
                        color: colorPallete.btRed,
                      }}
                    />
                  </Stack>
                  <Stack>
                    <TextForBody className={mergeStyles(styles.errorMessage)}>
                      {res.message} :{" "}
                      {res.code === 208
                        ? t("already_exists")
                        : res.code === 404
                        ? t("file_empty")
                        : res.code === 500 && t("upload_fail")}
                    </TextForBody>
                  </Stack>
                </Stack>
              </>
            );
          }
        )}
      </Stack>
    );
  };
  function getBackgroundColor() {
    if (props.copy && !newVersion && String(folderName).length > 0) {
      return `${colorPallete.btBlue}`;
    } else if (
      props.copy &&
      newVersion &&
      String(folderName).length > 0 &&
      String(documentName).length > 0
    ) {
      return `${colorPallete.btBlue}`;
    } else if (
      !newVersion &&
      String(folderName).length > 0 &&
      mulitpleFiles.length > 0
    ) {
      return `${colorPallete.btBlue}`;
    } else if (
      newVersion &&
      String(folderName).length > 0 &&
      mulitpleFiles.length == 1 &&
      String(documentName).length > 0
    ) {
      return `${colorPallete.btBlue}`;
    } else {
      return "#EAEAEA";
    }
  }

  function getFontColor() {
    if (props.copy && !newVersion && String(folderName).length > 0) {
      return `${colorPallete.white}`;
    } else if (
      props.copy &&
      newVersion &&
      String(folderName).length > 0 &&
      String(documentName).length > 0
    ) {
      return `${colorPallete.white}`;
    } else if (
      !newVersion &&
      String(folderName).length > 0 &&
      mulitpleFiles.length > 0
    ) {
      return `${colorPallete.white}`;
    } else if (
      newVersion &&
      String(folderName).length > 0 &&
      mulitpleFiles.length === 1 &&
      String(documentName).length > 0
    ) {
      return `${colorPallete.white}`;
    } else {
      return "#717070";
    }
  }
  const ErrorJSX = (props: any) => {
    return (
      <Stack
        style={{ width: "100%", alignItems: "flex-end", marginTop: "4px" }}
      >
        <Text
          style={{
            ...styles.mediumText,
            color: colorPallete.btRed,
          }}
        >
          {props?.message}
        </Text>
      </Stack>
    );
  };
  return (
    <FormModal
      title={
        props.copy
          ? t("copy_precedent")
          : t("import_documents")
      }
      TitleBackgroundColor={"#18468F"}
      isModalOpen={props.isModalOpen}
      showModal={props.showModal}
      hideModal={props.hideModal}
      fileName={props.fileName}
      width="610px"
      cancelButton
      customCancel={disable}
    >
      {modal ? (
        <Stack className={mergeStyles(styles.uploadModal)}>
          {upload ? (
            showResponse ? (
              response(responseData)
            ) : (
              <>
                <TextForPageHeaders>{t(msg)}</TextForPageHeaders>
              </>
            )
          ) : (
            <Stack style={styles.loader}>
              <Stack style={{ padding: "2%" }}>
                <Loader />
              </Stack>
              <TextForPageHeaders>
                {props.copy ? t("copying_docs") : t("importing_docs")}
              </TextForPageHeaders>
            </Stack>
          )}
        </Stack>
      ) : (
        <>
          {addDocument ? (
            <AddNewVersion
              documentName={documentName}
              folderName={folderName}
              addDocument={addDocument}
              setAddDocument={setAddDocument}
              mulitpleFiles={mulitpleFiles}
              rootDocumentId={rootDocumentId}
              uploadFile={uploadFile}
            />
          ) : (
            <>
              <VerticalStack style={styles.container}>
                <Text style={styles.headingText}>
                  {props?.copy ? t("copy_as") : t("import_as")}
                </Text>
                <Buttons
                  newVersion={newVersion}
                  setNewVersion={setNewVersion}
                  files={mulitpleFiles.length}
                  setMultipleFilesError={setMultipleFilesError}
                />
                {multipleFilesError ? (
                  <ErrorJSX message={t("error_import_v")} />
                ) : null}
                {!newVersion ? (
                  <VerticalStack
                    style={{ marginTop: "28px", minHeight: "237px" }}
                  >
                    <HorizontalStack className="projectName">
                      <Label style={styles.headingText} required>
                        {t("project_name")}
                      </Label>
                      <Text
                        onClick={() => {
                          props.project();
                        }}
                        style={styles.blueText}
                      >
                        + {t("new_project")}
                      </Text>
                    </HorizontalStack>
                    <CustomDropdown
                      options={projectData.map((item: any) => item.projectName)}
                      title={t("project_dd")}
                      data={setFolderName}
                      error={setFolderNameError}
                      folderName={folderName}
                    />
                    {folderNameError ? (
                      <ErrorJSX message={t("error_project")} />
                    ) : null}
                    {props?.copy ? (
                      <HorizontalStack
                        style={{
                          ...styles.documentCard,
                          marginTop: "28px",
                        }}
                      >
                        <WordDocIcon />
                        <Text style={styles.documentText}>
                          {props.document.documentName}
                        </Text>
                      </HorizontalStack>
                    ) : (
                      <VerticalStack style={{ marginBottom: "28px" }}>
                        <Stack
                          style={{
                            ...styles.baseStyle,
                            backgroundColor: error1 ? " #F6EAE7" : "#F8F8F8",
                            marginTop: "28px",
                          }}
                        >
                          <div {...getRootProps({ style })}>
                            <input {...getInputProps()} />
                            <Stack
                              style={{
                                flexDirection:
                                  mulitpleFiles.length > 0 ? "row" : "column",
                                alignItems: "center",
                                gap: "8px",
                                justifyContent: "center",
                                padding:
                                  mulitpleFiles.length > 0
                                    ? "7px 0px"
                                    : "15px 0px",
                              }}
                            >
                              <UploadDocumentIcon
                                height={
                                  mulitpleFiles.length > 0 ? "16px" : "34px"
                                }
                                width={
                                  mulitpleFiles.length > 0 ? "15px" : "33px"
                                }
                              />
                              <HorizontalStack>
                                <Text style={styles.dragText}>
                                  {t("drag_drop")}
                                </Text>
                                <Link
                                  style={{
                                    fontSize: "0.8rem",
                                    fontWeight: "400",
                                    color: colorPallete.btBlue,
                                    fontFamily: "Poppins",
                                  }}
                                  underline
                                >
                                  {t("browse")}
                                </Link>
                              </HorizontalStack>
                            </Stack>
                          </div>
                        </Stack>
                        {mulitpleFiles.length === 0 && error1 ? (
                          <ErrorJSX message={t("error_document")} />
                        ) : (
                          <DocumentList
                            multipleFiles={mulitpleFiles}
                            setMultipleFiles={setMultipleFiles}
                          />
                        )}
                      </VerticalStack>
                    )}
                    {nameError ? (
                      <ErrorJSX message={t("error_filename")} />
                    ) : null}
                  </VerticalStack>
                ) : (
                  <VerticalStack
                    style={{ marginTop: "28px", minHeight: "237px" }}
                  >
                    <HorizontalStack
                      style={{
                        marginBottom: "4px",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Label style={styles.headingText} required>
                        {t("project_name")}
                      </Label>
                    </HorizontalStack>
                    <CustomDropdown
                      options={projectData.map((item: any) => item.projectName)}
                      title={t("project_dd")}
                      data={setFolderName}
                      error={setFolderNameError}
                      folderName={folderName}
                    />
                    {folderNameError ? (
                      <ErrorJSX message={t("error_project")} />
                    ) : null}
                    {String(folderName).length > 0 && docsData.length > 0 ? (
                      <VerticalStack style={{ marginTop: "28px" }}>
                        <Label
                          required
                          style={{ ...styles.headingText, marginBottom: "4px" }}
                        >
                          {t("document_family")}
                        </Label>
                        <CustomDropdown
                          options={docsData}
                          title={t("document_dd")}
                          data={setDocumentName}
                          doc={true}
                          folderName={folderName}
                          error={setDocumentNameError}
                          documentId={setRootDocumentId}
                          documentItem={documentItem}
                          setDocumentItem={setDocumentItem}
                        />
                        {documentNameError ? (
                          <ErrorJSX message={t("error_docfamily")} />
                        ) : null}
                      </VerticalStack>
                    ) : null}
                    {props.copy ? (
                      <HorizontalStack
                        style={{
                          ...styles.documentCard,
                          marginTop: "28px",
                          marginBottom: "28px",
                        }}
                      >
                        <WordDocIcon />
                        <Text style={styles.documentText}>
                          {props.document.documentName}
                        </Text>
                      </HorizontalStack>
                    ) : mulitpleFiles.length > 0 ? (
                      <Stack
                        style={{ marginTop: "28px", marginBottom: "28px" }}
                      >
                        <DocumentList
                          multipleFiles={mulitpleFiles}
                          setMultipleFiles={setMultipleFiles}
                          newVersion={newVersion}
                        />
                        {singleFileError ? (
                          <ErrorJSX message={t("error_import_v")} />
                        ) : null}
                      </Stack>
                    ) : (
                      <VerticalStack>
                        <Stack
                          style={{
                            ...styles.baseStyle,
                            backgroundColor: error1 ? " #F6EAE7" : "#F8F8F8",
                            marginTop: "28px",
                          }}
                        >
                          <div {...getRootProps({ style })}>
                            <input {...getInputProps()} />
                            <HorizontalStack style={styles.inputWrapper}>
                              <UploadDocumentIcon height="16px" width="15px" />
                              <HorizontalStack>
                                <Text style={styles.dragText}>
                                  {t("drag_drop")}
                                </Text>
                                <Link
                                  style={{
                                    fontSize: "0.8rem",
                                    fontWeight: "400",
                                    color: colorPallete.btBlue,
                                    fontFamily: "Poppins",
                                  }}
                                  underline
                                >
                                  {t("browse")}
                                </Link>
                              </HorizontalStack>
                            </HorizontalStack>
                          </div>
                        </Stack>
                        {mulitpleFiles.length === 0 && error1 ? (
                          <ErrorJSX message={t("error_document")} />
                        ) : null}
                      </VerticalStack>
                    )}
                  </VerticalStack>
                )}
              </VerticalStack>
              <Stack className="horizontalLine"></Stack>
              <HorizontalStack style={styles.bottom}>
                <Text
                  style={{
                    ...styles.addButton,
                    ...styles.mediumText,
                    fontWeight: "500",
                    background: getBackgroundColor(),
                    color: getFontColor(),
                    textAlign: "center",
                  }}
                  onClick={handleCLick}
                >
                  {props.copy ? t("copy") : t("import")}
                </Text>
                <Text
                  style={{
                    ...styles.cancelButton,
                    ...styles.mediumText,
                    textAlign: "center",
                    fontWeight: "500",
                  }}
                  onClick={disable}
                >
                  {t("cancel")}
                </Text>
              </HorizontalStack>
            </>
          )}
        </>
      )}
    </FormModal>
  );
};

export const styles = {
  container: {
    paddingLeft: "25px",
    paddingRight: "25px",
    backgroundColor: "white",
    paddingTop: "30px",
  },
  headingText: {
    color: "#1F2430",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: "500",
    lineHeight: "24px",
    fontStyle: "normal",
  },
  mediumText: {
    color: "#323232",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "16px",
    fontStyle: "normal",
  },
  documentText: {
    color: "#323232",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "16px",
    fontStyle: "normal",
  },
  button: {
    width: "49%",
    height: "40px",
    backgroundColor: "#F8F8F8",
    alignItems: "center",
    borderRadius: "5px",
    cursor: "pointer",
  },
  blueText: {
    color: `${colorPallete.btBlue}`,
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "24px",
    fontStyle: "normal",
    cursor: "pointer",
  },
  dropDown: {
    marginBottom: "28px",
    minWidth: "75%",
  },
  addButton: {
    padding: "12px 24px",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    borderRadius: "4px",
    cursor: "pointer",
    width: "120px",
  },
  cancelButton: {
    padding: "12px 24px",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    borderRadius: "4px",
    backgroundColor: "#E8ECF4",
    color: "#323232",
    cursor: "pointer",
    width: "120px",
  },
  bottom: {
    padding: "20px 25px",
    alignSelf: "flex-end",
    gap: "9px",
    justifyContent: "flex-end",
  },
  dragText: {
    paddingRight: "0.3rem",
    fontSize: "0.8rem",
    fontWeight: "400",
    color: colorPallete.grey1,
    fontFamily: "Poppins",
  },
  greyCircle: {
    width: "19px",
    height: "19px",
    borderRadius: "50%",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: "15px",
    marginRight: "4px",
  },
  blueCircle: {
    width: "11px",
    height: "11px",
    borderRadius: "50%",
    backgroundColor: "#18468F",
    alignSelf: "center",
  },
  versionCard: {
    padding: "8px 16px",
    borderRadius: "4px",
    border: `1px solid ${colorPallete.btLightBlue}`,
    backgroundColor: "white",
    alignItems: "flex-start",
    justifyContent: "center",
    gap: "10px",
    width: "98%",
    minHeight: "60px",
  },
  versionContainer: {
    width: "91.5%",
    borderRadius: "8px",
    border: "1px solid #EAEAEA",
    backgroundColor: "#F8F8F8",
    alignItems: "center",
    gap: "12px",
    padding: "12px",
    alignSelf: "flex-end",
  },
  projectDetails: {
    alignItems: "center",
    gap: "10px",
  },
  projectText: {
    color: "#323232",
    fontFamily: "Poppins",
    fontSize: "17px",
    fontWeight: "500",
    lineHeight: "18px",
    fontStyle: "normal",
  },
  dateText: {
    color: "#323232",
    fontFamily: "Poppins",
    fontSize: "12px",
    fontWeight: "400",
    lineHeight: "16px",
    fontStyle: "normal",
  },
  loader: {
    padding: "2%",
    backgroundColor: "",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    alignSelf: "center",
    marginTop: "1%",
  },
  inputWrapper: {
    alignItems: "center",
    gap: "8px",
    justifyContent: "center",
    padding: "7px 0px",
  },
  versionHistory: {
    backgroundColor: "white",
    padding: "20px 25px",
  },
  responseContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    backgroundColor: colorPallete.white,
    height: "100%",
    padding: "15px",
  },
  success: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    backgroundColor: colorPallete.white,
    paddingBottom: "2%",
  },
  failed: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    backgroundColor: colorPallete.white,
    paddingBottom: "2%",
  },
  baseStyle: {
    backgroundColor: "#F8F8F8",
    borderRadius: "8px",
    padding: "10px",
    cursor: "pointer",
    justifyContent: "center",
    alignItems: "center",
    backgroundImage: `repeating-linear-gradient(1deg, #51596C, #51596C 11px, transparent 11px, transparent 25px, #51596C 25px), repeating-linear-gradient(91deg, #51596C, #51596C 11px, transparent 11px, transparent 25px, #51596C 25px), repeating-linear-gradient(181deg, #51596C, #51596C 11px, transparent 11px, transparent 25px, #51596C 25px), repeating-linear-gradient(271deg, #51596C, #51596C 11px, transparent 11px, transparent 25px, #51596C 25px)`,
    backgroundSize: "1px 100%, 100% 1px, 1px 100% , 100% 1px",
    backgroundPosition: "0 0, 0 0, 100% 0, 0 100%",
    backgroundRepeat: "no-repeat",
    display: "flex",
    marginBottom: "8px",
  },
  focusedStyle: {
    borderColor: "#2196f3",
  },
  acceptStyle: {
    borderColor: "#0E4394",
  },
  rejectStyle: {
    borderColor: "#ff1744",
  },
  uploadModal: {
    display: "flex",
    justifyContent: "flex-start",
    width: "100%",
    minHeight: "8rem",
    alignItems: "center",
    padding: "1%",
    backgroundColor: colorPallete.white,
  },
  formDataStyles: {
    paddingTop: "1.5%",
    backgroundColor: "",
    overflowY: "scroll",
  },
  errorMessage: {
    color: colorPallete.btRed,
    fontSize: "14px",
    fontfamily: "Poppins",
  },
  successMessage: {
    color: colorPallete.btGreen,
    fontSize: "14px",
    fontfamily: "Poppins",
  },
  documentCard: {
    width: "100%",
    padding: "9px 16px",
    gap: "16px",
    borderRadius: "5px",
    border: "1px solid #EAEAEA",
    background: "#F8F8F8",
    alignItems: "center",
  },
};
