import {
  HoverCard,
  HoverCardType,
  IPlainCardProps,
  Stack,
  Text,
} from "@fluentui/react";
import DocumentIcon from "../../../assets/Icons/svg/documentIcon";
import EditIcon from "../../../assets/Icons/svg/editIcon";
import LockIcon from "../../../assets/Icons/svg/lockIcon";
import ReceivedIcon from "../../../assets/Icons/svg/receivedIcon";
import RedLineDocumentIcon from "../../../assets/Icons/svg/redLineDocument";
import SendIcon from "../../../assets/Icons/svg/sendIcon";
import TrashIcon from "../../../assets/Icons/svg/trashIcon";
import { HorizontalStack, VerticalStack } from "../../../components/Stack";
import { getFormattedDateTime } from "../../../utils";
import { colorPallete } from "../../../assets/color";
import { UserModel } from "../../../models";
import { FC } from "react";
import { useTranslation } from "react-i18next";


const VersionsListView = (props: any) => {
  const { t } = useTranslation();
  function getFileTypeIcon(fileType?: string, isRedlined?: boolean) {
    if (fileType === "sent") {
      return <SendIcon />;
    } else if (fileType === "received") {
      return <ReceivedIcon />;
    } else if (fileType === "draft" && isRedlined === false) {
      return <DocumentIcon />;
    } else if (fileType === "draft" && isRedlined === true) {
      return <RedLineDocumentIcon />;
    }
  }
  function getFileTypeDate(fileType?: string) {
    if (fileType === "sent") {
      return (
        <Text
          style={{
            fontFamily: "Poppins",
            fontSize: "12px",
            lineHeight: "16px",
            fontWeight: 400,
            color: "#717070",
            textAlign: "end",
          }}
        >
         {t("Sent_on")}
        </Text>
      );
    } else if (fileType === "received") {
      return (
        <Text
          style={{
            fontFamily: "Poppins",
            fontSize: "12px",
            lineHeight: "16px",
            fontWeight: 400,
            color: "#717070",
            textAlign: "end",
          }}
        >
          {t("Received_on")}
        </Text>
      );
    } else {
      return (
        <Text
          style={{
            fontFamily: "Poppins",
            fontSize: "12px",
            lineHeight: "16px",
            fontWeight: 400,
            color: "#717070",
            textAlign: "end",
          }}
        >
          {t("Saved_on")}
        </Text>
      );
    }
  }
  function getIcons(fileType?: string) {
    if (fileType === "draft") {
      return (
        <HorizontalStack
          style={{
            width: "82px",
            height: "22px",
            gap: "8px",
            justifyContent: "flex-end",
          }}
        >
          <EditIcon />
          <Stack
            style={{ cursor: "pointer" }}
            onClick={() => {
              if (!props.delete && props.version.documentVersion !== 1) {
                props.setDeleteversion(props.version);
                props.showModal();
              }
            }}
          >
            <TrashIcon />
          </Stack>
        </HorizontalStack>
      );
    } else {
      return (
        <HorizontalStack
          style={{
            width: "82px",
            height: "22px",
            gap: "8px",
            justifyContent: "flex-end",
          }}
        >
          <LockIcon />
        </HorizontalStack>
      );
    }
  }

  function getVersionFromData(fileType?: any, sharedBy?: any) {
    if (fileType === "draft") {
      return (
        <div style={{ width: "85%" }}>
          <Text
            style={{
              fontSize: "16px",
              lineHeight: "20px",
              fontWeight: 400,
              fontFamily: "Poppins",
              color: "#323232",
            }}
          >
         {t("my_draft")}
          </Text>
        </div>
      );
    } else if (fileType === "sent") {
      return (
        <VerticalStack style={{ width: "85%" }}>
          <Text
            style={{
              fontSize: "12px",
              lineHeight: "16px",
              fontWeight: 400,
              fontFamily: "Poppins",
              color: "#717070",
            }}
          >
           {t("from")}
          </Text>
          <Text
            style={{
              fontSize: "16px",
              lineHeight: "20px",
              fontWeight: 400,
              fontFamily: "Poppins",
              color: "#323232",
            }}
          >
           {t("me")}
          </Text>
        </VerticalStack>
      );
    } else if (fileType === "received") {
      return (
        <VerticalStack style={{ width: "85%" }}>
          <Text
            style={{
              fontSize: "12px",
              lineHeight: "16px",
              fontWeight: 400,
              fontFamily: "Poppins",
              color: "#717070",
            }}
          >
           {t("from")}
          </Text>
          <Text
            style={{
              fontSize: "16px",
              lineHeight: "20px",
              fontWeight: 400,
              fontFamily: "Poppins",
              color: "#323232",
            }}
          >
            {sharedBy}
          </Text>
        </VerticalStack>
      );
    } else {
      return null;
    }
  }
  interface Props1 {
    recipients: UserModel[] | undefined;
  }
  const RenderPerson: FC<Props1> = (props: any) => {
    const { recipients } = props;
    const remainingCount = recipients.length - 1;

    return recipients.length ? (
      <div style={{ ...styles.recepientList, wordWrap: "break-word" }}>
        <HorizontalStack style={{ width: "100%", flexWrap: "wrap" }}>
          <Text style={styles.Info}>{recipients[0]?.name}</Text>
          {remainingCount > 0 && (
            <RecipientHoverCard recipients={recipients.slice(1)} />
          )}
        </HorizontalStack>
      </div>
    ) : (
      <></>
    );
  };

  const RecipientHoverCard: React.FC<{
    recipients: UserModel[] | undefined;
  }> = ({ recipients }) => {
    const plainCardProps: IPlainCardProps = {
      onRenderPlainCard: () => (
        <div
          style={{
            padding: "10px",
            backgroundColor: "#FFFFFF",
            fontSize: "16px",
            fontWeight: 400,
            fontFamily: "Poppins",
            color: "#323232",
          }}
        >
          <ul>
            {recipients?.map((recipient, index) => (
              <li key={recipient.name}>{recipient.name}</li>
            ))}
          </ul>
        </div>
      ),
      directionalHint: 1,
    };
    return (
      <HoverCard type={HoverCardType.plain} plainCardProps={plainCardProps}>
        <div style={styles.RecepientHoverCard}>
          ,&nbsp;{`+${recipients?.length} People`}
        </div>
      </HoverCard>
    );
  };

  function getVersionToData(
    fileType?: string,
    recepientList?: UserModel[] | undefined
  ) {
    if (fileType !== "draft") {
      return (
        <VerticalStack style={{ width: "30%" }}>
          <Text
            style={{
              fontSize: "12px",
              lineHeight: "16px",
              fontWeight: 400,
              fontFamily: "Poppins",
              color: "#717070",
            }}
          >
           {t("to")}
          </Text>
          <RenderPerson recipients={recepientList} />
        </VerticalStack>
      );
    } else {
      return <VerticalStack style={{ width: "30%" }}></VerticalStack>;
    }
  }

  return (
    <HorizontalStack style={styles.card}>
      <HorizontalStack
        style={{ width: "27%", justifyContent: "space-between" }}
      >
        {getFileTypeIcon(props.version.fileType, props.version.isRedlined)}
        {getVersionFromData(
          props.version?.fileType,
          props.version?.sharedBy.name
        )}
      </HorizontalStack>

      {getVersionToData(props.version?.fileType, props.version?.sharedTo)}
      {getIcons(props.version?.fileType)}
      <Text
        style={{
          fontSize: "16px",
          lineHeight: "20px",
          fontWeight: 600,
          fontFamily: "Poppins",
          color: "#323232",
        }}
      >
        V{props.version.documentVersion}
      </Text>
      <VerticalStack style={{ width: "15%" }}>
        {getFileTypeDate(props.version?.fileType)}
        <HorizontalStack style={styles.dateTime}>
          {getFormattedDateTime(props.version.lastUpdatedDate)[0]}&nbsp;|&nbsp;
          {getFormattedDateTime(props.version.lastUpdatedDate)[1]}
        </HorizontalStack>
      </VerticalStack>
    </HorizontalStack>
  );
};

export default VersionsListView;
const styles = {
  card: {
    width: "100%",
    height: "56px",
    background: "#FFFFFF",
    border: "1px solid #E8ECF4",
    alignItems: "center",
    borderRadius: "8px",
    padding: "10px 20px 10px 20px",
    justifyContent: "space-between",
  },
  fileName: {
    color: `${colorPallete.black1}`,
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: "600",
    lineHeight: "20px",
  },
  dateTime: {
    color: "#323232",
    fontWeight: "400",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontSize: "14px",
    lineHeight: "20px",
    justifyContent: "flex-end",
  },
  versions: {
    fontFamily: "Poppins",
    color: "#323232",
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "16px",
    width: "66px",
    height: "16px",
    paddingRight: "2px",
  },
  recepientList: {
    display: "inline-block",
    height: "100%",
    width: "90%",
  },
  Info: {
    fontSize: "16px",
    lineHeight: "20px",
    color: colorPallete.black,
    fontWeight: 500,
    fontFamily: "Poppins",
  },
  RecepientHoverCard: {
    fontSize: "16px",
    borderRadius: "1rem",
    minWidth: "1px",
    maxWidth: "fit-content",
    color: "#18468F",
    cursor: "pointer",
    lineHeight: "20px",
  },
};
