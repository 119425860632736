import { FC } from "react";
import { PageTitle } from "../../components/PageTitle";
import { useTranslation } from "react-i18next";
import { AllPrecedentDoc } from "../../modules/PrecedentDocuments/AllPrecedentDoc";
import { Stack } from "@fluentui/react";
import { setBoxView, setGridView } from "../../modules/redux/DocumentViewSlice";
import { HorizontalStack } from "../../components/Stack";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import SearchBar from "../../components/SearchBox";
import { colorPallete } from "../../assets/color";
import BoxIcon from "../../assets/Icons/svg/boxIcon";
import ListIcon from "../../assets/Icons/svg/listIcon";
import FadeTransition from "../../components/FadeTransition";

const MyPrecedentFiles: FC<any> = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const view = useAppSelector((state) => state.DocumentView.value);

  return (
    <FadeTransition>
      <Stack style={{height:"100%",overflowX:"hidden",width:"104%",overflowY:"auto"}}>
        <HorizontalStack
          style={{
            width: "100%",
            justifyContent: "space-between",
            height: "200px",
            alignItems: "flex-end",
            paddingTop: "10px",
            paddingBottom:'10px',
            paddingLeft:"5px",

          }}
        >
          <Stack style={{ width: "90%", height: "100%", justifyContent:"center" }}>
            <PageTitle
              pageTitle={t("my_precedent_files")}
              search={t("search_precedent")}
              project={false}
            />
          </Stack>
          <Stack
            style={{
              backgroundColor: "",
              alignItems: "flex-end",
              width: "32%",
              justifyContent: "center",
              marginRight: "2%",
              height: "90%",
            }}
          >
            <HorizontalStack style={styles.allDocuments}>
              <Stack style={styles.searchBar}>
                <SearchBar placeholder={t("search_document")} />
              </Stack>
              <div
                style={{
                  backgroundColor: !view ? colorPallete.btLightBlue : "white",
                  border: `2px solid ${colorPallete.lightGrey2}`,
                  borderRadius: "0.3rem",
                  cursor: "pointer",
                  padding: "10px 8px 10px 8px",
                  alignItems: "center",
                  display: "flex",
                }}
                title={String(t("list_view"))}
                onClick={() => {
                  dispatch(setGridView());
                }}
              >
                <ListIcon />
              </div>
              <div
                style={{
                  backgroundColor: view ? colorPallete.btLightBlue : "white",
                  border: `2px solid ${colorPallete.lightGrey2}`,
                  borderRadius: "0.3rem",
                  cursor: "pointer",
                  padding: "7px",
                  alignItems: "center",
                  display: "flex",
                }}
                onClick={() => {
                  dispatch(setBoxView());
                }}
                title={String(t("box"))}
              >
                <BoxIcon />
              </div>
            </HorizontalStack>
          </Stack>
        </HorizontalStack>
        <AllPrecedentDoc />
      </Stack>
    </FadeTransition>
  );
};

export default MyPrecedentFiles;

const styles = {
  top: {
    marginTop: "2%",
    backgroundColor: "",
    padding: 10,
    justifyContent: "space-between",
    paddingRight: 20,
  },
  allDocuments: {
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "",
  },
  header: {
    fontSize: "24px",
    paddingLeft: "1%",
    paddingBottom: "1%",
  },
  searchBar: {
    backgroundColor: "",
    alignItems: "center",
    width: "80%",
    height: "100%",
    justifyContent: "center",
  },
  button: {},
};
