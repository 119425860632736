import {
  HoverCard,
  HoverCardType,
  IPlainCardProps,
  IconButton,
  Modal,
  Separator,
  Stack,
  Text,
} from "@fluentui/react";
import { FC } from "react";
import { FormModal } from "../Modal";
import { HorizontalStack, VerticalStack } from "../../Stack";
import { colorPallete } from "../../../assets/color";
import { UserModel } from "../../../models";
import { t } from "i18next";

export const EmailTemplateModal: FC<any> = (props) => {
  const ModalStyles = {
    main: {
      width: "640px",
      height: "max-content",
      borderRadius: "12px",
      backgroundColor: "#EFECF9",
      padding: "8px",
    },
  };
  const styles = {
    recepientList: {
      display: "inline-block",
      height: "100%",
      width: "90%",
    },
    Info: {
      fontSize: "16px",
      lineHeight: "28px",
      color: colorPallete.black,
      fontWeight: 400,
      fontFamily: "Poppins",
    },
    RecepientHoverCard: {
      fontSize: "16px",
      borderRadius: "1rem",
      minWidth: "1px",
      maxWidth: "fit-content",
      color: "#18468F",
      cursor: "pointer",
      lineHeight: "28px",
      fontFamily: "Poppins",
    },
  };
  interface Props1 {
    recipients: UserModel[] | undefined;
  }
  const RenderPerson: FC<Props1> = (props: any) => {
    const { recipients } = props;
    const remainingCount = recipients.length - 1;

    return recipients.length ? (
      <div style={{ ...styles.recepientList, wordWrap: "break-word" }}>
        <HorizontalStack style={{ width: "100%", flexWrap: "wrap" }}>
          <Text style={styles.Info}>{recipients[0]?.name}</Text>
          {remainingCount > 0 && (
            <RecipientHoverCard recipients={recipients.slice(1)} />
          )}
        </HorizontalStack>
      </div>
    ) : (
      <></>
    );
  };

  const RecipientHoverCard: React.FC<{
    recipients: UserModel[] | undefined;
  }> = ({ recipients }) => {
    const plainCardProps: IPlainCardProps = {
      onRenderPlainCard: () => (
        <div
          style={{
            padding: "10px",
            backgroundColor: "#FFFFFF",
            fontSize: "16px",
            fontWeight: 400,
            fontFamily: "Poppins",
            color: "#323232",
          }}
        >
          <ul>
            {recipients?.map((recipient, index) => (
              <li key={recipient.name}>{recipient.name}</li>
            ))}
          </ul>
        </div>
      ),
      directionalHint: 1,
    };
    return (
      <HoverCard type={HoverCardType.plain} plainCardProps={plainCardProps}>
        <div style={styles.RecepientHoverCard}>
          ,&nbsp;{`+${recipients?.length} People`}
        </div>
      </HoverCard>
    );
  };

  return (
    <Stack>
      <Modal
        styles={ModalStyles}
        isOpen={props.isModalOpen}
        onDismiss={props.hideModal}
      >
        <VerticalStack
          style={{
            width: "624px",
            height: "max-content",
            border: "1px solid #EFECF9",
            borderRadius: "8px",
            backgroundColor: "white",
          }}
        >
          <HorizontalStack
            horizontalAlign="space-between"
            style={{
              width: "69%",
              height: "78px",
              borderRadius: "8px 8px 0px 0px",
              padding: "24px",
              gap: "24px",
              alignSelf: "flex-end",
              alignItems: "center",
              
            }}
          >
            <img src={require("../../../assets/Icons/dexterity.png")} style={{width:"162px",height:"36px"}} />

            <img
              onClick={props.hideModal}
              style={{ width: "13.11px", height: "13.11px", cursor: "pointer" }}
              src={require("../../../assets/Icons/cross.png")}
            />
          </HorizontalStack>
          <Separator />
          <VerticalStack
            style={{
              width: "100%",
              height: "max-content",
              borderRadius: "0px 0px 8px 8px ",
              padding: "24px",
              gap: "10px",
              backgroundColor: "",
            }}
          >
            <VerticalStack
              style={{
                width: "528px",
                height: "84px",
                backgroundColor: "",
              }}
            >
              <HorizontalStack
                style={{ width: "100%", height: "28px", gap: "5px" }}
              >
                <Text
                  style={{
                    fontSize: "16px",
                    lineHeight: "28px",
                    fontWeight: 400,
                    fontFamily: "Poppins",
                    color: "#717070",
                    width: "60px",
                  }}
                >
                 {t("from")}:
                </Text>
                <Text
                  style={{
                    fontSize: "16px",
                    lineHeight: "28px",
                    fontWeight: 400,
                    fontFamily: "Poppins",
                    color: "#1F2430",
                  }}
                >
                  {props.sharedBy.name} {"<"}
                  {props.sharedBy.email}
                  {">"}
                </Text>
              </HorizontalStack>
              <HorizontalStack
                style={{
                  width: "100%",
                  height: "28px",
                  gap: "5px",
                  alignItems: "center",
                }}
              >
                <Text
                  style={{
                    fontSize: "16px",
                    lineHeight: "28px",
                    fontWeight: 400,
                    fontFamily: "Poppins",
                    color: "#717070",
                    width: "60px",
                  }}
                >
              {t("to")}:
                </Text>
                <RenderPerson recipients={props.sharedTo} />
              </HorizontalStack>
              <HorizontalStack
                style={{ width: "100%", height: "28px", gap: "5px" }}
              >
                <Text
                  style={{
                    fontSize: "16px",
                    lineHeight: "28px",
                    fontWeight: 400,
                    fontFamily: "Poppins",
                    color: "#717070",
                    width: "60px",
                  }}
                >
                 {t("cc")}:
                </Text>
              </HorizontalStack>
            </VerticalStack>
            <VerticalStack
              style={{
                width: "576px",
                height: "fit-content",
                backgroundColor: "#F9F9FF",
                padding: "24px",
                gap: "10px",
              }}
            >
              <VerticalStack style={{ width: "528px", gap: "8px" }}>
                <Text
                  style={{
                    fontSize: "16px",
                    lineHeight: "28.8px",
                    fontWeight: 400,
                    fontFamily: "Poppins",
                    color: "#323232",
                  }}
                >
                  {props.emailNotes}
                </Text>
                <Text
                  style={{
                    fontSize: "16px",
                    lineHeight: "28px",
                    fontWeight: 400,
                    fontFamily: "Poppins",
                    color: "#323232",
                  }}
                >
                  {props.sharedBy.name}
                </Text>
                <Text
                  style={{
                    fontSize: "16px",
                    lineHeight: "28px",
                    fontWeight: 400,
                    fontFamily: "Poppins",
                    color: "#007CFF",
                    textDecorationLine: "underline",
                  }}
                >
                  {props.sharedBy.email}
                </Text>
                <Text
                  style={{
                    fontSize: "16px",
                    lineHeight: "28px",
                    fontWeight: 400,
                    fontFamily: "Poppins",
                    color: "#323232",
                  }}
                >
                  Project: {props.projectName}
                </Text>
              </VerticalStack>
            </VerticalStack>
          </VerticalStack>
        </VerticalStack>
      </Modal>
    </Stack>
  );
};
