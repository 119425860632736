import { FC, useEffect, useState } from "react";
import { HorizontalStack, VerticalStack } from "../../Stack";
import { Checkbox, Text } from "@fluentui/react";
import { Document } from "../../../models";
import Draft from "../MultiShareModal/icons/Draft";
import Recieved from "../MultiShareModal/icons/Received";
import Sent from "../MultiShareModal/icons/Sent";
import { t } from "i18next";


type DocumentAndVersions = {
  rootDocumentId: number;
  rootDocumentName: string;
  driveItemId: string;
  versions: Document[];
};
type SelectDocumentProps = {
  document: DocumentAndVersions;
  selectedDocuments: Document[];
  setSelectedDocuments: React.Dispatch<React.SetStateAction<Document[]>>;
};

type SelectDocumentGroupProps = {
  documents: DocumentAndVersions[];
  selectedDocuments: Document[];
  setSelectedDocuments: React.Dispatch<React.SetStateAction<Document[]>>;
};
const selectDocumentStackStyle = {
  gap: "10px",
  width: "696px",
  height: "42px",
  padding: "9px 20px",
  justifyContent: "space-between",
  alignItems: "center",
  borderRadius: "6px",
  border: "1px solid #E8ECF4",
  ":hover": {
    backgroundColor: "#F3F5F9",
    border: "1px solid #007CFF",
  },
};

const textStyle = {
  color: "#717070",
  fontFamily: "Poppins",
  fontSize: "9px",
  fontWeight: 400,
  lineHeight: "14px",
};

const dateStyle = {
  color: "#1F2430",
  fontFamily: "Poppins",
  fontSize: "9px",
  fontWeight: 400,
  lineHeight: "14px",
};

const selectDocumentGroupStyle = {
  maxHeight: "600px",
  background: "#FFF",
  padding: "14px",
  display: "flex",
  gap: "6px",
  overflowY: "auto",
};
const documentNameStyle = {
  color: "#1F2430",
  fontFamily: "Poppins",
  fontSize: "12px",
  fontWeight: 500,
  lineHeight: "14px",
  width: "240px",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

const formatDate = (inputDate: Date) => {
  const formattedDate = `${
    inputDate.getUTCMonth() + 1
  }/${inputDate.getUTCDate()}/${inputDate.getUTCFullYear()}`;

  // Format the time to "hh:mm[am/pm]" format
  const hours = inputDate.getUTCHours() % 12 || 12; // Convert to 12-hour format
  const minutes = inputDate.getUTCMinutes();
  const ampm = inputDate.getUTCHours() < 12 ? "am" : "pm";
  const formattedTime = `${hours}:${minutes
    .toString()
    .padStart(2, "0")}${ampm}`;

  const finalFormattedDate = `${formattedDate} ${formattedTime}`;
  return finalFormattedDate;
};

const SelectDocument: FC<SelectDocumentProps> = (props) => {
  const [selectedVersion, setSelectedVersion] = useState(() => {
    const initialSelectedVersion = props.selectedDocuments.find(
      (doc) => doc.rootDocumentId === props.document.rootDocumentId
    );

    return initialSelectedVersion || props.document.versions[0];
  });
  const [checked, setChecked] = useState(
    props.selectedDocuments.some(
      (doc) => doc.rootDocumentId === props.document.rootDocumentId
    )
  );
  const handleSelectChange = (event: any) => {
    const selectedIndex = event.target.selectedIndex;
    const newSelectedVersion = props.document.versions[selectedIndex];
    setSelectedVersion(newSelectedVersion);
  };

  const handleCheckboxChange = (ev: any, isChecked: any) => {
    setChecked(isChecked);
    if (!isChecked) {
      props.setSelectedDocuments((prevSelectedDocuments) =>
        prevSelectedDocuments.filter(
          (doc) => doc.rootDocumentId !== props.document.rootDocumentId
        )
      );
    }
  };
  useEffect(() => {
    if (checked) {
      props.setSelectedDocuments((prevSelectedDocuments) => {
        const filteredDocuments = prevSelectedDocuments.filter(
          (doc) => doc.rootDocumentId !== props.document.rootDocumentId
        );

        return [...filteredDocuments, selectedVersion];
      });
    }
  }, [selectedVersion, checked]);

  return (
    <HorizontalStack styles={{ root: selectDocumentStackStyle }}>
      <Checkbox
        styles={{
          checkbox: {
            width: "12px",
            height: "12px",
            stroke: "#007CFF",
            strokeWidth: "0.8px",
            borderColor: "#007CFF",
          },
        }}
        checked={checked}
        onChange={handleCheckboxChange}
      />
      {selectedVersion?.fileType === "draft" ? (
        <Draft />
      ) : selectedVersion?.fileType === "received" ? (
        <Recieved />
      ) : (
        <Sent />
      )}
      <Text style={{ whiteSpace: "nowrap", ...documentNameStyle }}>
        {props.document.rootDocumentName?.replace(/\.[^/.]+$/, "")}
      </Text>
      <select
        style={selectStyle}
        onChange={handleSelectChange}
        value={selectedVersion ? `v${selectedVersion.documentVersion}` : ""}
      >
        {props.document.versions.map(
          (version, index) =>
            version.documentVersion !== null && (
              <option key={index}>

                {index === 0
                  ? (
                    <Text>
                   v{version?.documentVersion} ({t("most_recent")})
                    </Text>
                  
                    )
                  : (`v${version?.documentVersion}`)
                  }
              </option>
            )
        )}
      </select>
      <HorizontalStack
        styles={{
          root: { gap: "4px", minWidth: "155px", justifyContent: "flex-end" },
        }}
      >
        <Text style={textStyle}>
          {selectedVersion?.fileType === "draft"
            ? t("last_saved_on")
            : selectedVersion?.fileType === "sent"
            ? t("sent_on")
            : t("received_on")
            }
        </Text>
        <Text style={dateStyle}>
          {formatDate(new Date(selectedVersion?.lastUpdatedDate!))}
        </Text>
      </HorizontalStack>
    </HorizontalStack>
  );
};

const SelectDocumentGroup: FC<SelectDocumentGroupProps> = (props) => {
  // useEffect(() => {
  //   console.log(props?.selectedDocuments);
  // }, [props?.selectedDocuments]);
  // console.log(props.documents);
  return (
    <VerticalStack styles={{ root: selectDocumentGroupStyle }}>
      {props.documents.map((document) => (
        <SelectDocument
          key={document.rootDocumentId}
          document={document}
          selectedDocuments={props?.selectedDocuments}
          setSelectedDocuments={props?.setSelectedDocuments}
        />
      ))}
    </VerticalStack>
  );
};

export default SelectDocumentGroup;

const selectStyle = {
  width: "132px",
  borderRadius: "4px",
  border: "1px solid var(--LIGHT-BLUE, #E8ECF4)",
  background: "#F8F8F8",
  padding: "4px",
  color: "#18468F",
  fontFamily: "Poppins",
  fontSize: "12px",
  fontWeight: 500,
  cursor: "pointer",
};
