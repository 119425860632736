import { FC, ReactElement } from "react";
import LanguageDropdown from "../LanguageDropdown";
import Signin from "../Login";
import { Logo } from "../Logo/index.";
import { HorizontalStack } from "../Stack";

const headerStackStyles = {
  root: {
    backgroundColor: '',
    padding: '0.8%',
  },
};

const Header: FC = (): ReactElement => {
  return (
    <HorizontalStack styles={headerStackStyles} horizontalAlign="space-between">
      <Logo />
      <HorizontalStack >

        <LanguageDropdown />
        <Signin />
      </HorizontalStack>
    </HorizontalStack>
  );
};

export default Header;
