import {
  FC,
  useState,
  useCallback,
  FormEvent,
  useEffect,
} from "react";
import { FormModal } from "../Modal";
import { HorizontalStack, VerticalStack } from "../../Stack";
import { getAllProjects } from "../../../modules/redux/Projects/project";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import {
  ComboBox,
  DefaultButton,
  IComboBox,
  IComboBoxOption,
  IStackTokens,
  Label,
  Separator,
  Stack,
  Text,
  TextField,
  Checkbox,
  mergeStyles,
  IconButton,
  FontIcon,
} from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { Document, Project, response } from "../../../models";
import { colorPallete } from "../../../assets/color";
import {
  createTemplate,
  getPrecedentDocuments,
} from "../../../modules/redux/Documents/document";
import Loader from "../../Loader";

const formDataStyles = {
  paddingTop: "3%",
  width: "100%",
};

const success = mergeStyles({
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
  marginTop: "3%",
});

const uploadModal = mergeStyles({
  display: "flex",
  justifyContent: "space-evenly",
  width: "100%",
  height: "8rem",
  alignItems: "center",
  padding: "1%",
});

const buttonStackStyles = {
  root: {
    paddingRight: "2.5%",
  },
};
const horizontalStackTokens: IStackTokens = {
  childrenGap: "2%",
};

const styles = {
  label: {
    color: colorPallete.black1,
    fontSize: "14px",
    fontWeight: "500",
  },
  descriptionLabel: {
    color: colorPallete.black1,
    fontSize: "14px",
    fontWeight: "500",
    marginBottom: "4px",
    marginTop: "2%",
  },
};

type Props = {
  document: Document;
  isModalOpen: boolean;
  showModal: any;
  hideModal: any;
};

export const CopyDocumentModal: FC<Props> = (props) => {
  const [validationMessage, setValidationMessage] = useState("");
  const [fileName, setFileName] = useState("");
  const [fileNameError, setFileNameError] = useState(false);
  const { data } = useAppSelector((state) => state.fetchProjects);
  const [dropData, setDropData] = useState<IComboBoxOption[]>([]);
  const [isNewFolder, setIsNewFolder] = useState(false);
  const [estimatedDateOfCompletion, setEstimatedDateOfCompletion] =
    useState("");
  const [description, setDescription] = useState("");
  const [targetFolderName, setTargetFolderName] = useState("");
  const [modal, setModal] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [folderNameError, setFolderNameError] = useState(false);
  const [projectExist, setProjectExist] = useState(false);
  const [counterPartyError, setCounterPartyError] = useState(false);
  const createNewTemplateState = useAppSelector(
    (state) => state.createTemplate
  );
  const [upload, setUpload] = useState(false);
  useEffect(() => {
    if (props.document.documentName) {
      setFileName(props.document.documentName);
    }
  }, [props.document]);

  function updating(data: Project[]) {
    let dropdownData: IComboBoxOption[] = [];
    data &&
      data.forEach((project) => {
        let key = project.projectName;
        let text = project.projectName;
        dropdownData.push({ key: key, text: text });
      });
    setTargetFolderName(data[0].projectName);
    setDropData(dropdownData);
  }

  useEffect(() => {
    if (data && data.length > 0) {
      updating(data);
      setTargetFolderName(data[0].projectName);
    }
  }, [data]);

  function onComboBoxChange(
    event: FormEvent<IComboBox>,
    option: IComboBoxOption | undefined
  ) {
    setFileNameError(false);
    setFolderNameError(false);
    setTargetFolderName(String(option?.key));
  }

  const onChangeFileNameTextField = useCallback(
    (
      event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      newValue?: string
    ) => {
      setFileName(newValue || "");
      if (newValue?.length === 0) {
        setFileNameError(true);
        setValidationMessage("valid_name");
      } else {
        setFileNameError(false);
      }
    },
    []
  );

  const [textFields, setTextFields] = useState<string[]>([""]);

  const addCounterPartyField = () => {
    setTextFields([...textFields, ""]);
  };
  const updatedTextFields = [...textFields];
  const removeTextField = (index: number) => {
    updatedTextFields.splice(index, 1);
    setTextFields(updatedTextFields);
  };

  useEffect(() => {}, [textFields]);

  const handleTextFieldChange = (index: number, value: string) => {
    updatedTextFields[index] = value;
    if (value.length === 0) {
      setCounterPartyError(true);
    } else {
      setCounterPartyError(false);
    }
    setTextFields(updatedTextFields);
  };

  const onChangeTargetFolderNameTextField = useCallback(
    (event: any, newValue: any) => {
      if (newValue?.length === 0) {
        setFolderNameError(true);
      } else {
        setFileNameError(false);
        setProjectExist(false);
        setFolderNameError(false);
      }
      setTargetFolderName(newValue ?? "");
    },
    []
  );

  const onChangeEstimatedDateOfCompletionTextField = useCallback(
    (event: any, newValue: any) => {
      setEstimatedDateOfCompletion(newValue ?? "");
    },
    []
  );
  function onChangeMultiLine(
    event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) {
    setDescription(newValue ?? "");
  }
  const OnClickIsNewFolderButton = () => {
    setTargetFolderName("");
    setEstimatedDateOfCompletion("");
    setDescription("");
    setFolderNameError(false);
    setFileNameError(false);
    setIsNewFolder((value) => !value);
  };
  const CreateTemplate = () => {
    setModal(true);
    let obj = {
      folderName: props.document.folderName,
      fileName: props.document.driveItemName,
      isNewFolder: isNewFolder,
      newFileName:
        fileName?.split(".docx")[0].split(".DOCX")[0].split(".DOCx")[0] +
        ".docx",
      targetFolderName: targetFolderName.trim(),
      newFolderData: {
        projectName: targetFolderName,
        estimatedCompletionDate: estimatedDateOfCompletion,
        counterParties: textFields.toString(),
        description: description,
        projectOwner: "",
      },
    };

    dispatch(createTemplate(obj)).then((res: any) => {
      setUpload(true);
      if (
        res.meta.requestStatus === "fulfilled" &&
        res.payload.code === 200 
      ) {
        setMessage("copy_successfull");
        setTimeout(() => {
          setUpload(false);
          dispatch(getAllProjects({}));
          dispatch(
            getPrecedentDocuments({
              limit: 10,
              offset: 0,
            })
          );
          disable();
        }, 2000);
      } else if (res.meta.requestStatus === "fulfilled" && res.payload.code === 400) {
        setMessage(res.payload.message);
        setTimeout(() => {
          setModal(false);
          setUpload(false);
        }, 2500);
      } else {
        setMessage(
          createNewTemplateState.data.message
            ? createNewTemplateState.data.message
            : "something_went_wrong"
        );
        setTimeout(() => {
          setModal(false);
          setUpload(false);
        }, 2500);
      }
    });
  };

  const handleCreateTemplateValidation = () => {
    const currentFileName = props.document.documentName;
    if (
      fileName === currentFileName &&
      props.document.folderName === targetFolderName
    ) {
      setFileNameError(true);
      setValidationMessage("same_file_name");
      return;
    }
    if (isNewFolder) {
      if (fileName.length === 0) {
        setFileNameError(true);
        setValidationMessage("valid_name");
      }
      if (targetFolderName.length === 0) {
        setFolderNameError(true);
      }
      if (textFields[0].length === 0) {
        setCounterPartyError(true);
      }
      if (
        targetFolderName.length !== 0 &&
        textFields[0].length !== 0 &&
        fileName.length !== 0
      ) {
        CreateTemplate();
      }
    } else if (fileName.length !== 0) {
      CreateTemplate();
    }
  };

  const disable = () => {
    setMessage("");
    setModal(false);
    setTextFields([""]);
    setFolderNameError(false);
    props.hideModal();
    setFileNameError(false);
    setCounterPartyError(false);
    setIsNewFolder(false);
    setValidationMessage("");
  };

  return (
    <FormModal
      title={t("copy_document")}
      isModalOpen={props.isModalOpen}
      showModal={props.showModal}
      hideModal={props.hideModal}
      fileName={props.document.documentName}
      width="600px"
      height="max-content"
      cancelButton={modal}
    >
      {modal ? (
        <Stack className={uploadModal}>
          {upload && (
            <VerticalStack className={success}>
              {message === "copy_successfull" ? (
                <FontIcon
                  iconName="SkypeCircleCheck"
                  style={{
                    fontSize: "40px",
                    backgroundColor: "white",
                    color: "green",
                  }}
                />
              ) : (
                <Stack style={{opacity:"0.7"}}>
                  <FontIcon
                    iconName="StatusErrorFull"
                    style={{
                      fontSize: "40px",
                      backgroundColor: "white",
                      color: "red",
                    }}
                  />
                </Stack>
              )}
            </VerticalStack>
          )}
          {!upload && <Loader />}
          <Text style={{ fontSize: "18px", paddingBottom: "4%" }}>
            {upload ? t(message) : t("loading")}
          </Text>
        </Stack>
      ) : (
        <Stack style={{ padding: "12px", backgroundColor:"white" }}>
          <Stack
            style={{
              maxHeight: "442px",
              overflowY: "scroll",
              overflowX: "hidden",
            }}
          >
            <Label
              htmlFor="fileName"
              style={{
                color: colorPallete.black1,
                fontSize: "0.9rem",
                fontWeight: "500",
              }}
            >
              {t("file_name")}
            </Label>
            <TextField
              style={{
                height: "46px",
                backgroundColor: "#f3f5f9",
                borderRadius: "4px",
              }}
              borderless
              id="fileName"
              value={
                "v" +
                props.document.documentVersion +
                "-" +
                props.document.documentName
              }
              disabled={true}
              placeholder={String(t("file_name"))}
            />
            <Label
              required
              htmlFor="fileName"
              style={{
                color: colorPallete.black1,
                fontSize: "0.9rem",
                fontWeight: "500",
                marginTop: "3%",
              }}
            >
              {t("new_file_name")}
            </Label>
            <TextField
              style={{
                height: "46px",
                backgroundColor: "#f3f5f9",
                borderRadius: "4px",
              }}
              borderless
              id="newfileName"
              value={fileName}
              onChange={onChangeFileNameTextField}
              placeholder={String(t("file_name"))}
            />
            {fileNameError && (
              <Text style={{ color: colorPallete.btRed, marginTop: "3%" }}>
                {t(validationMessage)}
              </Text>
            )}
            <HorizontalStack
              horizontalAlign="end"
              style={{ backgroundColor: "", marginTop: "5%" }}
            >
              <Checkbox
                label={String(t("create_new_project"))}
                onChange={OnClickIsNewFolderButton}
              />
            </HorizontalStack>
            <div>
              {!isNewFolder ? (
                <VerticalStack styles={{ root: { minWidth: "80%" } }}>
                  <Label
                    required
                    htmlFor="folderName"
                    style={{
                      color: colorPallete.black1,
                      fontSize: "0.9rem",
                      fontWeight: "500",
                      marginBottom: "4px",
                    }}
                  >
                    {t("project_name")}
                  </Label>
                  <ComboBox
                    options={dropData}
                    placeholder={String(t("select_project_name"))}
                    autoComplete="on"
                    onChange={onComboBoxChange}
                    defaultSelectedKey={targetFolderName}
                    required={true}
                    text={targetFolderName}
                    styles={{
                      optionsContainerWrapper: {
                        maxHeight: "120px",
                        overflowY: "scroll",
                        width: "450px",
                      },
                    }}
                  />
                  {folderNameError && (
                    <Text style={{ color: colorPallete.btRed }}>
                      {t("project_name_required")}
                    </Text>
                  )}
                </VerticalStack>
              ) : (
                <VerticalStack styles={{ root: { minWidth: "80%" } }}>
                  <Stack style={{ gap: "15px" }}>
                    <Stack>
                      <Label required htmlFor="targetFolderName">
                        {t("project_name")}
                      </Label>
                      <TextField
                        id="targetFolderName"
                        style={{
                          height: "46px",
                          backgroundColor: "#f3f5f9",
                          borderRadius: "4px",
                        }}
                        borderless
                        value={targetFolderName}
                        onChange={onChangeTargetFolderNameTextField}
                        placeholder={String(t("project_name"))}
                      />
                    </Stack>
                    {folderNameError && (
                      <Text style={{ color: colorPallete.btRed }}>
                        {t("project_name_required")}
                      </Text>
                    )}
                    {projectExist && (
                      <Text style={{ color: colorPallete.btRed }}>
                        {t("project_already_exist")}
                      </Text>
                    )}
                  </Stack>
                  <Stack style={formDataStyles}>
                    <HorizontalStack horizontalAlign="space-between">
                      <Label
                        required
                        htmlFor="counterParty"
                        style={styles.label}
                      >
                        {t("counter_party")}
                      </Label>
                      <IconButton
                        iconProps={{ iconName: "CircleAdditionSolid" }}
                        onClick={addCounterPartyField}
                        style={{
                          width: "25px",
                          height: "25px",
                          backgroundColor: "",
                          marginRight: "2.5%",
                          color: "#2B579A",
                        }}
                      />
                    </HorizontalStack>
                    <div style={{ backgroundColor: "" }}>
                      {textFields.map((value, index) => (
                        <div
                          key={index}
                          style={{
                            display: "flex",
                            width: "100%",
                            backgroundColor: "#f3f5f9",
                            height: "46px",
                            borderRadius: "4px",
                            marginTop: index > 0 ? "3%" : 0,
                          }}
                        >
                          <TextField
                            style={{
                              height: "46px",
                              backgroundColor: "#f3f5f9",
                              borderRadius: "4px",
                              width: "500px",
                            }}
                            borderless
                            id="counterParty"
                            value={value}
                            onChange={(e) =>
                              handleTextFieldChange(
                                index,
                                e.currentTarget.value
                              )
                            }
                            placeholder={String(t("counter_party"))}
                          />
                          {index === 0 ? (
                            <img
                              src={require("../../../assets/Icons/questionIcon.png")}
                              style={{
                                width: "15px",
                                height: "15px",
                                backgroundColor: "#f3f5f9",
                                marginLeft: "2%",
                                marginTop: "3%",
                              }}
                              title={String(t("add_more_counter_parties"))}
                            />
                          ) : (
                            <IconButton
                              iconProps={{ iconName: "Blocked2Solid" }}
                              onClick={() => removeTextField(index)}
                              style={{
                                width: "20px",
                                height: "20px",
                                backgroundColor: "#f3f5f9",
                                marginLeft: "2%",
                                marginTop: "2%",
                                color: "#2B579A",
                              }}
                            />
                          )}
                        </div>
                      ))}
                    </div>
                    {isNewFolder && counterPartyError && (
                      <Text style={{ color: colorPallete.btRed }}>
                        {t("counter_party_required")}
                      </Text>
                    )}
                  </Stack>
                  <div style={{ paddingTop: "2%" }}>
                    <Label htmlFor="estimatedDateOfCompletion">
                      {t("completion_date")}
                    </Label>
                    <TextField
                      id="estimatedDateOfCompletion"
                      type="date"
                      style={{
                        height: "46px",
                        backgroundColor: "#f3f5f9",
                        borderRadius: "4px",
                      }}
                      borderless
                      value={estimatedDateOfCompletion}
                      onChange={onChangeEstimatedDateOfCompletionTextField}
                    />
                  </div>

                  <div style={{ paddingTop: "3%" }}>
                    <Label htmlFor="description">{t("description")}</Label>
                    <TextField
                      id="description"
                      style={{
                        height: "46px",
                        backgroundColor: "#f3f5f9",
                        borderRadius: "4px",
                      }}
                      borderless
                      multiline={true}
                      resizable={false}
                      value={description}
                      onChange={onChangeMultiLine}
                      placeholder={String(t("add_description"))}
                    />
                  </div>
                </VerticalStack>
              )}
            </div>
          </Stack>
          <Stack
            style={{
              position: "relative",
              right: 0,
              width: "100%",
              marginTop: "1%",
            }}
          >
            <Separator />
            <HorizontalStack
              horizontalAlign="end"
              styles={buttonStackStyles}
              tokens={horizontalStackTokens}
            >
              {!isNewFolder ? (
                <DefaultButton
                  style={{
                    backgroundColor: colorPallete.btBlue,
                    color: colorPallete.white,
                    borderRadius: "4px",
                    border: "0px ",
                  }}
                  allowDisabledFocus
                  onClick={handleCreateTemplateValidation}
                >
                  {t("copy")}
                </DefaultButton>
              ) : (
                <DefaultButton
                  style={{
                    backgroundColor: colorPallete.btBlue,
                    color: colorPallete.white,
                    borderRadius: "4px",
                    border: "0px ",
                  }}
                  allowDisabledFocus
                  onClick={handleCreateTemplateValidation}
                >
                  {t("create_project_copy")}
                </DefaultButton>
              )}
              <DefaultButton
                onClick={disable}
                allowDisabledFocus
                style={{
                  backgroundColor: colorPallete.btLightBlue,
                  color: colorPallete.black1,
                  borderRadius: "4px",
                  border: "0px ",
                }}
              >
                {t("cancel")}
              </DefaultButton>
            </HorizontalStack>
          </Stack>
        </Stack>
      )}
    </FormModal>
  );
};
