import { FC } from "react";
import { HorizontalStack } from "../Stack";
import { Text } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../hooks/hooks";
import { colorPallete } from "../../assets/color";

export const Logo: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  function click() {
    navigate("home");
  }
  const functionalityState = useAppSelector(
    (state) => state.DocumentStack.value
  );

  return (
    <HorizontalStack
      onClick={click}
      style={styles.container}
      verticalAlign="center"
    >
      <img src={require("../../assets/Icons/dexterity.png")} style={{width:"162px",height:"36px"}} />
  </HorizontalStack>
  );
};

const styles = {
  container: { cursor: "pointer", backgroundColor: "", alignItems: "center" },
  headerStyle: {
    fontSize: "1.3rem",
    color: colorPallete.black2,
    fontWeight: "400",
    lineHeight: "16px",
    fontFamily: "Poppins",
  },
  logo: {
    marginLeft: "6px",
    padding: "7px 8px",
    borderRadius: "5px",
    fontSize: "16px",
  },
};
