import React from "react";

function ReceivedIcon() {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.175 0.299805H2.67499C2.20624 0.299805 1.73749 0.499805 1.36249 0.899805C0.987488 1.1998 0.799988 1.6998 0.799988 2.2998V18.2998C0.799988 18.7998 0.987488 19.2998 1.36249 19.6998C1.73749 20.0998 2.20624 20.2998 2.67499 20.2998H13.925C14.3937 20.2998 14.8625 20.0998 15.2375 19.6998C15.6125 19.2998 15.8 18.7998 15.8 18.2998V6.2998M10.175 0.299805L15.8 6.2998L10.175 0.299805ZM10.175 0.299805V6.2998H15.8"
        fill="#E8ECF4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.40009 12.3004C4.40009 12.0795 4.57917 11.9004 4.80009 11.9004H11.8001C12.021 11.9004 12.2001 12.0795 12.2001 12.3004C12.2001 12.5213 12.021 12.7004 11.8001 12.7004H4.80009C4.57917 12.7004 4.40009 12.5213 4.40009 12.3004Z"
        fill="#1F2430"
      />
      <path
       fillRule="evenodd"
       clipRule="evenodd"
        d="M4.40009 15.3004C4.40009 15.0795 4.57917 14.9004 4.80009 14.9004H11.8001C12.021 14.9004 12.2001 15.0795 12.2001 15.3004C12.2001 15.5213 12.021 15.7004 11.8001 15.7004H4.80009C4.57917 15.7004 4.40009 15.5213 4.40009 15.3004Z"
        fill="#1F2430"
      />
      <path
       fillRule="evenodd"
       clipRule="evenodd"
        d="M4.40009 9.30039C4.40009 9.07948 4.57917 8.90039 4.80009 8.90039H6.80009C7.021 8.90039 7.20009 9.07948 7.20009 9.30039C7.20009 9.5213 7.021 9.70039 6.80009 9.70039H4.80009C4.57917 9.70039 4.40009 9.5213 4.40009 9.30039Z"
        fill="#1F2430"
      />
      <path
        d="M20.7997 11.1625V19.4575C20.7997 20.4713 19.9447 21.3008 18.8997 21.3008H3.69974C2.65474 21.3008 1.79974 20.4713 1.79974 19.4575V9.31921L4.83974 7.4759V2.8676C4.83974 1.94594 5.59974 1.30078 6.45474 1.30078H16.5247C17.4747 1.30078 18.1397 2.03811 18.1397 2.8676V7.84456H18.2347L20.7997 9.31921C20.7997 9.31921 20.7997 10.1487 20.7997 11.1625Z"
        fill="white"
      />
      <path
       fillRule="evenodd"
       clipRule="evenodd"
        d="M5.16512 7.09038C5.28113 7.27838 5.22278 7.52483 5.03478 7.64085L2.19984 9.3903V19.4338C2.19984 20.2348 2.86669 20.9004 3.69039 20.9004H18.8148C19.2097 20.9004 19.6076 20.7138 19.9123 20.4047C20.2173 20.0952 20.3998 19.6922 20.3998 19.3004V9.30318L18.0456 7.92569C17.8549 7.81413 17.7908 7.56911 17.9024 7.37844C18.0139 7.18777 18.2589 7.12364 18.4496 7.2352L21.0018 8.72853C21.1245 8.80029 21.1998 8.9317 21.1998 9.07378V19.3004C21.1998 19.9353 20.9097 20.5323 20.4821 20.9662C20.0542 21.4004 19.4597 21.7004 18.8148 21.7004H3.69039C2.43448 21.7004 1.39984 20.6861 1.39984 19.4338V9.16711C1.39984 9.02839 1.47172 8.89957 1.58978 8.82671L4.61465 6.96004C4.80265 6.84403 5.0491 6.90238 5.16512 7.09038Z"
        fill="#A82C0C"
      />
      <path
     fillRule="evenodd"
     clipRule="evenodd"
        d="M4.98388 1.48838C5.33105 1.13938 5.80424 0.900391 6.38977 0.900391H15.9293C17.181 0.900391 18.1998 1.92761 18.1998 3.18073V11.9243C18.1998 12.1452 18.0208 12.3243 17.7998 12.3243C17.5789 12.3243 17.3998 12.1452 17.3998 11.9243V3.18073C17.3998 2.36548 16.7352 1.70039 15.9293 1.70039H6.38977C6.04005 1.70039 5.76504 1.83747 5.55105 2.05258C5.3461 2.2586 5.19984 2.62682 5.19984 2.89868V11.9243C5.19984 12.1452 5.02076 12.3243 4.79984 12.3243C4.57893 12.3243 4.39984 12.1452 4.39984 11.9243V2.89868C4.39984 2.41841 4.62768 1.84645 4.98388 1.48838Z"
        fill="#A82C0C"
      />
      <path
       fillRule="evenodd"
       clipRule="evenodd"
        d="M1.45947 10.0904C1.57546 9.90241 1.8219 9.84403 2.00991 9.96002L9.00325 14.2743C10.3878 15.1092 12.212 15.1092 13.5965 14.2743C13.5971 14.274 13.5976 14.2737 13.5982 14.2733L20.5899 9.96002C20.7779 9.84403 21.0243 9.90241 21.1403 10.0904C21.2563 10.2784 21.1979 10.5249 21.0099 10.6409L14.0116 14.9583C12.372 15.9479 10.2278 15.9478 8.58823 14.9583L8.5849 14.9562L1.58988 10.6409C1.40187 10.5249 1.34348 10.2784 1.45947 10.0904Z"
        fill="#A82C0C"
      />
      <path
     fillRule="evenodd"
     clipRule="evenodd"
        d="M7.39984 10.3004C7.39984 10.0795 7.57893 9.90039 7.79984 9.90039H14.7998C15.0208 9.90039 15.1998 10.0795 15.1998 10.3004C15.1998 10.5213 15.0208 10.7004 14.7998 10.7004H7.79984C7.57893 10.7004 7.39984 10.5213 7.39984 10.3004Z"
        fill="#A82C0C"
      />
      <path
     fillRule="evenodd"
     clipRule="evenodd"
        d="M7.39984 7.30039C7.39984 7.07948 7.57893 6.90039 7.79984 6.90039H14.7998C15.0208 6.90039 15.1998 7.07948 15.1998 7.30039C15.1998 7.5213 15.0208 7.70039 14.7998 7.70039H7.79984C7.57893 7.70039 7.39984 7.5213 7.39984 7.30039Z"
        fill="#A82C0C"
      />
    </svg>
  );
}

export default ReceivedIcon;
