import React from "react";

function EmailCircleIcon() {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 9 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="4.5" cy="4" r="3.5" stroke="#18468F" />
    </svg>
  );
}
export default EmailCircleIcon;
