import { FC, FormEvent, useCallback, useEffect, useState } from "react";
import {
  FontIcon,
  Modal,
  Stack,
  Text,
  TextField,
} from "@fluentui/react";
import { HorizontalStack, VerticalStack } from "../../Stack";
import "./index.css";
import { useTranslation } from "react-i18next";
import { colorPallete } from "../../../assets/color";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import ShareWhiteIcon from "../../../assets/Icons/svg/shareWhiteIcon";
import {
  getDocumentversions,
  shareDocument,
} from "../../../modules/redux/Documents/document";
import { getAllProjectDetails } from "../../../modules/redux/Projects/project";
import Loader from "../../Loader";
import CrossIcon from "../../../assets/Icons/svg/crossIcon";
import ToggleButtonGroup from "../MultiShareModal/toggleButton";
import SelectedDocuments from "../MultiShareModal/SelectedDocument";
import EmailTextBox from "./EmailTextBox";
import { Document } from "../../../models/index";

const NewShareModal: FC<any> = (props) => {
  const modalStyles = {
    root: {
      background: "rgba(0, 0, 0, 0.5)",
    },
    main: {
      borderRadius: "8px",
      backgroundColor: "#ecf0f9",
      width: "752px",
    },
  };
  const { t } = useTranslation();
  const [selected, setSelected] = useState(String(t("forward")));
  const [user, setUser] = useState("");
  const [message, setMessage] = useState<string>("");
  const [error, setError] = useState(false);
  const [subjectError, setSetsubjectError] = useState(false);
  const [subject, setSubject] = useState(String(t("review_doc")));
  const [notes, setNotes] = useState(String(t("for_your_review")));
  const [fileName, setFileName] = useState("");
  const [folderName, setFolderName] = useState("");
  const [projectId,setProjectId]=useState()
  const [notesError, setNotesError] = useState<boolean>(false);
  const [userList, setUserList] = useState<string[]>([]);
  const [modal, setModal] = useState<boolean>(false);
  const [toEmails, setToEmails] = useState<string[]>([]);
  const [selectedId, setSelectedId] = useState(0);

  const dispatch = useAppDispatch();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const shareDocumentState = useAppSelector((state) => state.shareDocument);
  const shareSentDocumentState = useAppSelector(
    (state) => state.shareSentDocument
  );

  useEffect(() => {
    if (selectedId === 0) setToEmails([]);
    else if (selectedId === 1) {
      if (props?.potentialRecipients?.reply?.length > 0)
        setToEmails([props?.potentialRecipients?.reply!]);
    } else if (selectedId === 2) {
      if (props?.potentialRecipients?.replyAll?.length > 0)
        setToEmails(props?.potentialRecipients?.replyAll!);
    }
  }, [props.folderName]);

  useEffect(() => {
    if (props.projectId) {
      setProjectId(props.projectId);
    }
  }, [props.projectId]);

  const onChangeMultiLine = (
    event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ): void => {
    setNotesError(false);
    setNotes(newValue || "");
  };

  const onChangeSubjectTextField = useCallback(
    (
      event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      newValue?: string
    ) => {
      setSetsubjectError(false);
      setSubject(newValue || "");
    },
    []
  );

  const share = () => {
    if (toEmails.length === 0) {
      setError(true);
      setMessage("Add Emails");
    } else if (toEmails.length === 0) {
      setError(true);
      setMessage("Press Enter to Save");
    }
    if (notes.length === 0) {
      setNotesError(true);
    }
    if (subject.length === 0) {
      setSetsubjectError(true);
    }

    if (
      notes.length !== 0 &&
      toEmails.length !== 0 &&
      subject.length !== 0 &&
      props?.selectedDocuments?.length > 0 &&
      !error
    ) {
      var docIds: number[] = [];
      props?.selectedDocuments.forEach((document: Document) => {
        docIds.push(document?.documentId as number);
      });
      let data = {
        projectId: props?.selectedDocuments[0]?.projectId,
        emailList: toEmails,
        documentIds:docIds,
        subject: subject,
        emailNotes: notes,
      };
      setModal(true);
      dispatch(shareDocument(data))
        .then((res: any) => {
          setErrorMessage("shared_success");
          setTimeout(() => {
            if (res) {
              dispatch(
                getDocumentversions({
                  rootDocumentId: props.rootDocumentId,
                  limit: 6,
                  offset: 0,
                })
              );
              dispatch(getAllProjectDetails(props?.selectedDocuments[0]?.projectId!));
            }
            disable();
          }, 2000);
        })
        .catch(() => {
          setErrorMessage("something_went_wrong");
          setTimeout(() => {
            disable();
          }, 2000);
        });
      }
  };
  const loaderJSX = (state: any) => {
    return (
      <VerticalStack className="loader">
        {state.isLoading ? (
          <>
            <Stack className="loading">
              <Loader />
            </Stack>
            <Text style={{ padding: "1%", fontSize: "20px", fontFamily:"Poppins" }}>
              {t("sharing")}
            </Text>
          </>
        ) : (
          <>
            {errorMessage === "shared_success" ? (
              <FontIcon
                iconName="SkypeCircleCheck"
                style={{
                  fontSize: "40px",
                  backgroundColor: "white",
                  color: colorPallete.btGreen,
                }}
              />
            ) : (
              <Stack style={{ opacity: "0.7" }}>
                <FontIcon
                  iconName="StatusErrorFull"
                  style={{
                    fontSize: "40px",
                    backgroundColor: "white",
                    color: colorPallete.btRed,
                  }}
                />
              </Stack>
            )}
            <Text style={{ padding: "1%", fontSize: "20px", fontFamily:"Poppins" }}>
              {t(errorMessage)}
            </Text>
          </>
        )}
      </VerticalStack>
    );
  };

  const Title = () => {
    return (
      <HorizontalStack className="title">
        <Text className="titletext">{t("share_document_access")}</Text>
        <Stack
          onClick={() => {
            disable();
            props.hideModal();
          }}
          style={{ cursor: "pointer" }}
        >
          <CrossIcon />
        </Stack>
      </HorizontalStack>
    );
  };

  const disable = () => {
    setNotesError(false);
    // setSelected("forward");
    setError(false);
    setMessage("");
    setModal(false);
    setUserList([]);
    props.hideModal();
  };

  return (
    <Modal
      styles={modalStyles}
      isOpen={props.isModalOpen}
      onDismiss={props.hideModal}
      isBlocking={true}
    >
      {modal ? (
        <Stack className="uploadModal">
          <Title />
          {loaderJSX(shareDocumentState)}
        </Stack>
      ) : (
        <VerticalStack className="container">
          <Title />
          <ToggleButtonGroup
            selectedId={selectedId}
            setSelectedId={setSelectedId}
          />
          <Stack className="body">
            <HorizontalStack style={{ gap: "8px" }}>
              <VerticalStack className="button" onClick={share}>
                <ShareWhiteIcon />
                <Text className="sendText">{t("send")}</Text>
              </VerticalStack>
              <VerticalStack className="textContainer">
                <HorizontalStack
                  style={{
                    width: "100%",
                    justifyContent: "flex-start",
                    backgroundColor: "",
                  }}
                >
                  <HorizontalStack className="textWrapper">
                    <Text className="text">{t("to")}</Text>
                  </HorizontalStack>
                  <Stack
                    style={{
                      backgroundColor: "",
                      marginLeft: "2.3%",
                      width: "100%",
                    }}
                  >
                     <EmailTextBox
                      emails={toEmails}
                      setEmails={setToEmails}
                      error={error}
                      setError={setError}
                      message={message}
                      setMessage={setMessage}
                    />
                  </Stack>
                </HorizontalStack>
                <HorizontalStack
                  style={{
                    width: "100%",
                    justifyContent: "flex-start",
                    backgroundColor: "",
                  }}
                >
                  <HorizontalStack className="textWrapper">
                    <Text className="text">{t("cc")}</Text>
                  </HorizontalStack>
                  <Stack
                    style={{
                      backgroundColor: "",
                      marginLeft: "12px",
                      width: "100%",
                    }}
                  >
                    <TextField placeholder={""} disabled />
                  </Stack>
                </HorizontalStack>
                <HorizontalStack
                  style={{
                    width: "100%",
                    justifyContent: "flex-start",
                    backgroundColor: "",
                  }}
                >
                  <HorizontalStack className="textWrapper">
                    <Text className="text">{t("bcc")}</Text>
                  </HorizontalStack>
                  <Stack
                    style={{
                      backgroundColor: "",
                      marginLeft: "12px",
                      width: "100%",
                    }}
                  >
                    <TextField placeholder={""} disabled />
                  </Stack>
                </HorizontalStack>
                <HorizontalStack
                  style={{
                    width: "100%",
                    justifyContent: "flex-start",
                    backgroundColor: "",
                  }}
                >
                  <HorizontalStack className="textWrapper1">
                    <Text className="text">{t("Subject")}</Text>
                  </HorizontalStack>
                  <Stack
                    style={{
                      backgroundColor: "",
                      marginLeft: "2.3%",
                      width: "100%",
                    }}
                  >
                    <TextField
                      placeholder={String(t("add_subject"))}
                      style={{ border: "none" ,fontFamily:"Poppins"}}
                      underlined
                      onChange={onChangeSubjectTextField}
                      value={subject}
                      borderless={true}
                      styles={{
                        root: {
                          width: "100%",
                          borderBottom: "1px solid var(--LIGHT-BLUE, #E8ECF4)",
                        },
                        fieldGroup: {
                          // backgroundColor:"yellow"
                          fontFamily:"Poppins",
                          fontWeight:"500",
                          color:"#323232"
                        },
                        wrapper: {
                          border: "none",
                        },
                      }}
                      disabled={props.fileType === "sent"}
                    />
                    {subjectError && (
                      <Text
                        style={{
                          color: colorPallete.btRed,
                          marginLeft: "1%",
                        }}
                      >
                        {t("subject_required")}
                      </Text>
                    )}
                  </Stack>
                </HorizontalStack>
              </VerticalStack>
            </HorizontalStack>
            <div
              style={{
                borderTop: "1px solid #E8ECF4",
                borderBottom: "1px solid #E8ECF4",
                paddingTop: "8px",
                paddingBottom: "8px",
              }}
            >
              <SelectedDocuments
                documents={props?.selectedDocuments}
                setSelectedDocuments={props?.setSelectedDocuments}
              />
            </div>

            <Stack className="bodyTextWrapper">
              {notesError && (
                <Text
                  style={{
                    color: colorPallete.btRed,
                    marginLeft: "1%",
                  }}
                >
                  {t("notes_required")}
                </Text>
              )}
              <TextField
                resizable={false}
                onChange={onChangeMultiLine}
                borderless
                multiline={true}
                autoAdjustHeight
                id="message"
                value={notes}
                style={{
                  minHeight: "200px",
                  fontFamily:"Poppins"
                  // backgroundColor: "",
                  // overflowY: "scroll",
                  // paddingLeft: "16px",
                  // paddingRight: "16px",
                }}
                disabled={props.fileType === "sent"}
                placeholder={String(t("add_message"))}
              />
            </Stack>
          </Stack>
        </VerticalStack>
      )}
    </Modal>
  );
};

export default NewShareModal;
