import React, { FC, useState } from "react";
import { FormModal } from "../Modal";
import { HorizontalStack, VerticalStack } from "../../Stack";
import { Stack, Text } from "@fluentui/react";
import { t } from "i18next";
import { useAppDispatch } from "../../../hooks/hooks";
import { setDeleteProject } from "../../../modules/redux/ProjectDeleteSlice";
import Loader from "../../Loader";
import { useNavigate } from "react-router-dom";
import { getPrecedentDocuments } from "../../../modules/redux/Documents/document";
import {
  deleteProject,
  getAllProjects,
} from "../../../modules/redux/Projects/project";

const DeleteProjectModal: FC<any> = (props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [modal, setModal] = useState<boolean>(false);

  const handleDeleteClick = () => {
    if (props?.projectId) {
      setModal(true);
      dispatch(deleteProject(props?.projectId)).then((res: any) => {
        if (res.meta.requestStatus === "fulfilled") {
          setTimeout(() => {
            navigate("/home")
            dispatch(setDeleteProject(props.projectName));
            dispatch(
              getPrecedentDocuments({
                limit: 21,
                offset: 0,
              })
            );
            dispatch(getAllProjects({
              limit: 12,
              offset: 0,
            }));
          }, 2000);
        }
      });
    }
  };

  function disable() {
    setModal(false);
    props.hideModal();
  }

  return (
    <FormModal
      title={t("delete_project")}
      TitleBackgroundColor="#A82C0C"
      isModalOpen={props.isModalOpen}
      showModal={props.showModal}
      hideModal={props.hideModal}
      width="480px"
      cancelButton
      customCancel={disable}
    >
      <VerticalStack
        className="deleteProjectContainer"
        style={{ minHeight: !modal ? "205px" : "150px" }}
      >
        {modal ? (
          <VerticalStack className="deleteProjectLoader">
            <Loader />
            <Text className="deleteContentText" style={{ fontSize: "18px" }}>
              {t("deleting_proj")}
            </Text>
          </VerticalStack>
        ) : (
          <>
            <VerticalStack style={{ padding: "20px 25px", gap: "10px" }}>
              <Text className="deleteContentText">
               {t("delete_conf")}&nbsp;{props?.projectName}?
              </Text>
              <Text className="deleteContentText1">
              {t("delete_proj_warning")}
              </Text>
            </VerticalStack>
            <Stack className="horizontalLine"></Stack>
            <HorizontalStack
              className="deleteBottom"
              style={{ padding: "20px 25px" }}
            >
              <Text className="deleteCancelButton" onClick={props.hideModal}>
              {t("cancel")}
              </Text>
              <Text className="deleteButton" onClick={handleDeleteClick}>
              {t("delete")}
              </Text>
            </HorizontalStack>
          </>
        )}
      </VerticalStack>
    </FormModal>
  );
};

export default DeleteProjectModal;
