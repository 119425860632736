import React from "react";

function HelpIcon() {
  return (
    <svg
      width="68"
      height="68"
      viewBox="0 0 68 68"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_2830_50101)">
        <circle cx="34" cy="30" r="24" fill="#18468F" />
      </g>
      <path
        d="M30.508 26.4C30.7901 25.598 31.347 24.9217 32.0799 24.491C32.8129 24.0602 33.6747 23.9027 34.5126 24.0465C35.3505 24.1902 36.1106 24.6258 36.6581 25.2762C37.2056 25.9266 37.5053 26.7498 37.504 27.6C37.504 30 33.904 31.2 33.904 31.2M34 36H34.012M46 30C46 36.6274 40.6274 42 34 42C27.3726 42 22 36.6274 22 30C22 23.3726 27.3726 18 34 18C40.6274 18 46 23.3726 46 30Z"
        stroke="white"
        stroke-width="1.6"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <defs>
        <filter
          id="filter0_d_2830_50101"
          x="0"
          y="0"
          width="68"
          height="68"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2830_50101"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2830_50101"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}
export default HelpIcon;
