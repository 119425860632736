import { FC, useState, useCallback, FormEvent, useEffect } from "react";
import { FormModal } from "../Modal";
import {
  Label,
  TextField,
  DefaultButton,
  IStackTokens,
  Stack,
  mergeStyles,
  Separator,
  Text,
  IconButton,
  IPersonaProps,
  FontIcon,
} from "@fluentui/react";
import { HorizontalStack, VerticalStack } from "../../Stack";
import { ProjectService } from "../../../services/ProjectService";
import { Project, response } from "../../../models";
import { useTranslation } from "react-i18next";
import Loader from "../../Loader";
import { useAppDispatch } from "../../../hooks/hooks";
import { getAllProjects } from "../../../modules/redux/Projects/project";
import { useNavigate } from "react-router-dom";
import { colorPallete } from "../../../assets/color";
import { MailTextBox } from "../../MailTextBox";

const formDataStyles = {
  // paddingTop: "0.5%",
};
const buttonStackStyles = {
  root: {
    padding: "1%",
  },
};
const horizontalStackTokens: IStackTokens = {
  childrenGap: "2%",
};

const uploadModal = mergeStyles({
  display: "flex",
  width: "100%",
  height: "120px",
  backgroundColor: "white",
  padding: "20px 25px",
});

export const CreateProjectModal: FC<any> = (props) => {
  const [folderName, setFolderName] = useState("");
  const [projectOwner, setProjectOwner] = useState("");
  const [projectOwnerError, setProjectOwnerError] = useState(false);
  const [counterParty, setCounterParty] = useState("");
  const [description, setDescription] = useState("");
  const [completionDate, setCompletionDate] = useState("");
  const [errorMesssage, setErrorMesssage] = useState("");
  const [folderNameError, setFolderNameError] = useState(false);
  const [counterPartyError, setCounterPartyError] = useState(false);
  const [projectExist, setProjectExist] = useState(false);
  const [modal, setModal] = useState<boolean>(false);
  const [upload, setUpload] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const dispatch = useAppDispatch();
  const [currentSelectedItems, setCurrentSelectedItems] = useState<
    IPersonaProps[]
  >([]);

  const [textFields, setTextFields] = useState<string[]>([""]);

  const addCounterPartyField = () => {
    setTextFields([...textFields, ""]);
  };
  const updatedTextFields = [...textFields];
  const removeTextField = (index: number) => {
    updatedTextFields.splice(index, 1);
    setTextFields(updatedTextFields);
  };

  useEffect(() => {}, [textFields]);

  const handleTextFieldChange = (index: number, value: string) => {
    updatedTextFields[index] = value;
    if (value.length === 0) {
      setCounterPartyError(true);
    } else {
      setCounterPartyError(false);
    }

    setTextFields(updatedTextFields);
  };

  const onChangeFolderNameTextField = useCallback(
    (
      event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      newValue?: string
    ) => {
      if (newValue?.length === 0) {
        setFolderNameError(true);
      } else {
        setProjectExist(false);
        setFolderNameError(false);
      }
      setFolderName(newValue || "");
    },
    []
  );

  const onChangeDateField = useCallback(
    (
      event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      newValue?: string
    ) => {
      setCompletionDate(newValue || "");
    },
    []
  );

  function onChangeMultiLine(
    event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) {
    setDescription(newValue || "");
  }

  const hideModal = props.hideModal;
  const navigate = useNavigate();

  const redirectFolder = (projectId: number | undefined) => {
    navigate(`/home/${projectId}`, {
      state: {
        created: true,
      },
    });
  };

  const displayDocUpload = props.displayDoc;

  const clearedTextFields = updatedTextFields.map(() => "");
  const counterPartiesArray = textFields.filter((text) => text.trim() !== "");
  const counterParties = counterPartiesArray.join(";");

  async function createProject() {
    if (folderName.length === 0) {
      setFolderNameError(true);
    }
    if (updatedTextFields[0].length === 0) {
      setCounterPartyError(true);
      return
    }
    if (currentSelectedItems.length === 0 && projectOwner.length === 0) {
      setErrorMesssage("project_owner_required");
      setProjectOwnerError(true);
    } else if (projectOwner.length > 0 && currentSelectedItems.length === 0) {
      setProjectOwnerError(true);
      setErrorMesssage("press_enter_to_save");
    }
    if (
      folderName.length !== 0 &&
      updatedTextFields[0].length !== 0 &&
      currentSelectedItems.length !== 0
    ) {
      let ownerList = currentSelectedItems
        .map((a: any) => {
          return a.primaryText;
        })
        .toString();
      const project: any = {
        projectName: folderName.trim(),
        counterParties: counterParties,
        description: description ? description : " ",
        estimatedCompletionDate: completionDate,
        projectOwner:
          projectOwner.length > 0 ? ownerList + "," + projectOwner : ownerList,
      };
      setModal(true);
      await ProjectService.create(project).then((res: response) => {
        setUpload(true);
        if (res.message === "Name already exists") {
          setMessage("project_already_exist");
        } else if (res.code === 200) {
          setMessage("create_project_success");
        } else {
          if (res.data?.message) {
            setMessage(res.data.message);
          } else {
            setMessage(res.message);
          }
          setTimeout(() => {
            setModal(false);
            setUpload(false);
          }, 2000);
          return;
        }
        dispatch(
          getAllProjects({
            limit: 12,
            offset: 0,
          })
        );
        setTimeout(() => {
          setModal(false);
          setUpload(false);
          if (!displayDocUpload && res.message !== "Name already exists") {
            redirectFolder(res.data.projectId);
          } else if (displayDocUpload) {
            hideModal();
            displayDocUpload();
          }
        }, 2000);
      });
      setFolderName("");
      setCounterParty("");
      setCompletionDate("");
      setDescription("");
      setTextFields(clearedTextFields);
    }
  }
  const closeProject = () => {
    hideModal();
    setFolderName("");
    setCounterParty("");
    setErrorMesssage("");
    setCompletionDate("");
    setDescription("");
    setCounterPartyError(false);
    setFolderNameError(false);
    setProjectOwner("");
    setProjectOwnerError(false);
    setTextFields(clearedTextFields);
    displayDocUpload && displayDocUpload();
  };

  const { t } = useTranslation();

  return (
    <FormModal
      title={
        upload
          ? t(message) === t("create_project_success")
            ? t("success")
            : t("error")
          : t("new_project")
      }
      TitleBackgroundColor={
        upload
          ? t(message) === t("create_project_success")
            ? "#13540C"
            : "#A82C0C"
          : "#18468F"
      }
      isModalOpen={props.isModalOpen}
      showModal={props.showModal}
      hideModal={props.hideModal}
      width="595px"
      height={modal ? "149px" : "620px"}
      cancelButton={true}
      customCancel={closeProject}
    >
      {modal ? (
        <Stack
          className={uploadModal}
          style={{ flexDirection: upload ? "row" : "column",
        alignItems: upload ? "flex-start":"center" }}
        >
          {upload && t(message) === t("create_project_success") ? (
            <FontIcon
              iconName="SkypeCircleCheck"
              style={{
                fontSize: "25px",
                backgroundColor: "white",
                color: colorPallete.btGreen,
              }}
            />
          ) : (
            upload && (
              <FontIcon
                iconName="StatusErrorFull"
                style={{
                  fontSize: "25px",
                  backgroundColor: "white",
                  color: colorPallete.btRed,
                }}
              />
            )
          )}
          {!upload && <Loader />}
          <Text
            style={{
              fontSize: "16px",
              fontFamily: "Poppins",
              paddingLeft: "15px",
              paddingTop: upload ? "":"10px"
            }}
          >
            {upload ? t(message) : t("creating_project")}
          </Text>
        </Stack>
      ) : (
        <VerticalStack
          style={{
            backgroundColor: "white",
            height: "558px",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <VerticalStack
            style={{
              width: "100%",
              overflowY: "scroll",
              gap: "8px",
              backgroundColor: "",
              padding: "20px 25px",
            }}
          >
            <Stack>
              <Label required htmlFor="folderName" style={styles.label}>
                {t("project_name")}
              </Label>
              <TextField
                styles={{
                  root: {
                    height: "44px",
                    backgroundColor: "#F8F8F8",
                    borderRadius: "8px",
                    width: "100%",
                    padding: "5px",
                    border: "1px solid #E0E0E0",
                    fontSize: "16px",
                    fontWeight: 400,
                    lineHeight: "24px",
                    fontFamily: "Poppins",
                  },
                  field: {
                    backgroundColor: "#F8F8F8",
                    fontSize: "16px",
                    fontWeight: 400,
                    lineHeight: "24px",
                    fontFamily: "Poppins",

                    selectors: {
                      "::placeholder": {
                        color: "#717070",
                        fontSize: "16px",
                        fontWeight: 400,
                        lineHeight: "24px",
                        fontFamily: "Poppins",
                      },
                    },
                  },
                }}
                borderless
                id="folderName"
                value={folderName}
                onChange={onChangeFolderNameTextField}
                placeholder={String(t("project_name"))}
              />

              {folderNameError && (
                <Text style={{ color: colorPallete.btRed }}>
                  {t("project_name_required")}
                </Text>
              )}
              {projectExist && (
                <Text style={{ color: colorPallete.btRed }}>
                  {t("project_already_exist")}
                </Text>
              )}
            </Stack>
            <Stack>
              <Label required htmlFor="project Owner" style={styles.label}>
                {t("project_owner")}
              </Label>
              <div
                style={{
                  backgroundColor: "#F8F8F8",
                  width: "100%",
                  height: "44px",
                  borderRadius: "8px",
                }}
              >
                <MailTextBox
                  initial={[]}
                  placeholder={
                    currentSelectedItems.length === 0
                      ? t("project_owner_email")
                      : ""
                  }
                  styles={{
                    input: {
                      backgroundColor: "#F8F8F8",
                      // width: "445px",
                      border: "none",
                      outline: "none",
                      outlineColor: "inherit",

                      selectors: {
                        "::placeholder": {
                          color: "#717070",
                          fontSize: "16px",
                          fontWeight: 400,
                          lineHeight: "24px",
                          fontFamily: "Poppins",
                        },
                      },
                    },
                    root: {
                      border: "1px solid #E0E0E0",
                      backgroundColor: "#F8F8F8",
                      padding: "1%",
                      height: "maxContent",
                      borderRadius: "8px",
                      color: "#323232",
                      fontSize: "16px",
                      fontWeight: 400,
                      lineHeight: "24px",
                      fontFamily: "Poppins",
                      selectors: {
                        "div.ms-BasePicker-text::after": {
                          border: "none",
                          outline: "none",
                        },
                      },
                    },
                    itemsWrapper: {
                      border: "none",
                    },
                    text: {
                      border: "none",
                      outline: "none",
                      selectors: {
                        ".ms-BasePicker-text::after": {
                          border: "none",
                          outline: "none",
                        },
                      },
                    },
                    screenReaderText: {},
                  }}
                  onPersonaData={setCurrentSelectedItems}
                  error={setProjectOwnerError}
                  item={setProjectOwner}
                  message={setErrorMesssage}
                  project={true}
                />
              </div>
              {projectOwnerError === true && (
                <Text style={{ color: colorPallete.btRed }}>
                  {t(errorMesssage)}
                </Text>
              )}
            </Stack>
            <Stack>
              <HorizontalStack
                horizontalAlign="space-between"
                style={{
                  display: "flex",
                  alignItems: "center",
                  paddingRight: "8px",
                }}
              >
                <Label required htmlFor="counterParty" style={styles.label}>
                  {t("counter_party")}
                </Label>
                <IconButton
                  iconProps={{ iconName: "CircleAdditionSolid" }}
                  onClick={addCounterPartyField}
                  style={{
                    width: "16px",
                    height: "16px",
                    backgroundColor: "",
                    marginRight: "1%",
                    color: "#2B579A",
                  }}
                />
              </HorizontalStack>
              {textFields.map((value, index) => (
                <>
                {index !== 0 ? <>
                  <HorizontalStack
                horizontalAlign="space-between"
                style={{
                  display: "flex",
                  alignItems: "center",
                  paddingRight: "8px",
                }}
              >
                <Label htmlFor="counterParty" style={styles.label}>
                  {t("counter_party")}
                </Label>
              </HorizontalStack>
                </>:null}
                <div
                  key={index}
                  style={{
                    display: "flex",
                    width: "100%",
                    backgroundColor: "#F8F8F8",
                    height: "47px",
                    borderRadius: "8px",
                    alignItems: "center",
                    border: "1px solid #E0E0E0",
                  }}
                >
                  <TextField
                    styles={{
                      root: {
                        height: "44px",
                        backgroundColor: "#F8F8F8",
                        borderRadius: "8px",
                        width: "100%",
                        padding: "5px",
                        fontSize: "16px",
                        fontWeight: 400,
                        lineHeight: "24px",
                        fontFamily: "Poppins",
                      },
                      field: {
                        backgroundColor: "#F8F8F8",
                        fontSize: "16px",
                        fontWeight: 400,
                        lineHeight: "24px",
                        fontFamily: "Poppins",
                        selectors: {
                          "::placeholder": {
                            color: "#717070",
                            fontSize: "16px",
                            fontWeight: 400,
                            lineHeight: "24px",
                            fontFamily: "Poppins",
                          },
                        },
                      },
                    }}
                    borderless
                    id="counterParty"
                    value={value}
                    onChange={(e) =>
                      handleTextFieldChange(index, e.currentTarget.value)
                    }
                    placeholder={String(t("counter_party"))}
                  />
                  {index !== 0 && (
                    <>
                      <svg
                        width="2"
                        height="24"
                        viewBox="0 0 2 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M1 0.5V23.5" stroke="#E0E0E0" />
                      </svg>
                      <Stack
                        onClick={() => removeTextField(index)}
                        style={{ padding: "15px", cursor: "pointer" }}
                      >
                        <svg
                          width="9"
                          height="3"
                          viewBox="0 0 9 3"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8.09091 0.579545V2.45455H0.454545V0.579545H8.09091Z"
                            fill="#717070"
                          />
                        </svg>
                      </Stack>
                    </>
                  )}
                </div>
                </>
              ))}

              {counterPartyError && (
                <Text style={{ color: colorPallete.btRed }}>
                  {t("counter_party_required")}
                </Text>
              )}
            </Stack>
            <Stack style={formDataStyles}>
              <Label htmlFor="completionDate" style={styles.label}>
                {t("completion_date")}
              </Label>
              <TextField
                styles={{
                  root: {
                    height: "44px",
                    backgroundColor: "#F8F8F8",
                    borderRadius: "8px",
                    width: "100%",
                    padding: "5px 3px",
                    border: "1px solid #E0E0E0",
                    fontSize: "16px",
                    fontWeight: 400,
                    lineHeight: "24px",
                    fontFamily: "Poppins",
                  },
                  field: {
                    backgroundColor: "#F8F8F8",
                    fontSize: "16px",
                    fontWeight: 400,
                    lineHeight: "24px",
                    fontFamily: "Poppins",
                    textTransform: "uppercase",
                    selectors: {
                      "::placeholder": {
                        color: "#717070",
                        fontSize: "16px",
                        fontWeight: 400,
                        lineHeight: "24px",
                        fontFamily: "Poppins",
                        textTransform: "uppercase",
                      },
                    },
                  },
                }}
                borderless
                id="completionDate"
                value={completionDate}
                onChange={onChangeDateField}
                placeholder={"MM/DD/YYYY"}
                type="date"
              />
            </Stack>
            <Stack style={formDataStyles}>
              <Label htmlFor="description" style={styles.descriptionLabel}>
                {t("description")}
              </Label>
              <TextField
                styles={{
                  root: {
                    height: "90px",
                    backgroundColor: "#F8F8F8",
                    borderRadius: "8px",
                    width: "100%",
                    padding: "5px",
                    border: "1px solid #E0E0E0",
                    fontSize: "16px",
                    fontWeight: 400,
                    lineHeight: "24px",
                    fontFamily: "Poppins",
                  },
                  field: {
                    backgroundColor: "#F8F8F8",
                    fontSize: "16px",
                    fontWeight: 400,
                    lineHeight: "24px",
                    fontFamily: "Poppins",
                    height: "80px",
                    selectors: {
                      "::placeholder": {
                        color: "#717070",
                        fontSize: "16px",
                        fontWeight: 400,
                        lineHeight: "24px",
                        fontFamily: "Poppins",
                      },
                    },
                  },
                }}
                borderless
                multiline={true}
                onChange={onChangeMultiLine}
                id="description"
                value={description}
                placeholder={String(t("project_description"))}
                resizable={false}
              />
            </Stack>
          </VerticalStack>
          <VerticalStack>
            <Stack className="horizontalLine"></Stack>
            <HorizontalStack
              style={{
                justifyContent: "space-between",
                alignSelf: "end",
                padding: "20px 25px",
                gap: "5px",
                backgroundColor: "",
              }}
              horizontalAlign="end"
              styles={buttonStackStyles}
              tokens={horizontalStackTokens}
            >
              <Text
                style={{
                  ...styles.createButton,
                  backgroundColor:
                    folderName.length !== 0 &&
                    currentSelectedItems.length !== 0 &&
                    updatedTextFields[0].length !== 0
                      ? "#18468F"
                      : "#EAEAEA",

                  color:
                    folderName.length !== 0 &&
                    currentSelectedItems.length !== 0 &&
                    updatedTextFields[0].length !== 0
                      ? "#F4F4F4"
                      : "#717070",
                  textAlign: "center",
                }}
                onClick={() => {
                  createProject();
                }}
              >
                {t("create_project")}
              </Text>
              <Text
                style={{ ...styles.cancelButton, textAlign: "center" }}
                onClick={() => {
                  closeProject();
                }}
                onMouseEnter={() => {}}
              >
                {t("cancel")}
              </Text>
            </HorizontalStack>
          </VerticalStack>
        </VerticalStack>
      )}
    </FormModal>
  );
};

const styles = {
  label: {
    color: colorPallete.black1,
    fontSize: "14px",
    fontWeight: "700",
    fontFamily: "Poppins",

    lineHeight: "20px",
  },
  descriptionLabel: {
    color: colorPallete.black1,
    fontSize: "14px",
    fontWeight: "700",
    fontFamily: "Poppins",
    lineHeight: "20px",
  },
  createButton: {
    borderRadius: "4px",
    padding: "12px 24px",
    fontSize: "14px",
    lineHeight: "16px",
    fontFamily: "Poppins",
    fontWeight: "500",
    alignItems: "center",
    minWidth: "fit-content",
    height: "40px",
    gap: "10px",
    cursor: "pointer",
  },
  cancelButton: {
    backgroundColor: "#E4E8EC",
    color: colorPallete.black1,
    borderRadius: "4px",
    minWidth: "120px",
    height: "40px",
    padding: "12px 24px",
    fontSize: "14px",
    lineHeight: "16px",
    fontWeight: 500,
    fontFamily: "Poppins",
    cursor: "pointer",
  },
};
