import React, { FC, useState } from "react";
import { FormModal } from "../Modal";
import { HorizontalStack, VerticalStack } from "../../Stack";
import { Stack, Text } from "@fluentui/react";
import { DocumentVersionInCard } from "../../Cards/DocumentVersionInCard";
import { useAppDispatch } from "../../../hooks/hooks";
import Loader from "../../Loader";
import { deleteVersion } from "../../../modules/redux/Documents/document";
import { t } from "i18next";

const DeleteVersionModal: FC<any> = (props) => {
  const dispatch = useAppDispatch();
  const [modal, setModal] = useState<boolean>(false);
  const [upload, setUpload] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");

  const handleDeleteClick = () => {
    if (props.version) {
      setModal(true);
      //   setTimeout(()=>{
      //     setUpload(true);
      //     setMessage("Success");
      //   },2000)
      dispatch(deleteVersion(props.version.documentId)).then((res: any) => {
        setUpload(true);
        if (res.meta.requestStatus === "fulfilled") {
          setMessage("Success");
          props.refresh();
        } else {
          setMessage("Error");
        }
        setTimeout(()=>{
          disable()
        },3000)
      });
    }
  };

  function disable() {
    setUpload(false);
    setMessage("");
    setModal(false);
    props.hideModal();
  }
  return (
    <FormModal
      title={
        upload
          ? message === "Success"
            ? t("success")
            : t("error")
          : t("delete_version")
      }
      TitleBackgroundColor={
        upload ? (message == "Success" ? "#13540C" : "#A82C0C") : "#A82C0C"
      }
      isModalOpen={props.isModalOpen}
      showModal={props.showModal}
      hideModal={props.hideModal}
      width="480px"
      cancelButton
      customCancel={disable}
    >
      <VerticalStack
        className="deleteVersionContainer"
        style={{ minHeight: !modal ? "490px" : "100px" }}
      >
        {modal ? (
          <>
            <VerticalStack
              style={{
                alignItems: upload ? "" : "center",
                justifyContent: upload ? "" : "space-between",
                backgroundColor: "",
                gap: "20px",
                padding: "20px 25px",
                width:'100%'
              }}
            >
              {!upload ? <Loader /> : null}
              <Text
                style={{
                  fontSize: "17px",
                }}
                className="deleteContentText"
              >
                {upload
                  ? 
                  (<Text className="deleteContentText">
                   {`${props.version?.documentName.split(".docx")[0]} V${
                      props.version.documentVersion
                    }`  } {t("deleted")}
                  </Text>
                  )
                  : (<Text className="deleteContentText">
                  {t("deleting")}  {`${props.version?.documentName.split(".docx")[0]} V${
                      props.version.documentVersion
                    }`  } 
                  </Text>
                  )
                  }
              </Text>
            </VerticalStack>
          
          </>
        ) : (
          <>
            <VerticalStack
              style={{
                padding: "20px 25px 10px 20px",
              }}
            >
              <Text className="deleteContentText">
               {t("delete_v_conf")}&nbsp;
                {props.version?.documentName.split(".docx")[0] ?? ""}&nbsp;V
                {props.version?.documentVersion}?
              </Text>
            </VerticalStack>
            <Stack onClick={() => {}} style={{ paddingBottom: "20px" }}>
              <DocumentVersionInCard version={props.version} delete={true} />
            </Stack>
            <Stack className="horizontalLine"></Stack>
            <HorizontalStack
              className="deleteBottom"
              style={{ padding: "20px 25px" }}
            >
              <Text className="deleteCancelButton" onClick={props.hideModal}>
                {t("cancel")}
              </Text>
              <Text className="deleteButton" onClick={handleDeleteClick}>
                {t("delete")}
              </Text>
            </HorizontalStack>
          </>
        )}
      </VerticalStack>
    </FormModal>
  );
};

export default DeleteVersionModal;
