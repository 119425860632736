import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ApiEndpoints } from "../../../network/ApiEndpoints";
import { ApiClient } from "../../../network/ApiClient";
import { Document, response } from "../../../models";

type InitialState = {
  isLoading: boolean;
  data: Document[];
  error: string;
  metadata?: {
    count: number;
    offset: number;
    limit: number;
  };
};

type TemplateInitialState = {
  isLoading: boolean;
  data: response;
  error: string;
  metadata?: {
    count: number;
    offset: number;
    limit: number;
  };
};

const templateInitialState: TemplateInitialState = {
  isLoading: false,
  data: {
    code: 0,
    message: "",
    data: "",
  },
  error: "",
  metadata: {
    count: 0,
    offset: 0,
    limit: 0,
  },
};
const initialState: InitialState = {
  isLoading: false,
  data: [],
  error: "",
  metadata: {
    count: 0,
    offset: 0,
    limit: 0,
  },
};

type PDFInitialState = {
  isLoading: boolean;
  data: Document[];
  error: string;
};

const pdfInitialState: PDFInitialState = {
  isLoading: false,
  data: [],
  error: "",
};

type DefaultInitialState = {
  isLoading: boolean;
  data: string;
  error: string;
};

const defaultInitialState: DefaultInitialState = {
  isLoading: false,
  data: "",
  error: "",
};

export const getDocusignCode = createAsyncThunk(
  "getDocusignCode",
  async (code: string) => {
    const response = await ApiClient.get<any>(ApiEndpoints.docusign(), {
      code: code,
    });
    return response;
  }
);
const getDocusinCodeSlice = createSlice({
  name: "getDocusignCode",
  initialState: defaultInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getDocusignCode.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      getDocusignCode.fulfilled,
      (state, action: PayloadAction<string>) => {
        state.isLoading = false;
        state.data = action.payload;
        state.error = "";
      }
    );
    builder.addCase(getDocusignCode.rejected, (state, action) => {
      state.isLoading = false;
      state.data = "";
      state.error = action.error.message ?? "Something went wrong";
    });
  },
});

export const getPrecedentDocuments = createAsyncThunk(
  "getPrecedentDocuments",
  async (payload: { limit: number; offset: number }) => {
    const response = await ApiClient.get<any>(
      ApiEndpoints.getPrecedentFiles(),
      {
        Limit: payload.limit,
        Offset: payload.offset,
      },
      {}
    );
    return response;
  }
);
const documentSlice = createSlice({
  name: "document",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPrecedentDocuments.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      getPrecedentDocuments.fulfilled,
      (state, action: PayloadAction<response>) => {
        state.isLoading = false;
        const newData = action.payload.data;
        if (action.payload.metadata === null ||  action.payload.metadata?.offset === 0) {
          var docs = [...newData]
          state.data = docs; // For the first load, replace data
        } else {
          state.data = state.data.concat(newData); // For subsequent loads, concatenate data
        }
        state.error = "";
        state.metadata = action.payload.metadata;
      }
    );
    builder.addCase(getPrecedentDocuments.rejected, (state, action) => {
      state.isLoading = false;
      state.data = [];
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const getSearchedDocuments = createAsyncThunk(
  "getSearchedDocument",
  async (payload: {documentName: string, limit: number; offset: number }) => {
    const encodedSearchParam = encodeURIComponent(payload.documentName);
    let response = await ApiClient.get<any>(
      ApiEndpoints.search(),
      {
        searchParam: encodedSearchParam,
        Limit: payload.limit,
        Offset: payload.offset,
      },
      {}
    );
    return response.data;
  }
);
const documentSearchSlice = createSlice({
  name: "searchedDocuments",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSearchedDocuments.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      getSearchedDocuments.fulfilled,
      (state, action: PayloadAction<response>) => {
        state.isLoading = false;
        state.data = action.payload.data;
        state.error = "";
        state.metadata = action.payload.metadata;
      }
    );
    builder.addCase(getSearchedDocuments.rejected, (state, action) => {
      state.isLoading = false;
      state.data = [];
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const getSearchedVersions = createAsyncThunk(
  "getSearchedVersions",
  async (object: { rootDocumentId: number; searchParam: string }) => {
    const encodedSearchParam = encodeURIComponent(object.searchParam);
    let response = await ApiClient.get<any>(
      ApiEndpoints.searchVersions(),
      {
        rootDocumentId: object.rootDocumentId,
        searchParam: encodedSearchParam,
      },
      {}
    );
    return response.data.data;
  }
);
const versionSearchSlice = createSlice({
  name: "searchedVersions",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSearchedVersions.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      getSearchedVersions.fulfilled,
      (state, action: PayloadAction<Document[]>) => {
        state.isLoading = false;
        state.data = action.payload;
        state.error = "";
      }
    );
    builder.addCase(getSearchedVersions.rejected, (state, action) => {
      state.isLoading = false;
      state.data = [];
      state.error = action.error.message ?? "Something went wrong";
    });
  },
});

export const getFilesInFolders = createAsyncThunk(
  "getFilesInFolder",
  async (projectId: number) => {
    const encodedFolderName = encodeURIComponent(projectId);
    let response = await ApiClient.get<any>(
      ApiEndpoints.getDocumentsInFolder(),
      {
        projectId:encodedFolderName
      },
      {}
    );
    return response.data;
  }
);
const getFilesInFolderSlice = createSlice({
  name: "getFilesInFolderSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getFilesInFolders.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      getFilesInFolders.fulfilled,
      (state, action: PayloadAction<Document[]>) => {
        state.isLoading = false;
        state.data = action.payload;
        state.error = "";
      }
    );
    builder.addCase(getFilesInFolders.rejected, (state, action) => {
      state.isLoading = false;
      state.data = [];
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const getDocumentversions = createAsyncThunk(
  "getDocVersions",
  async (payload: {
    rootDocumentId: number;
    limit?: number;
    offset?: number;
  }) => {
    let response = await ApiClient.get<any>(
    
      ApiEndpoints.getDocumentVersions(),
      {
        Offset: payload.offset,
        rootDocumentId: payload.rootDocumentId,
        Limit: payload.limit,
      },
      {}
    );
  
    return response;
  }
);
const getDocumentVersionsSlice = createSlice({
  name: "getDocVersions",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getDocumentversions.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      getDocumentversions.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.data = action.payload.data;
        state.error = "";
        state.metadata = action.payload.metadata;
      }
    );
    builder.addCase(getDocumentversions.rejected, (state, action) => {
      state.isLoading = false;
      state.data = [];
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const createNewVersion = createAsyncThunk(
  "createNewVersion",
  async (documentId:number) => {
    let response: any = await ApiClient.post(
      ApiEndpoints.createNewVersion(),
      {
      },
      {
        documentId: documentId
      },
      {}
    );
    return response.data;
  }
);

const createNewVersionSlice = createSlice({
  name: "createNewVersion",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createNewVersion.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      createNewVersion.fulfilled,
      (state, action: PayloadAction<Document[]>) => {
        state.isLoading = false;
        state.data = action.payload;
        state.error = "";
      }
    );
    builder.addCase(createNewVersion.rejected, (state, action) => {
      state.isLoading = false;
      state.data = [];
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const getPDF = createAsyncThunk("getPDF", async (folderName: string) => {
  let response = await ApiClient.get<any>(
    ApiEndpoints.getPdfsInFolder(folderName),
    {},
    {}
  );
  return response.data;
});
const getPDFSlice = createSlice({
  name: "getPdfVersions",
  initialState: pdfInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPDF.pending, (state) => {
      state.isLoading = true;
      state.data = [];
    });
    builder.addCase(
      getPDF.fulfilled,
      (state, action: PayloadAction<Document[]>) => {
        state.isLoading = false;
        state.data = action.payload;
        state.error = "";
      }
    );
    builder.addCase(getPDF.rejected, (state, action) => {
      state.isLoading = false;
      state.data = [];
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const createTemplate = createAsyncThunk(
  "createTemplate",
  async (payload: any) => {
    let response: response = await ApiClient.post(
      ApiEndpoints.createTemplate(),
      {
        projectId:payload?.projectId,
        driveItemId:payload?.driveItemId
      },
      {},
      {}
    );
    return response;
  }
);

const createTemplateSlice = createSlice({
  name: "createTemplateSlice",
  initialState: templateInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createTemplate.pending, (state) => {
      state.isLoading = true;
      state.data = {
        code: 0,
        message: "",
        data: {},
      };
    });
    builder.addCase(
      createTemplate.fulfilled,
      (state, action: PayloadAction<response>) => {
        state.isLoading = false;
        state.data = action.payload;
      }
    );
    builder.addCase(createTemplate.rejected, (state, action) => {
      state.isLoading = false;
      state.data = {
        code: 0,
        message: "",
        data: {},
      };
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const createTemplateVersion = createAsyncThunk(
  "createTemplate",
  async (payload: any) => {
    let response: response = await ApiClient.post(
      ApiEndpoints.createTemplateVersion(),
      {
        rootDocumentId:payload?.rootDocumentId,
        driveItemId:payload?.driveItemId
      },
      {},
      {}
    );
    return response;
  }
);

export const shareDocument = createAsyncThunk(
  "shareDocument",
  async (payload: any) => {
    let response: any = await ApiClient.post(
      ApiEndpoints.shareDocument(),
      {
        projectId: payload.projectId,
        emailList: payload.emailList,
        documentIds:payload.documentIds,
        emailNotes: payload.emailNotes,
        subject: payload.subject,
      },
      {
      },
      {
        "Content-Type": "application/json",
      }
    );
    return response.webDavUrl;
  }
);

const shareDocumentSlice = createSlice({
  name: "shareDocumentSlice",
  initialState: defaultInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(shareDocument.pending, (state) => {
      state.isLoading = true;
      state.data = "";
    });
    builder.addCase(shareDocument.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(shareDocument.rejected, (state, action) => {
      state.isLoading = false;
      state.data = "";
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const shareSentDocument = createAsyncThunk(
  "shareSentDocument",
  async (payload: any) => {
    let response: any = await ApiClient.post(
      ApiEndpoints.shareSentDocument(),
      {
        emailList: payload.emailList,
        fileName: payload.fileName,
        folderName: payload.folderName,
        type: payload.type,
        scope: payload.scope,
        notess: payload.notes,
        subject: payload.subject,
      },
      {},
      {
        "Content-Type": "application/json",
      }
    );
    return response.webDavUrl;
  }
);
const shareSentDocumentSlice = createSlice({
  name: "shareSentDocumentSlice",
  initialState: defaultInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(shareSentDocument.pending, (state) => {
      state.isLoading = true;
      state.data = "";
    });
    builder.addCase(shareSentDocument.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(shareSentDocument.rejected, (state, action) => {
      state.isLoading = false;
      state.data = "";
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const getSentVersionAccess = createAsyncThunk(
  "getSentVersionAccess",
  async (payload: any) => {
    let response: any = await ApiClient.get<any>(
      ApiEndpoints.getVersionSharedAccess(),
      {
        fileName: payload.fileName,
        folderName: payload.folderName,
      },
      {}
    );
    return response;
  }
);
const getSentVersionAccessSlice = createSlice({
  name: "getSentVersionAccess",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSentVersionAccess.pending, (state) => {
      state.isLoading = true;
      state.data = [];
    });
    builder.addCase(getSentVersionAccess.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(getSentVersionAccess.rejected, (state, action) => {
      state.isLoading = false;
      state.data = [];
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const deleteDocument = createAsyncThunk(
  "deleteDocument",
  async (rootDocumentId: number)=>{
    let response: any = await ApiClient.delete<any>(
      ApiEndpoints.deleteDocument(rootDocumentId),
      {},
      {}
    );
    return response;
  }
);

export const deleteVersion = createAsyncThunk(
  "deleteDocument",
  async (documentId: number)=>{
    let response: any = await ApiClient.delete<any>(
      ApiEndpoints.deleteVersion(documentId),
      {},
      {}
    );
    return response;
  }
);

export const grantPermissionFolder = createAsyncThunk(
  "grantPermissionFolder",
  async (projectId: number)=>{
    let response: any= await ApiClient.post(
      ApiEndpoints.grantPermissionFolder(),
      {},
      {
        projectId:projectId
      },
      {},
      )
      return response;
  }
)


export const getSearchedDocumentReducer = documentSearchSlice.reducer;
export const getPrecedentDocumentReducer = documentSlice.reducer;
export const getFilesInFolderReducer = getFilesInFolderSlice.reducer;
export const getDocumentVersionsReducer = getDocumentVersionsSlice.reducer;
export const getAllPDF = getPDFSlice.reducer;
export const getDocuSignCodeReducer = getDocusinCodeSlice.reducer;
export const shareDocumentReducer = shareDocumentSlice.reducer;
export const shareSentDocumentReducer = shareSentDocumentSlice.reducer;
export const createNewVersionReducer = createNewVersionSlice.reducer;
export const getSentDocumentAccessReducer = getSentVersionAccessSlice.reducer;
export const getSearchedVersionsReducer = versionSearchSlice.reducer;
export const createTemplateReducer = createTemplateSlice.reducer;
