import React from "react";

export default function Received() {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.01953 7.67158C2.01953 7.52288 2.14007 7.40234 2.28876 7.40234H7.00035C7.14904 7.40234 7.26958 7.52288 7.26958 7.67158C7.26958 7.82027 7.14904 7.94081 7.00035 7.94081H2.28876C2.14007 7.94081 2.01953 7.82027 2.01953 7.67158Z"
          fill="#1F2430"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.01953 9.69111C2.01953 9.54241 2.14007 9.42188 2.28876 9.42188H7.00035C7.14904 9.42188 7.26958 9.54241 7.26958 9.69111C7.26958 9.8398 7.14904 9.96034 7.00035 9.96034H2.28876C2.14007 9.96034 2.01953 9.8398 2.01953 9.69111Z"
          fill="#1F2430"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.01953 5.65205C2.01953 5.50335 2.14007 5.38281 2.28876 5.38281H3.63493C3.78362 5.38281 3.90416 5.50335 3.90416 5.65205C3.90416 5.80074 3.78362 5.92128 3.63493 5.92128H2.28876C2.14007 5.92128 2.01953 5.80074 2.01953 5.65205Z"
          fill="#1F2430"
        />
        <path
          d="M13.0581 6.90731V12.4905C13.0581 13.1729 12.4826 13.7312 11.7793 13.7312H1.54839C0.845018 13.7312 0.269531 13.1729 0.269531 12.4905V5.6666L2.3157 4.4259V1.32413C2.3157 0.703779 2.82725 0.269531 3.40273 0.269531H10.1807C10.8201 0.269531 11.2677 0.765814 11.2677 1.32413V4.67404H11.3317L13.0581 5.6666C13.0581 5.6666 13.0581 6.22492 13.0581 6.90731Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.53434 4.16694C2.61243 4.29348 2.57315 4.45936 2.44662 4.53745L0.538467 5.71498V12.4751C0.538467 13.0142 0.987313 13.4623 1.54173 13.4623H11.7217C11.9875 13.4623 12.2554 13.3367 12.4605 13.1286C12.6657 12.9203 12.7886 12.649 12.7886 12.3853V5.65634L11.204 4.72918C11.0756 4.65408 11.0325 4.48917 11.1076 4.36083C11.1827 4.23249 11.3476 4.18933 11.4759 4.26442L13.1938 5.26956C13.2763 5.31785 13.3271 5.4063 13.3271 5.50193V12.3853C13.3271 12.8127 13.1318 13.2145 12.844 13.5065C12.556 13.7988 12.1558 14.0007 11.7217 14.0007H1.54173C0.6964 14.0007 0 13.318 0 12.4751V5.56476C0 5.47138 0.0483808 5.38467 0.127843 5.33564L2.16383 4.07921C2.29037 4.00113 2.45626 4.0404 2.53434 4.16694Z"
          fill="#A82C0C"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.41264 0.395763C2.64631 0.160862 2.96481 0 3.35892 0H9.77984C10.6223 0 11.3081 0.691405 11.3081 1.53486V7.42002C11.3081 7.56872 11.1875 7.68926 11.0388 7.68926C10.8902 7.68926 10.7696 7.56872 10.7696 7.42002V1.53486C10.7696 0.986125 10.3223 0.538467 9.77984 0.538467H3.35892C3.12353 0.538467 2.93842 0.63073 2.79439 0.775517C2.65644 0.914191 2.558 1.16203 2.558 1.34502V7.42002C2.558 7.56872 2.43746 7.68926 2.28876 7.68926C2.14007 7.68926 2.01953 7.56872 2.01953 7.42002V1.34502C2.01953 1.02175 2.17289 0.636777 2.41264 0.395763Z"
          fill="#A82C0C"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.040133 6.18651C0.118203 6.05996 0.284081 6.02066 0.41063 6.09873L5.11773 9.00262C6.04966 9.56453 7.27746 9.56453 8.2094 9.00262C8.20976 9.0024 8.21013 9.00218 8.2105 9.00196L12.9165 6.09873C13.043 6.02066 13.2089 6.05996 13.287 6.18651C13.3651 6.31305 13.3258 6.47893 13.1992 6.557L8.48875 9.46297C7.38519 10.129 5.94194 10.129 4.83838 9.46296L4.83614 9.46161L0.127912 6.557C0.00136234 6.47893 -0.0379376 6.31305 0.040133 6.18651Z"
          fill="#A82C0C"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.03906 6.32783C4.03906 6.17913 4.1596 6.05859 4.3083 6.05859H9.01988C9.16857 6.05859 9.28911 6.17913 9.28911 6.32783C9.28911 6.47652 9.16857 6.59706 9.01988 6.59706H4.3083C4.1596 6.59706 4.03906 6.47652 4.03906 6.32783Z"
          fill="#A82C0C"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.03906 4.3083C4.03906 4.1596 4.1596 4.03906 4.3083 4.03906H9.01988C9.16857 4.03906 9.28911 4.1596 9.28911 4.3083C9.28911 4.45699 9.16857 4.57753 9.01988 4.57753H4.3083C4.1596 4.57753 4.03906 4.45699 4.03906 4.3083Z"
          fill="#A82C0C"
        />
      </svg>
    </div>
  );
}
