import  { FC, useState, useEffect, createRef } from "react";
import { IPersonaProps } from "@fluentui/react/lib/Persona";
import {
  CompactPeoplePicker,
  IBasePickerStyles,
  IBasePickerSuggestionsProps,
  IPickerItemProps,
  ValidationState,
} from "@fluentui/react/lib/Pickers";
import { UserModel } from "../../models";
import { Stack, Text } from "@fluentui/react";
import { HorizontalStack } from "../Stack";
import EmailCircleIcon from "../../assets/Icons/svg/emailCircleIcon";

interface MailTextBoxProps {
  initial: UserModel[];
  placeholder: string;
  user?: string[];
  setUser?: (value: string[]) => void;
  styles?: IBasePickerStyles;
  onPersonaData?: (personas: IPersonaProps[]) => void;
  error?: any;
  item?: any;
  message?: any;
  potentialRecipients?: any;
  selected?: string;
  project?:boolean
}

const ConvertUserToIpersona = (userList: UserModel[]) => {
  let personaList: IPersonaProps[] = [];
  userList.forEach((user) => {
    let persona: IPersonaProps = {};
    persona.text = user.name;
    persona.id = String(user.id);
    persona.key = user.name;
    persona.imageUrl = user.userImage;
    personaList.push(persona);
  });
  return personaList;
};

export const MailTextBox: FC<MailTextBoxProps> = ({
  initial,
  placeholder,
  styles,
  onPersonaData,
  error,
  user,
  setUser,
  item,
  message,
  potentialRecipients,
  selected,
  project
}: MailTextBoxProps) => {
  const [peopleList, setPeopleList] = useState<IPersonaProps[]>();
  const [currentSelectedItems, setCurrentSelectedItems] = useState<
    IPersonaProps[]
  >([]);

  useEffect(() => {
    setPeopleList(ConvertUserToIpersona(initial));
  }, []);

  useEffect(() => {
    if (potentialRecipients) {
      if (selected === "reply") {
        let a: IPersonaProps = {
          text: potentialRecipients,
          key: potentialRecipients,
        };
        setCurrentSelectedItems([a]);
      } else if (selected === "reply_all") {
        let emails: IPersonaProps[] = potentialRecipients.map(
          (item: string) => {
            let a: IPersonaProps = {
              text: item,
              key: item,
            };
            return a;
          }
        );
        setCurrentSelectedItems(emails);
      }
    } else if (!potentialRecipients) {
      let emails: IPersonaProps[] = [];
      setCurrentSelectedItems(emails);
    }
  }, [potentialRecipients]);

  useEffect(() => {
    if (onPersonaData) {
      onPersonaData(currentSelectedItems);
    }
  }, [currentSelectedItems, onPersonaData]);

  const picker = createRef<any>();
  useEffect(() => {
    if (user) {
      let newUsers: string[] = [];
      currentSelectedItems.forEach((item) => {
        newUsers.push(item.key?.toString()!);
      });
      setUser!(newUsers);
    }
  }, [currentSelectedItems]);

  const onFilterChanged = (
    filterText: string,
    currentPersonas: IPersonaProps[] | undefined,
    limitResults?: number
  ): IPersonaProps[] | PromiseLike<IPersonaProps[]> => {
    if (filterText) {
      error(false);
      message ?? message("");
      item(filterText);
      let filteredPersonas: IPersonaProps[] = filterPersonasByText(filterText);
      filteredPersonas = removeDuplicates(filteredPersonas, currentPersonas!);
      filteredPersonas = limitResults
        ? filteredPersonas.slice(0, limitResults)
        : filteredPersonas;
      return filterPromise(filteredPersonas);
    } else {
      return [];
    }
  };

  function validateInput(input: string): ValidationState {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/.test(input)) {
      return ValidationState.valid;
    } else {
      error(true);
      message("invalid_email");
      return ValidationState.invalid;
    }
  }

  const filterPersonasByText = (filterText: string): IPersonaProps[] => {
    return peopleList!.filter((item) =>
      doesTextStartWith(item.text as string, filterText)
    );
  };

  const filterPromise = (
    personasToReturn: IPersonaProps[]
  ): IPersonaProps[] | Promise<IPersonaProps[]> => {
    return personasToReturn;
  };

  const onRemoveSuggestion = (item: IPersonaProps): void => {
    const indexPeopleList: number = peopleList!.indexOf(item);
    if (indexPeopleList >= 0) {
      const newPeople: IPersonaProps[] = peopleList!
        .slice(0, indexPeopleList)
        .concat(peopleList!.slice(indexPeopleList + 1));
      setPeopleList(newPeople);
    }
  };

  const onItemsChange = (items: IPersonaProps[] | undefined): void => {
    item("");
    const uniqueItems = items
      ? items.filter(
          (item, index, self) =>
            index === self.findIndex((t) => t.key === item.key)
        )
      : [];
    setCurrentSelectedItems(uniqueItems);
  };

  const handleRemove = (props: { custom: IPickerItemProps<IPersonaProps> }) => {
    const uniqueItems = currentSelectedItems.filter(
      (item, index) => index !== props.custom.index
    );
    setCurrentSelectedItems(uniqueItems);
  };


 const Email = (props: { custom: IPickerItemProps<IPersonaProps> }) => {
    return (
      <HorizontalStack
        key={props.custom.item.key}
        style={{ alignItems: "center", justifyContent: "center", }}
      >
        <Stack
          style={{
            paddingLeft: "2px",
            paddingRight: "4px",
            marginTop: "5px",
            cursor: "pointer",
          }}
          onClick={() => {
            handleRemove(props);
          }}
        >
          <EmailCircleIcon />
        </Stack>
        <Text
          style={{
            textDecorationLine:!project ? "underline": "none",
            fontSize: "16px",
            fontWeight: "500",
            lineHeight: "28px",
            fontFamily:"Poppins",
            textUnderlinePosition:"under"
          }}
        >
          {props.custom.item.key}
          {";"}
        </Text>
      </HorizontalStack>
    );
  };

  return (
    <Stack>
      <CompactPeoplePicker
        styles={styles}
        onResolveSuggestions={onFilterChanged}
        getTextFromItem={getTextFromItem}
        selectionAriaLabel={"Selected contacts"}
        removeButtonAriaLabel={"Remove"}
        className={"ms-PeoplePicker"}
        onRemoveSuggestion={onRemoveSuggestion}
        onValidateInput={validateInput}
        onChange={onItemsChange}
        selectedItems={currentSelectedItems}
        inputProps={{
          "aria-label": "People Picker",
          placeholder: placeholder,
        }}
        componentRef={picker}
        resolveDelay={300}
        onRenderItem={(props: IPickerItemProps<IPersonaProps>) => {
          return <Email custom={props} />;
        }}
      />
    </Stack>
  );
};

function doesTextStartWith(text: string, filterText: string): boolean {
  return (
    text.toLowerCase().indexOf(filterText.toLowerCase()) === 0 ||
    filterText === ""
  );
}

function removeDuplicates(
  personas: IPersonaProps[],
  possibleDupes: IPersonaProps[]
) {
  return personas.filter(
    (persona) => !listContainsPersona(persona, possibleDupes)
  );
}

function listContainsPersona(
  persona: IPersonaProps,
  personas: IPersonaProps[]
) {
  if (!persona && personas?.length === 0) {
    return false;
  }
  return personas.filter((item) => item.text === persona.text).length > 0;
}

function getTextFromItem(persona: IPersonaProps): string {
  return persona.text as string;
}
