import React from "react";

function EditIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
  <path d="M15.7773 1.27734L6.77734 10.2773L6.27734 13.7773L10.2773 12.7773L17.7773 5.27734L19.2773 2.77734L18.7773 1.27734L17.7773 0.777344L15.7773 1.27734Z" fill="white"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M17.2777 1.37773C16.9211 1.37773 16.5791 1.51939 16.327 1.77154L7.73618 10.3624L7.10236 12.8977L9.63765 12.2638L18.2285 3.67301C18.4806 3.42086 18.6223 3.07887 18.6223 2.72228C18.6223 2.36568 18.4806 2.02369 18.2285 1.77154C17.9763 1.51939 17.6343 1.37773 17.2777 1.37773ZM15.4785 0.923014C15.9557 0.445819 16.6029 0.177734 17.2777 0.177734C17.9526 0.177734 18.5998 0.445819 19.077 0.923014C19.5542 1.40021 19.8223 2.04742 19.8223 2.72228C19.8223 3.39713 19.5542 4.04435 19.077 4.52154L10.3687 13.2299C10.2918 13.3068 10.1954 13.3613 10.0899 13.3877L6.42326 14.3044C6.21879 14.3555 6.0025 14.2956 5.85347 14.1465C5.70444 13.9975 5.64453 13.7812 5.69565 13.5768L6.61232 9.91009C6.63869 9.80459 6.69324 9.70824 6.77014 9.63135L15.4785 0.923014ZM0.890441 2.83498C1.34678 2.37865 1.96571 2.12228 2.61107 2.12228H9.02773C9.35911 2.12228 9.62774 2.39091 9.62774 2.72228C9.62774 3.05365 9.35911 3.32228 9.02773 3.32228H2.61107C2.28397 3.32228 1.97026 3.45222 1.73897 3.68351C1.50767 3.91481 1.37773 4.22851 1.37773 4.55561V17.3889C1.37773 17.716 1.50767 18.0297 1.73897 18.261C1.97026 18.4923 2.28397 18.6223 2.61107 18.6223H15.4444C15.7715 18.6223 16.0852 18.4923 16.3165 18.261C16.5478 18.0297 16.6777 17.716 16.6777 17.3889V10.9723C16.6777 10.6409 16.9464 10.3723 17.2777 10.3723C17.6091 10.3723 17.8777 10.6409 17.8777 10.9723V17.3889C17.8777 18.0343 17.6214 18.6532 17.165 19.1096C16.7087 19.5659 16.0898 19.8223 15.4444 19.8223H2.61107C1.96571 19.8223 1.34678 19.5659 0.890441 19.1096C0.434103 18.6532 0.177734 18.0343 0.177734 17.3889V4.55561C0.177734 3.91025 0.434103 3.29132 0.890441 2.83498Z" fill="#323232"/>
</svg>
  );
}

export default EditIcon;