import { IStackStyles, mergeStyles, Stack, Text } from "@fluentui/react";
import { FC } from "react";
import { Document } from "../../../models";
import { HorizontalStack, VerticalStack } from "../../Stack";
import { initializeFileTypeIcons } from "@fluentui/react-file-type-icons";
import { colorPallete } from "../../../assets/color";
import { Thumbnails } from "../../Thumbnails";
import EditIcon from "../../../assets/Icons/svg/editIcon";
import TrashIcon from "../../../assets/Icons/svg/trashIcon";
import useModal from "../../../hooks/useModal";
import DeleteDocumentModal from "../../modals/DeleteDocument";
initializeFileTypeIcons();

const styles = {
  ImageStyle: {
    borderTopLeftRadius: "0.4rem",
    borderTopRightRadius: "0.4rem",
  },
  FileNameStackStyle: {
    height: "20%",
    backgroundColor: "",
    width: "97%",
    paddingLeft: "10px",
    paddingTop: "10px",
    paddingRight: "10px",
  },
  container: {
    width: "100%",
    height: "72%",
    marginLeft: "3%",
    backgroundColor: "red",
    alignItems: "flex-start",
  },
  FileName: {
    fontSize: "16px",
    paddingLeft: "1%",
    color: colorPallete.black,
    lineHeight: "20px",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    textOverflow: "ellipsis",
    maxWidth: "85%",
    backgroundColor: "",
    fontFamily: "Poppins",
  },
  EditIcon: {
    marginTop: "5%",
    bottom: 20,
    left: 12,
    width: "27%",
    justifyContent: "space-between",
  },
  DocCover: {
    height: "104px",
    width: "198px",
    background: colorPallete.btLightBlue,
    boxShadow: "0px 4px 6px rgb(0 0 0 / 25%)",
    zIndex: 1,
    padding: "4%",
  },
  DocCard: {
    height: "300px",
    minWidth: "200px",
    width: "220px",
    border: `1px solid ${colorPallete.btLightBlue}`,
    boxShadow: "4px 4px 4px 0px rgba(0, 0, 0, .25)",
    cursor: "pointer",
  },
};

const stackStyles: IStackStyles = {
  root: {
    width: "100%",
    height: "100%",
    position: "absolute",
    opacity: 0.8,
  },
};

type Props = {
  document: Document;
  showModal?: any;
  deleteDocName: any;
  deleteRootDocId?: any;
};

export const ProjectDocumentCard: FC<Props> = (props) => {
  return (
    <div
      style={{ ...styles.DocCard, position: "relative" }}
      title={props.document?.rootDocumentName ?? ""}
      key={props.document?.documentId}
    >
      <VerticalStack styles={stackStyles}>
        <Thumbnails
          driveItemId={props.document?.driveItemId}
          height={550}
          width={220}
        />
      </VerticalStack>
      <VerticalStack
        style={{ ...styles.DocCover, position: "absolute" }}
        title={props.document?.rootDocumentName ?? ""}
      >
        <HorizontalStack title={props.document?.rootDocumentName ?? ""}>
          <Text
            className={mergeStyles(styles.FileName)}
            style={{ WebkitLineClamp: 2, overflow: "hidden", fontWeight: 400 }}
          >
            {props.document?.rootDocumentName.split(".docx") ?? ""}
          </Text>
        </HorizontalStack>
        <HorizontalStack style={{ ...styles.EditIcon, position: "absolute" }}>
          <EditIcon />
          <Stack
            style={{ cursor: "pointer" }}
            onClick={() => {
              props.deleteDocName(
                props.document.rootDocumentName.split(".docx")[0]
              );
              props.deleteRootDocId(props.document.rootDocumentId);
              props.showModal();
            }}
          >
            <TrashIcon />
          </Stack>          
        </HorizontalStack>
      </VerticalStack>
    </div>
  );
};
