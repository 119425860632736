import { createSlice } from '@reduxjs/toolkit'
import { Document } from '../../../models'

export interface PrecedentFilesState {
  precedentData: Document[]
}

const initialState: PrecedentFilesState = {
    precedentData: [],
}

export const precedentFilesSlice = createSlice({
  name: 'precedentFiles',
  initialState,
  reducers: {

  },
})
const precedentFileReducer =precedentFilesSlice.reducer;
export default precedentFileReducer  