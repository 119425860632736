import { FC, useEffect, useState } from "react";
import { Project } from "../../models";
import { NewProject } from "../../components/modals/NewProject";
import { ProjectInCard } from "../../components/Cards/ProjectInCard";
import { HorizontalStack } from "../../components/Stack";
import {
  FontSizes,
  IButtonStyles,
  mergeStyles,
  PrimaryButton,
  Stack,
  Text,
} from "@fluentui/react";
import Loader from "../../components/Loader";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { getAllProjects, getSearchedProjects } from "../redux/Projects/project";
import InfiniteScroll from "react-infinite-scroll-component";

export const AllProjectData: FC = () => {
  const isSearchEnabled = useAppSelector(
    (state) => state.isSearchEnabled.value
  );
  const searchParam = useAppSelector((state) => state.isSearchEnabled.text);
  const [isError, setIsError] = useState<boolean>(false);
  const { isLoading, data, error, metadata } = useAppSelector(
    (state) =>
      isSearchEnabled ? state.fetchSearchedProject : state.fetchProjects,
  );
  const [projectData, setProjectData] = useState<Project[]>([]);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (!isLoading) {
      if (data && data.length > 0) {
        setProjectData(data);
      } else {
        setProjectData([]);
      }
    }
  }, [data]);

  useEffect(() => {
    isSearchEnabled && 
    dispatch(getSearchedProjects({
      projectName:searchParam,
      limit:12,
      offset:0
    }));
  }, [searchParam]);

  useEffect(() => {
    if (data.length === 0 || isError)
      dispatch(
        getAllProjects({
          limit: 12,
          offset: 0,
        })
      );
  }, [isError]);

  const loaderJSX = isLoading && projectData.length === 0 && (
    <Stack className={mergeStyles(styles.loaderStyles)}>
      <Loader />
    </Stack>
  );

  const errorJSX =
    !isLoading && error === "Network Error" ? (
      <Stack className={mergeStyles(styles.errorStyles)}>
        <Text className={mergeStyles(styles.textStyles)}>{t("loader")}</Text>
        <PrimaryButton
          styles={retryButtonStyles}
          onClick={() => setIsError(!isError)}
        >
          {t("retry")}
        </PrimaryButton>
      </Stack>
    ) : (
      !isLoading &&
      data.length === 0 && (
        <Stack className={mergeStyles(styles.errorStyles)}>
          <Text className={mergeStyles(styles.textStyles)}>
            {t("no_projects_found")}
          </Text>
        </Stack>
      )
    );

  const fetchMoreData = () => {
    let newOffSet = metadata.offset + metadata.limit;
    let searchPayload = {
      projectName: searchParam,
      limit: 12,
      offset: newOffSet,
    };
    let payload = {
      limit: 12,
      offset: newOffSet,
    };

    if (metadata.offset < metadata.count) {
      dispatch(
        isSearchEnabled
          ? getSearchedProjects(searchPayload)
          : getAllProjects(payload)
      ).then((res: any) => {
        if (res.payload.data && res.payload.data.length > 0) {
          setProjectData([...projectData, ...res.payload.data]);
        }
      });
    }
  };

  return (
    <div style={styles.ProjectStackDivStyle}>
      <HorizontalStack className={mergeStyles(styles.ProjectStackStyles)}>
        {projectData.length===0 && <NewProject />}
        {loaderJSX}
        {errorJSX}
        {!errorJSX && projectData.length!==0 && (
          <InfiniteScroll
            dataLength={projectData.length}
            next={fetchMoreData}
            hasMore={projectData.length < metadata?.count}
            loader={
              <Stack
                style={{
                  padding: "5%",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Loader />
              </Stack>
            }
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              backgroundColor: "",
              flexWrap: "wrap",
              overflowY: "hidden",
              gap:"13px"
            }}
          >
             <NewProject />
            {projectData.map((project) => {
              return (
                <ProjectInCard key={project.projectId} project={project} />
              );
            })}
          </InfiniteScroll>
        )}
      </HorizontalStack>
    </div>
  );
};

const styles = {
  loaderStyles: {
    display: "flex",
    justifyContent: "center",
    width: "75%",
    height: "18rem",
    alignItems: "center",
    marginTop: "1.5%",
  },
  textStyles: {
    fontSize: FontSizes.size20,
  },
  errorStyles: {
    display: "flex",
    justifyContent: "center",
    width: "75%",
    height: "18rem",
    alignItems: "center",
  },
  ProjectStackDivStyle: {
    width: "100%",
  },
  ProjectStackStyles: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
  },
};

const retryButtonStyles: IButtonStyles = {
  root: {
    width: "20px",
    margin: "1%",
    alignItems: "center",
  },
};
