import React, { useState } from "react";
import { HorizontalStack, VerticalStack } from "../../../components/Stack";
import { Stack, Text } from "@fluentui/react";
import { colorPallete } from "../../../assets/color";
import LibraryIcon from "../../../assets/Icons/svg/libraryIcon";
import SendIcon from "../../../assets/Icons/svg/sendIcon";
import ReceivedIcon from "../../../assets/Icons/svg/receivedIcon";
import LockIcon from "../../../assets/Icons/svg/lockIcon";
import EditIcon from "../../../assets/Icons/svg/editIcon";
import DocumentIcon from "../../../assets/Icons/svg/documentIcon";
import RedLineDocumentIcon from "../../../assets/Icons/svg/redLineDocument";
import TrashIcon from "../../../assets/Icons/svg/trashIcon";
import { motion } from "framer-motion";
import { getFormattedDateTime } from "../../../utils";
import { Document } from "../../../models";
import { t } from "i18next";

const DocumentListView = (props: any) => {
  const [hoverId, setHoverId] = useState<number | undefined>(0);
  const { deleteDocName, showModal, deleteRootDocId } = props;
  function getFileTypeIcon(fileType?: string, isRedlined?: boolean) {
    if (fileType === "sent") {
      return <SendIcon />;
    } else if (fileType === "received") {
      return <ReceivedIcon />;
    } else if (fileType === "draft" && isRedlined === false) {
      return <DocumentIcon />;
    } else if (fileType === "draft" && isRedlined === true) {
      return <RedLineDocumentIcon />;
    }
  }

  function getFileTypeDate(fileType?: string) {
    if (fileType === "sent") {
      return (
        <Text
          style={{
            fontFamily: "Poppins",
            fontSize: "14px",
            lineHeight: "20px",
            fontWeight: 400,
            color: "#717070",
          }}
        >
          {t("sent_on")}
        </Text>
      );
    } else if (fileType === "received") {
      return (
        <Text
          style={{
            fontFamily: "Poppins",
            fontSize: "14px",
            lineHeight: "20px",
            fontWeight: 400,
            color: "#717070",
          }}
        >
          {t("received_on")}
        </Text>
      );
    } else {
      return (
        <Text
          style={{
            fontFamily: "Poppins",
            fontSize: "14px",
            lineHeight: "20px",
            fontWeight: 400,
            color: "#717070",
          }}
        >
          {t("saved_on")}
        </Text>
      );
    }
  }
  function getIcons(
    fileType?: string,
    documentName?: string,
    rootDocumentId?: number
  ) {
    if (fileType === "draft") {
      return (
        <HorizontalStack
          style={{
            width: "82px",
            height: "22px",
            gap: "8px",
            justifyContent: "flex-end",
          }}
        >
          <EditIcon />
          <Stack
            style={{ cursor: "pointer" }}
            onClick={() => {
              deleteDocName(documentName);
              deleteRootDocId(rootDocumentId);
              showModal();
            }}
          >
            <TrashIcon />
          </Stack>
        </HorizontalStack>
      );
    } else {
      return (
        <HorizontalStack
          style={{
            width: "82px",
            height: "22px",
            gap: "8px",
            justifyContent: "flex-end",
          }}
        >
          <LockIcon />
        </HorizontalStack>
      );
    }
  }

  const ListCard = (props: any) => {
    return (
      <HorizontalStack style={styles.card}>
        <LibraryIcon />
        <HorizontalStack
          style={{
            width: "50%",
            paddingLeft: "12px",
            gap: "12px",
            alignItems: "center",
          }}
        >
          <Text style={{ ...styles.fileName }}>
            {props.document.rootDocumentName?.split(".docx") ?? ""}
          </Text>
          <HorizontalStack
            style={{
              height: "24px",
              borderRadius: "4px",
              border: "1px solid #E8ECF4 ",
              padding: "4px 8px",
              backgroundColor: "#F8F8F8",
              alignItems: "center",
            }}
          >
            <Text style={styles.versions}>
              {props.document.versions.length}&nbsp;
            </Text>
            <Text style={styles.versions}>
              {props.document.versions.length &&
              props.document.versions.length === 1
                ? t("version")
                : t("versions")}
            </Text>
          </HorizontalStack>
        </HorizontalStack>
        {props.document &&
          props.document.versions &&
          props.document.versions.map((version: any, index: any) => {
            if (index === 0) {
              return (
                <HorizontalStack
                  horizontalAlign="start"
                  style={{
                    width: "50%",
                    gap: "32px",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <HorizontalStack
                    key={index}
                    style={{ width: "158px", gap: "4px", alignItems: "center" }}
                  >
                    {getFileTypeIcon(version?.fileType, version?.isRedlined)}
                    <Text
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "14px",
                        lineHeight: "20px",
                        fontWeight: 600,
                        color: "#323232",
                      }}
                    >
                      V{version.documentVersion}
                    </Text>
                    <Text
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "14px",
                        lineHeight: "20px",
                        fontWeight: 500,
                        color: "#717070",
                      }}
                    >
                      {t("most_recent")}
                    </Text>
                  </HorizontalStack>
                  <HorizontalStack style={styles.dateTime}>
                    {getFileTypeDate(version?.fileType)}&nbsp;
                    {getFormattedDateTime(String(version?.lastUpdatedDate))[0]}
                    &nbsp;|&nbsp;
                    {getFormattedDateTime(String(version?.lastUpdatedDate))[1]}
                  </HorizontalStack>
                  {getIcons(
                    version?.fileType,
                    props.document.rootDocumentName?.split(".docx")[0],
                    props.document?.rootDocumentId
                  )}
                </HorizontalStack>
              );
            }
            return null;
          })}
      </HorizontalStack>
    );
  };

  const docList = (doc: Document) => {
    return (
      <motion.div
        animate={{
          opacity: 1,
          scale: hoverId === doc.rootDocumentId ? 1.01 : 1,
        }}
        initial={{
          opacity: 0,
        }}
        style={{
          border:
            props.selectedId === doc.rootDocumentId
              ? "1px solid #18468F"
              : "1px solid #E8ECF4",
          cursor: "pointer",
          borderRadius: "8px",
          backgroundColor:
            props.selectedId === doc.rootDocumentId ? "#F3F5F9" : "#FFFFFF",
        }}
        onMouseEnter={() => setHoverId(doc.rootDocumentId)}
        onMouseLeave={() => {
          setHoverId(0);
        }}
        onClick={() => {
          props.handleFilePath(doc.rootDocumentId);
          props.setSelectedDocument(doc.rootDocumentName);
          props.setSelectedId(doc.rootDocumentId);
        }}
        key={doc.documentId}
      >
        <ListCard document={doc} />
      </motion.div>
    );
  };

  return (
    <VerticalStack
      style={{
        ...styles.container,
        overflowY: props.listView ? "visible" : "scroll",
        height: props.listView ? "" : "351px",
        backgroundColor: "",
      }}
    >
      {props.data &&
        props.data?.length !== 0 &&
        props.data?.map((doc: Document) => {
          return docList(doc);
        })}
    </VerticalStack>
  );
};

export default DocumentListView;
const styles = {
  container: {
    width: "98%",
    borderRadius: "8px",
    padding: "10px",
    gap: "10px",
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 4px 10px 0px #0000001F",
    marginTop: "14px",
    marginBottom: "20px",
    marginLeft: "0.6%",
  },
  card: {
    width: "100%",
    height: "56px",
    alignItems: "center",
    borderRadius: "8px",
    padding: "16px 20px 16px 20px",
    justifyContent: "space-between",
  },
  fileName: {
    color: `${colorPallete.black1}`,
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: "600",
    lineHeight: "20px",
  },
  dateTime: {
    color: "#323232",
    fontWeight: "400",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontSize: "14px",
    lineHeight: "20px",
    width: "255px",
  },
  versions: {
    fontFamily: "Poppins",
    color: "#323232",
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "16px",
  },
};
