import { FC, useState, useEffect } from "react";
import { Document, Project, UserModel } from "../../../models";
import { HorizontalStack, VerticalStack } from "../../Stack";
import { ProjectCard } from "../ProjectCard";
import {
  IStackTokens,
  IStackStyles,
  Text,
  Stack,
  IPlainCardProps,
  HoverCard,
  HoverCardType,
  IStackProps,
} from "@fluentui/react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { colorPallete } from "../../../assets/color";
import { getFormattedDate } from "../../../utils";
import OpenFile from "../../../navigation/NoobOrPro";
import TrashIcon from "../../../assets/Icons/svg/trashIcon";
import useModal from "../../../hooks/useModal";
import DeleteProjectModal from "../../modals/DeleteProjectModal";

const innerStackTokens: IStackTokens = {
  childrenGap: "2%",
};

const outerStackStyles: IStackStyles = {
  root: {
    paddingLeft: "4%",
    paddingRight: "4%",

  },
};

type Props = {
  user?: UserModel;
  project: Project;
  onClick?: () => void;
  sender?: string;
};

const CounterPartiesHoverCard: React.FC<{ counterParties: string[] }> = ({
  counterParties,
}) => {
  const plainCardProps: IPlainCardProps = {
    onRenderPlainCard: () => (
      <div style={{ padding: "5px", backgroundColor: "white" }}>
        <ul>
          {counterParties.map((counterParty) => (
            <li key={counterParty}>{counterParty}</li>
          ))}
        </ul>
      </div>
    ),

    directionalHint: 1,
  };

  return (
    <HoverCard type={HoverCardType.plain} plainCardProps={plainCardProps}>
      <div
        style={{
          fontSize: "14px",
          borderRadius: "1rem",
          minWidth: "1px",
          maxWidth: "fit-content",
          color: "rgb(6 42 108)",
        }}
      >
        {counterParties.length === 1
          ? `+${counterParties.length} Other`
          : `+${counterParties.length} Others`}
      </div>
    </HoverCard>
  );
};

export const ProjectInCard: FC<Props> = (props) => {

  const { t } = useTranslation();
  const { isModalOpen, showModal, hideModal } = useModal();
  const [docData, setDocData] = useState<Document[]>([]);
  const navigate = useNavigate();

  const showDocuments = (projectId: number | undefined) => {
    navigate(`${projectId}`, {
      state: {
        review: false,
        fileName: null,
      },
    });
  };

  useEffect(() => {
    if (props?.project?.documents && props?.project?.documents.length > 0) {
      setDocData([...props?.project?.documents]);
    }
  }, [props]);
  

  const colors = [
    "#101828",
    "#007CFF",
    "#750b1c",
    "#18468F",
    "#e3008c",
    "#5c2e91",
    "#986f0b",
  ];

  const getColor = (name: string) => {
    return colors[name.length % colors.length];
  };

  // Updated renderPerson function
  const renderCounterParties = (data: string | undefined) => {
    let counterParties = data?.split(";");
    if (counterParties && counterParties.length > 0) {
      let remainingCount = counterParties.length - 2;
      const horizontalStackProps: IStackProps = {
        style: { width: "100%", flexWrap: "wrap" },
      };

      return (
        <>
          <Stack {...horizontalStackProps}>
            {counterParties.slice(0, 2).map((name) => {
              return (
                <HorizontalStack
                  style={styles.counterPartyContainer}
                  key={name}
                >
                  <Stack
                    style={{
                      ...styles.counterPartyLogo,
                      backgroundColor: getColor(name.trim()),
                    }}
                  >
                    <Text style={styles.counterPartyInitial}>
                      {name.trim().charAt(0).toUpperCase()}
                    </Text>
                  </Stack>
                  <Text style={styles.counterParty}>{name.trim()}</Text>
                </HorizontalStack>
              );
            })}

            {remainingCount > 0 && (
              <CounterPartiesHoverCard
                counterParties={counterParties.slice(2)}
              />
            )}
          </Stack>
        </>
      );
    }
    return null;
  };

  const projectId = props.project?.projectId?.toString()

  return (
    <ProjectCard>
      <div
        className="Card" >
        <VerticalStack className="ProjectCardTop"  
           
        onClick={() => {
          props.sender !== "AllProjects" &&
            showDocuments(props.project?.projectId);
        }} >
          <VerticalStack tokens={innerStackTokens} styles={outerStackStyles}
   >
            <Text className="ProjectName" title={props.project?.projectName}>
              {props.project?.projectName}
            </Text>
            <Text
              title={props.project?.counterParties}
              className="CounterParty"
            >
              {t("counter_parties")}
            </Text>
            {props.project?.counterParties &&
              renderCounterParties(props.project?.counterParties)}
          </VerticalStack>
        </VerticalStack>
        {docData.length > 0 ? (
          <VerticalStack
            style={{
              backgroundColor: "",
              position: "absolute",
              bottom: docData.length == 1 ? 72 : 55,
              width: "80%",
              marginLeft: "4%",
            }}
          >
            <Text
              key={docData[0]?.documentId}
              style={{
                ...styles.documentName,
                position: "relative",
                whiteSpace: "nowrap",
                cursor: "pointer",
              }}
              title={docData[0]?.documentName}
              onClick={() => {
                OpenFile(docData[0]?.documentLink, docData[0]?.documentWebLink);
              }}
            >
              {docData[0].documentName.split(".docx")[0]};
            </Text>
            {docData.length > 1 && (
              <Text style={styles.blueText}>+{docData.length - 1} Doc</Text>
            )}
          </VerticalStack>
        ) : null}

        <HorizontalStack
          style={{
            ...styles.date,
            position: "absolute",
            width: "87%",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Text style={{ ...styles.date, marginLeft: 0 }}>
            {getFormattedDate(String(props.project?.createdAt))}
          </Text>
         {window.location.pathname.includes(
           projectId!
          ) ? (
            <Stack
              style={{ cursor: "pointer" }}
              onClick={() => {
                showModal();
              }}
            >
              <TrashIcon />
            </Stack>
          ) : null}
        </HorizontalStack>
      </div>
      <DeleteProjectModal
        isModalOpen={isModalOpen}
        showModal={showModal}
        hideModal={hideModal}
        projectName={props.project?.projectName}
        projectId={props.project?.projectId}
      />
    </ProjectCard>
  );
};

const styles = {
  counterPartyLogo: {
    height: "32px",
    width: "32px",
    // backgroundColor: colorPallete.btBlue,
    borderRadius: "16px",
    alignItems: "center",
    justifyContent: "center",
  },
  counterPartyInitial: {
    fontSize: "13px",
    lineHeight: "16px",
    color: "#F4F4F4",
  },
  counterParty: {
    paddingLeft: "4%",
    fontSize: "13px",
    lineHeight: "16px",
    fontWeight: "400",
    color: colorPallete.black1,
    backgroundColor: "",
    fontFamily: "Poppins",
  },
  counterPartyContainer: {
    alignItems: "center",
    justifyContent: "flex-start",
    paddingBottom: "3.5%",
    backgroundColor: "",
    paddingTop: "0.5%",
  },
  bodyStyles: { paddingTop: "2%", paddingBottom: "4%", backgroundColor: "" },
  documentName: {
    fontSize: "14px",
    color: colorPallete.black,
    backgroundColor: "",
    textOverflow: "ellipsis",
    overflow: "hidden",
    fontWeight: 400,
    textDecorationLine: "underline",
    fontFamily: "Poppins",
  },
  blueText: {
    fontFamily: "Poppins",
    fontWeight: 500,
    color: "var(--primary-blue, #18468F)",
    fontSize: "12px",
    fontStyle: "normal",
  },
  date: {
    display: "flex",
    marginLeft: "4%",
    fontSize: "12px",
    color: "#717070",
    bottom: 12,
    fontFamily: "Poppins",
  },
};
