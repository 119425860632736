import  { FC, useState } from "react";
import { FormModal } from "../Modal";
import { HorizontalStack, VerticalStack } from "../../Stack";
import { Stack, Text } from "@fluentui/react";
import "./index.css";
import { useAppDispatch } from "../../../hooks/hooks";
import { deleteDocument } from "../../../modules/redux/Documents/document";
import Loader from "../../Loader";
import { t } from "i18next";

const DeleteDocumentModal: FC<any> = (props) => {
  const dispatch = useAppDispatch();
  const [modal, setModal] = useState<boolean>(false);
  const [upload, setUpload] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");

  const handleDeleteClick = () => {
    if (props.rootDocumentId) {
      setModal(true);
      dispatch(deleteDocument(props.rootDocumentId)).then((res: any) => {
        setUpload(true);
        if (res.meta.requestStatus === "fulfilled") {
          setMessage("Success");
          props.refresh();
        } else {
          setMessage("Error");
        }
        setTimeout(()=>{
          disable()
        },3000)
      });
    }
  };

  function disable() {
    setUpload(false);
    setMessage("");
    setModal(false);
    props.hideModal();
  }

  return (
    <FormModal
      title={
        upload
          ? message === "Success"
            ? t("success")
            : t("error")
          : t("delete_document")
      }
      TitleBackgroundColor={
        upload ? (message == "Success" ? "#13540C" : "#A82C0C") : "#A82C0C"
      }
      isModalOpen={props.isModalOpen}
      showModal={props.showModal}
      hideModal={props.hideModal}
      width="480px"
      cancelButton
      customCancel={disable}
    >
      <VerticalStack
        className="deleteContainer"
        style={{ minHeight: !modal ? "277px" : "100px" }}
      >
        {modal ? (
          <>
            <VerticalStack
              style={{
                alignItems: upload ? "" : "center",
                justifyContent: upload ? "" : "space-between",
                backgroundColor: "",
                gap: "20px",
                padding: "20px 25px",
                width:"100%"
              }}
            >
              {!upload ? <Loader /> : null}
              <Text
                style={{
                  fontSize: "17px",
                }}
                className="deleteContentText"
              >
                {upload
                  ? (<Text className="deleteContentText">
                   { `${props?.documentName}`} {t("deleted")}
                  </Text>
                )
                  : (<Text className="deleteContentText">
                 {t("deleting")} { `${props?.documentName}`} 
                 </Text>
               )
                  }
              </Text>
            </VerticalStack>
           
          </>
        ) : (
          <>
            <VerticalStack style={{ padding: "20px 25px", gap: "20px", }}>
              <Text className="deleteContentText">
               {t("delete_conf")}&nbsp;{props?.documentName}?
              </Text>
              <Text className="deleteContentText1">
              {t("delete_doc_warning")}
              </Text>
            </VerticalStack>
            <Stack className="horizontalLine"></Stack>
            <HorizontalStack
              className="deleteBottom"
              style={{ padding: "20px 25px", }}
            >
              <Text className="deleteCancelButton" onClick={props.hideModal}>
              {t("cancel")}
              </Text>
              <Text className="deleteButton" onClick={handleDeleteClick}>
                {t("delete")}
              </Text>
            </HorizontalStack>
          </>
        )}
      </VerticalStack>
    </FormModal>
  );
};

export default DeleteDocumentModal;
