import React from "react";

export default function Sent() {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
      >
        <path
          d="M0.28418 5.9037L5.5014 8.60342L11.8925 11.9459L13.7185 0.632812L0.28418 5.9037Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.9254 0.443278C14.0309 0.558423 14.0232 0.737331 13.908 0.84288L5.42316 8.62065C5.30802 8.7262 5.12911 8.71842 5.02356 8.60328C4.91801 8.48813 4.92579 8.30923 5.04093 8.20368L13.5258 0.425904C13.6409 0.320355 13.8198 0.328134 13.9254 0.443278Z"
          fill="#13540C"
        />
        <path
          d="M8.0607 9.96571L5.23242 8.41016V13.3596L8.0607 9.96571Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.0881 8.16828C5.17417 8.11738 5.28073 8.11573 5.36835 8.16392L8.19663 9.71947C8.27063 9.76017 8.32258 9.83182 8.33825 9.91482C8.35392 9.99781 8.33167 10.0835 8.2776 10.1484L5.44932 13.5423C5.37309 13.6338 5.24773 13.6677 5.13577 13.6272C5.0238 13.5866 4.94922 13.4803 4.94922 13.3612V8.41173C4.94922 8.31174 5.00202 8.21917 5.0881 8.16828ZM5.51487 8.89007V12.58L7.62343 10.0498L5.51487 8.89007Z"
          fill="#13540C"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.8947 0.414202C13.9737 0.477945 14.0126 0.579193 13.9964 0.679461L12.1704 11.9926C12.1559 12.0823 12.0991 12.1597 12.0178 12.2004C11.9365 12.2411 11.8406 12.2403 11.7601 12.1981L5.37007 8.8562L5.36897 8.85563L0.152848 6.15647C0.0542617 6.10546 -0.00536774 6.00149 0.00038145 5.89063C0.00613062 5.77978 0.0761953 5.68254 0.17953 5.64199L13.6139 0.371105C13.7084 0.334009 13.8156 0.350459 13.8947 0.414202ZM0.966786 5.94075L5.63112 8.35438L11.6745 11.515L13.359 1.07875L0.966786 5.94075Z"
          fill="#13540C"
        />
      </svg>
    </div>
  );
}
