import React from "react";

function FilterIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.9877 5.4236C14.0095 4.97897 9.99403 4.97897 6.01575 5.4236C5.95475 5.43059 5.89676 5.45383 5.84781 5.49088C5.79886 5.52792 5.76075 5.57743 5.73746 5.63423C5.71417 5.69103 5.70654 5.75304 5.71539 5.81379C5.72424 5.87454 5.74922 5.9318 5.78775 5.9796L9.30475 10.3276C10.5645 11.885 11.2518 13.8275 11.2517 15.8306V18.7196L12.7517 19.8196V15.8296C12.7519 13.8268 13.4392 11.8847 14.6987 10.3276L18.2157 5.9796C18.2543 5.9318 18.2793 5.87454 18.2881 5.81379C18.2969 5.75304 18.2893 5.69103 18.266 5.63423C18.2427 5.57743 18.2046 5.52792 18.1557 5.49088C18.1067 5.45383 18.0487 5.43059 17.9877 5.4236ZM5.84975 3.9326C9.93868 3.47559 14.0658 3.47559 18.1547 3.9326C19.6007 4.0946 20.2977 5.7906 19.3827 6.9226L15.8647 11.2706C14.8209 12.5611 14.2516 14.1708 14.2517 15.8306V21.2996C14.2518 21.4383 14.2135 21.5743 14.1409 21.6925C14.0684 21.8107 13.9645 21.9065 13.8407 21.9692C13.717 22.0319 13.5783 22.059 13.4401 22.0476C13.3019 22.0362 13.1695 21.9867 13.0577 21.9046L10.0577 19.7046C9.96281 19.6349 9.88564 19.5438 9.83248 19.4387C9.77932 19.3335 9.75166 19.2174 9.75175 19.0996V15.8296C9.75175 14.1696 9.18175 12.5606 8.13875 11.2696L4.62175 6.9236C3.70575 5.7916 4.40175 4.0946 5.84975 3.9326Z"
        fill="#323232"
      />
    </svg>
  );
}
export default FilterIcon;
