import { Link, Stack, Text } from "@fluentui/react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import useModal from "../../hooks/useModal";
import { CreateDocumentModal } from "../modals/CreateDocumentModal";
import { useTranslation } from "react-i18next";
import { HorizontalStack, VerticalStack } from "../Stack";
import { CreateProjectModal } from "../modals/CreateProjectModal";
import { useSelector } from "react-redux";
import { colorPallete } from "../../assets/color";
import UploadDocumentIcon from "../../assets/Icons/svg/uploadDocumentIcon";

export default function DragAndDrop(props) {
  const { t } = useTranslation();
  const [fileName, setFileName] = useState("");
  const [projectModel, setProjectModel] = useState(false);

  const onDrop = useCallback((file) => {
    setFileName(file[0].name);
    showModal();
  });
  let value = props.direction;
  useEffect(() => {
    setTimeout(() => {
      if (value === "open" && props.data?.length === 0) {
        value = "";
        showModal();
      }
    });
  }, []);

  const functionalityState = useSelector((state) => state.DocumentStack.value);
  useEffect(() => {}, [functionalityState]);
  const { isModalOpen, showModal, hideModal } = useModal();

  function showProjectModal() {
    setProjectModel(true);
  }
  function hideProjectModal() {
    setProjectModel(false);
  }
  function getModelValue() {
    hideModal();
    showProjectModal();
  }
  function displayUplaod() {
    showModal();
  }

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    acceptedFiles,
  } = useDropzone({ onDrop });

  const style = useMemo(
    () => ({
      ...styles.baseStyle,
      ...(isFocused ? styles.focusedStyle : {}),
      ...(isDragAccept ? styles.acceptStyle : {}),
      ...(isDragReject ? styles.rejectStyle : {}),
      ...(props.data && { backgroundColor: colorPallete.btLightBlue }),
      ...(props?.data && {
        boxShadow: "0px 4px 4px 0px rgba(0,0,0,0.10)",
      }),
    }),
    [isFocused, isDragAccept, isDragReject, props]
  );

  const getModal = () => {
    if ((props.data || props.projectId) && !projectModel) {
      return (
        // <AddDocumentModal
        //   isModalOpen={isModalOpen}
        //   showModal={showModal}
        //   file={acceptedFiles}
        //   hideModal={hideModal}
        //   folderName={props.folderName}
        //   refresh={props.refresh}
        // />
        <CreateDocumentModal
          fileName={fileName}
          isModalOpen={isModalOpen}
          showModal={showModal}
          hideModal={hideModal}
          file={acceptedFiles}
          project={getModelValue}
          refresh={props.refresh}
          folderName={props?.folderName}
          projectId={props?.projectId}
        />
      );
    } else if (!projectModel && !props.direction) {
      return (
        <CreateDocumentModal
          fileName={fileName}
          isModalOpen={isModalOpen}
          showModal={showModal}
          hideModal={hideModal}
          file={acceptedFiles}
          project={getModelValue}
        />
      );
    } else if (projectModel) {
      return (
        <CreateProjectModal
          isModalOpen={projectModel}
          showModal={showProjectModal}
          hideModal={hideProjectModal}
          displayDoc={displayUplaod}
        />
      );
    }
  };

  return (
    <VerticalStack style={styles.container} title={t("click_to_upload")}>
      <Text style={{ marginBottom: "3%" }}>{props.dropZoneText}</Text>
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <Stack
          style={{
            justifyContent: props.data ? "space-betweeen" : "center",
            alignItems: "center",
            flexDirection: props.data ? "row" : "column",
            height: props.data ? "100%" : "50%",
            backgroundColor: "",
          }}
        >
          <UploadDocumentIcon />
          <HorizontalStack
            style={{ ...styles.drag, paddingTop: props.data ? "0%" : "4%" }}
          >
            <Text style={styles.dragText} className="Drag">
              {t("drag_drop")}
            </Text>
            <Link
              style={{
                fontSize: "0.8rem",
                fontWeight: "400",
                color: colorPallete.btBlue,
                fontFamily: "Poppins",
              }}
              underline
            >
              {t("browse")}
            </Link>
          </HorizontalStack>
        </Stack>
      </div>
      {getModal()}
    </VerticalStack>
  );
}

const styles = {
  focusedStyle: {
    borderColor: "#2196f3",
  },
  acceptStyle: {
    borderColor: "#00e676",
  },
  rejectStyle: {
    borderColor: "#ff1744",
  },
  baseStyle: {
    backgroundColor: "#F8F8F8",
    borderRadius: "4px",
    padding: "10px",
    textAlign: "center",
    cursor: "pointer",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    backgroundImage: `repeating-linear-gradient(1deg, #0E4394, #0E4394 11px, transparent 11px, transparent 25px, #0E4394 25px), repeating-linear-gradient(91deg, #0E4394, #0E4394 11px, transparent 11px, transparent 25px, #0E4394 25px), repeating-linear-gradient(181deg, #0E4394, #0E4394 11px, transparent 11px, transparent 25px, #0E4394 25px), repeating-linear-gradient(271deg, #0E4394, #0E4394 11px, transparent 11px, transparent 25px, #0E4394 25px)`,
    backgroundSize: "1px 100%, 100% 1px, 1px 100% , 100% 1px",
    backgroundPosition: "0 0, 0 0, 100% 0, 0 100%",
    backgroundRepeat: "no-repeat",
    display: "flex",
    width: "100%",
  },
  container: {
    width: "100%",
    height: "100%",
  },
  drag: {
    width: "100%",
    textOverflow: "ellipsis",
    overflow: "hidden",
    textAlign: "center",
    backgroundColor: "",
  },
  dragText: {
    paddingLeft: "1rem",
    paddingRight: "0.3rem",
    fontSize: "0.8rem",
    fontWeight: "400",
    color: colorPallete.grey1,
    fontFamily: "Poppins",
  },
};
