import React from "react";

function LibraryIcon(props:any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width ? props.width :"24"}
      height={props.height ? props.height :"24"}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M1 17.5L12 23L23 17.5M1 12L12 17.5L23 12M12 1L1 6.5L12 12L23 6.5L12 1Z"
        stroke="#18468F"
        stroke-width="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default LibraryIcon;
