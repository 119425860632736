export const ApiEndpoints={
    createProject :()=>'/folders',   //POST
    getAllProjects : ()=>'/folders/all',    //GET
    getFolderDetails:(id:number)=>`/folders/${id}`,    //GET
    getDocumentsInFolder :()=>`/folders/files`,   //GET
    getPdfsInFolder:(folderName:string)=>`/folders/${folderName}/pdfs`,    //GET
    uploadFile:()=>"/upload/file",   //POST
    uploadMultiFile:()=>'/upload/file',   //POST
    uploadNewVersion:()=>'/upload/file/version',  //POST
    createTemplate:()=>'/files/template',
    createTemplateVersion:()=>'/files/template/version',
    getPrecedentFiles : ()=>'/files/precedent',   //GET
    getDocumentVersions:()=>'/files/versions',    //GET
    createNewVersion:()=>"/files/draft",  //GET
    search :()=>'/search',    //GET
    searchFolders:()=>'/search/folders',
    searchVersions:()=>'/search/versions',
    users :()=>'/users',
    grantUserPermission: () => "/permissions/grant",   //GET
    getVersionSharedAccess:()=>"/permissions/document",  //GET
    docusign:()=>"/docusign/code",    //GET
    createUser:()=>"/docusign/user",    //POST
    shareDocument:()=>"/file/share",    //POST
    shareSentDocument:()=>"/file/share/grant",  //POST
    userDetails:()=>"/users/details",
    getDocumentLinks: ()=>"/files/documentLinks",
    deleteDocument:(root_document_id:number)=>`/files/root/${root_document_id}`,
    deleteVersion:(document_id:number)=>`/files/version/${document_id}`,
    deleteProject:(project_id:number)=>`/folders/${project_id}`,
    grantPermissionFolder:()=>`/permissions/folder/files`
};
