import { FC, useEffect, useState } from "react";
import { HorizontalStack } from "../../../components/Stack";
import {
  FontSizes,
  IButtonStyles,
  PrimaryButton,
  Stack,
  Text,
  mergeStyles,
} from "@fluentui/react";
import { ViewAll } from "../../../components/ViewAll";
import { ProjectDocumentCard } from "../../../components/Cards/ProjectDocumentCard";
import { colorPallete } from "../../../assets/color";
import { motion } from "framer-motion";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import { useTranslation } from "react-i18next";
import Loader from "../../../components/Loader";
import { Document } from "../../../models";
import {
  getDocumentversions,
  getFilesInFolders,
} from "../../../modules/redux/Documents/document";
import { useParams } from "react-router-dom";
import { VersionsInDoc } from "../VersionsInDoc";
import SearchIcon from "../../../assets/Icons/svg/searchIcon";
import ListIcon from "../../../assets/Icons/svg/listIcon";
import BoxIcon from "../../../assets/Icons/svg/boxIcon";
import DocumentListView from "./docsListView";
import useModal from "../../../hooks/useModal";
import DeleteDocumentModal from "../../../components/modals/DeleteDocument";
import ReactPaginate from "react-paginate";
import "./index.css";
const textStyles = mergeStyles({
  fontSize: FontSizes.size24,
});

const retryButtonStyles: IButtonStyles = {
  root: {
    width: "20px",
    margin: "1%",
    alignItems: "center",
  },
};

const SearchComp = (props: any) => {
  const { t } = useTranslation();
  return (
    <HorizontalStack
      style={{ width: "36%", justifyContent: "space-between" }}
    >
      <HorizontalStack style={styles.searchContainer}>
        <SearchIcon />
        <input
          style={{
            width: "300px",
            height: "18px",
            fontFamily: "Poppins",
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "16px",
            
          }}
          onChange={(e) => props.setSearchText(e.target.value)}
          value={props.searchText}
          // onChange={(e)=>setSearchText(e.target.value)}
          placeholder={String(t("search_project_docs"))}
          // disabled
        />
      </HorizontalStack>
      <Stack
        style={{
          backgroundColor: props.listView ? colorPallete.btLightBlue : "white",
          ...styles.listView,
        }}
        title={String(t("list_view"))}
        onClick={() => {
          props.setListView(!props.listView);
        }}
      >
        <ListIcon />
      </Stack>
      <Stack
        style={{
          backgroundColor: !props.listView ? colorPallete.btLightBlue : "white",
          ...styles.boxView,
        }}
        onClick={() => {
          props.setListView(!props.listView);
        }}
        title={String(t("box"))}
      >
        <BoxIcon />
      </Stack>
    </HorizontalStack>
  );
};

export const DocsInProject: FC<any> = (props) => {
  const { t } = useTranslation();
  let { folderName } = useParams();
  let file: string | undefined = folderName;
  const dispatch = useAppDispatch();
  const [left, setLeft] = useState<number>(0);
  let distance = left;
  const documentState = useAppSelector(
    (state: { fetchFilesInFolder: any }) => state.fetchFilesInFolder,
    (a, b) => {
      return a.data.length === b.data.length && a.isLoading === b.isLoading;
    }
  );
  const [view, setView] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [hoverId, setHoverId] = useState<number | undefined>(0);
  const [selectedId, setSelectedId] = useState<number | undefined>(0);
  const [selectedDocument, setSelectedDocument] = useState("");
  const element = document.getElementById("myStack");
  const [rootDocumentId, setRootDocumentId] = useState<number>(0);
  const [versionError, setVersionError] = useState<boolean>(false);
  const [listView, setListView] = useState<boolean>(false);
  const { isModalOpen, showModal, hideModal } = useModal();
  const [deleteRootDocId, setDeleteRootDocId] = useState<number>(0);
  const [deleteDocName, setDeleteDocName] = useState<string>("");
  const [searchText, setSearchText] = useState("");
  const [documents, setDocuments] = useState<any>([]);

  const pageSize = 15;
  const [currentPage, setCurrentPage] = useState(0);

  const handlePageClick = (data: any) => {
    setCurrentPage(data.selected);
  };
  const loaderJSX = (
    <Stack className={mergeStyles(styles.loaderStyles)}>
      <Loader />
    </Stack>
  );

  useEffect(() => {
    if (left && element) {
      element.scrollLeft += left;
    }
  }, [left]);

  useEffect(() => {
    if (searchText?.length) {
      let searchedDocument = documentState.data.filter((document: any) =>
        document?.rootDocumentName
          .toLowerCase()
          .includes(searchText.toLowerCase())
      );
      setDocuments(searchedDocument);
    } else {
      setDocuments(documentState.data);
    }
  }, [searchText, documentState.data]);

  useEffect(() => {
    setSelectedId(documents[0]?.rootDocumentId);
    setSelectedDocument(documents[0]?.rootDocumentName);
    handleFilePath(documents[0]?.rootDocumentId);
  }, [documents]);

  useEffect(() => {
    if (isError) dispatch(getFilesInFolders(props.projectData.projectId));
  }, [isError]);
  useEffect(() => {
    if (versionError) {
      handleFilePath(rootDocumentId);
    }
  }, [versionError]);

  function handleFilePath(rootDocumentId: number) {
    setRootDocumentId(rootDocumentId);
    if (rootDocumentId && rootDocumentId !== 0)
      dispatch(
        getDocumentversions({
          rootDocumentId: rootDocumentId,
        })
      );
  }
  const errorJSX =
    !documentState.isLoading && documentState.error === "Network Error" ? (
      <Stack className={mergeStyles(styles.errorStyles)}>
        <Text className={textStyles}>{t("loader")}</Text>
        <PrimaryButton
          styles={retryButtonStyles}
          onClick={() => setIsError(!isError)}
        >
          {t("retry")}
        </PrimaryButton>
      </Stack>
    ) : (
      !documentState.isLoading &&
      documentState.data &&
      documents.length === 0 && (
        <Stack className={mergeStyles(styles.errorStyles)}>
          <Text className={textStyles}>{t("no_doument_found")}</Text>
        </Stack>
      )
    );

  const Buttons = () => {
    return (
      <>
        <Stack
          style={{
            ...styles.ScrollStyles,
            alignSelf: "flex-start",
            position: "absolute",
          }}
          onClick={() => {
            if (left > 0) {
              let a = 0;
              setLeft((a -= 1200));
            } else {
              setLeft((distance -= 400));
            }
          }}
        >
          {documentState.data && documentState.data.length !== 0 && (
            <img
              src={require("../../../assets/Icons/LeftScroll.png")}
              style={{ cursor: "pointer" }}
              alt=""
            />
          )}
        </Stack>
        <Stack
          style={{
            ...styles.ScrollStyles,
            alignSelf: "flex-end",
            position: "absolute",
            right: 8,
          }}
          onClick={() => {
            if (left < 0) {
              let a = 0;
              setLeft((a += 1200));
            } else {
              setLeft((distance += 400));
            }
          }}
        >
          {documentState.data && documentState.data.length !== 0 && (
            <img
              src={require("../../../assets/Icons/RightScroll.png")}
              style={{ cursor: "pointer" }}
              alt=""
            />
          )}
        </Stack>
      </>
    );
  };
  const docBox = (doc: Document) => {
    return (
      <motion.div
        animate={{
          opacity: 1,
          scale:
            hoverId === doc.rootDocumentId || selectedId === doc.rootDocumentId
              ? 1.05
              : 1,
        }}
        initial={{
          opacity: 0,
        }}
        style={{
          marginTop: "0.5rem",
          border: selectedId === doc.rootDocumentId ? "4px solid #007CFF" : "",
          backgroundColor: "",
          marginLeft: "0.5rem",
          marginRight: "8px",
          marginBottom: "15px",
        }}
        onMouseEnter={() => setHoverId(doc.rootDocumentId)}
        onMouseLeave={() => {
          setHoverId(0);
        }}
        onClick={() => {
          handleFilePath(doc.rootDocumentId);
          setSelectedId(doc.rootDocumentId);
          setSelectedDocument(doc.rootDocumentName);
        }}
        key={doc.documentId}
      >
        <ProjectDocumentCard
          document={doc}
          showModal={showModal}
          deleteDocName={setDeleteDocName}
          deleteRootDocId={setDeleteRootDocId}
        />
      </motion.div>
    );
  };


  return (
    <>
      <div style={{ backgroundColor: "" }}>
        <HorizontalStack style={styles.HeaderStack}>
          <HorizontalStack style={{ height: "100%", width: "50%" }}>
            <Text style={styles.headerStyle}>{t("existing_proj_docs")}</Text>
            <ViewAll
              onClickFunction={() => {
                setView(!view);
              }}
              collapsed={view}
            />
          </HorizontalStack>
          <SearchComp
            searchText={searchText}
            setSearchText={setSearchText}
            listView={listView}
            setListView={setListView}
          />
        </HorizontalStack>
        <Stack
          style={{
            ...styles.outerStack,
            position: "relative",
            justifyContent:
              documentState.error || documentState.isLoading
                ? "center"
                : "flex-start",
            minHeight: "20rem",
            alignItems: "center",
          }}
        >
          {!view ? (
            <>
              {documentState.isLoading && loaderJSX ? (
                loaderJSX
              ) : errorJSX ? (
                errorJSX
              ) : !listView ? (
                <>
                  <Buttons />
                  <HorizontalStack
                    className={mergeStyles(styles.documentContainer)}
                    id="myStack"
                  >
                    {!documentState.isLoading &&
                      documentState.data &&
                      documents.length !== 0 &&
                      documents.map((doc: any) => {
                        return docBox(doc);
                      })}
                  </HorizontalStack>
                </>
              ) : (
                <DocumentListView
                  data={documents}
                  listView={view}
                  handleFilePath={handleFilePath}
                  setSelectedDocument={setSelectedDocument}
                  selectedId={selectedId}
                  setSelectedId={setSelectedId}
                  showModal={showModal}
                  deleteDocName={setDeleteDocName}
                  deleteRootDocId={setDeleteRootDocId}
                />
              )}
            </>
          ) : (
            <>
              {!documentState.isLoading &&
              documentState.data &&
              documents.length !== 0 &&
              !listView ? (
                <div className="container" style={{backgroundColor:"inherit"}}>
                <div className="stack">
                  {documents
                    .slice(currentPage * pageSize, (currentPage + 1) * pageSize)
                    .map((doc: Document, index:any) => {
                      return <div key={index}>{docBox(doc)}</div>;
                    })}
                </div>
                {documents.length > pageSize && (
                  <ReactPaginate
                    previousLabel={'<'}
                    nextLabel={'>'}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={Math.ceil(documents.length / pageSize)}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={1}
                    onPageChange={handlePageClick}
                    containerClassName={'pagination'}
            
                    activeClassName={'active'}
                  />
                )}
              </div>
              ) : (
                <DocumentListView
                  data={documents}
                  listView={view}
                  handleFilePath={handleFilePath}
                  setSelectedDocument={setSelectedDocument}
                  setSelectedId={setSelectedId}
                  selectedId={selectedId}
                  showModal={showModal}
                  deleteDocName={setDeleteDocName}
                  deleteRootDocId={setDeleteRootDocId}
                />
              )}
            </>
          )}
        </Stack>
      </div>
      <VersionsInDoc
        projectData={props.projectData}
        selectedDocument={selectedDocument}
        selectedId={selectedId}
        documentState={documentState}
        versionError={versionError}
        setVersionError={setVersionError}
        refresh={props.refresh}
      />
      <DeleteDocumentModal
        isModalOpen={isModalOpen}
        showModal={showModal}
        hideModal={hideModal}
        documentName={deleteDocName}
        rootDocumentId={deleteRootDocId}
        refresh={props.refresh}
      />
    </>
  );
};

const styles = {
  documentContainer: {
    width: "97%",
    height: "100%",
    alignItems: "center",
    overflowX: "hidden",
    backgroundColor: "",
    marginTop: "1.8%",
    alignSelf: "center",
  },
  expandedDocumentContainer: {
    width: "95%",
    height: "auto",
    alignItems: "flex-start",
    marginRight: "1%",
    paddingBottom: "1%",
    marginLeft: "1.5%",
    marginTop: "1%",
    flexWrap: "wrap",
    display: "flex",
    backgroundColor: "",
  },
  loaderStyles: {
    display: "flex",
    height: "100%",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  headerStyle: {
    fontSize: "18px",
    color: colorPallete.black,
    fontWeight: 500,
    paddingLeft: "0.6%",
    fontFamily: "Poppins",
  },
  errorStyles: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    height: "18rem",
    alignItems: "center",
  },
  HeaderStack: {
    width: "99%",
    alignItems: "center",
    backgroundColor: "",
    paddingTop: "2%",
    paddingRight: "1%",
    paddingLeft: "1%",
    justifyContent: "space-between",
  },
  ScrollStyles: {
    height: "100%",
    alignItems: "center",
    backgroundColor: "",
    justifyContent: "center",
    zIndex: 2,
    marginLeft: "5px",
  },
  outerStack: {
    display: "flex",
    width: "99%",
    alignItems: "center",
    backgroundColor: "",
  },
  searchContainer: {
    height: "36px",
    backgroundColor: "white",
    borderRadius: "4px",
    width: "350px",
    alignItems: "center",
    border: "1px solid #EAEAEA",
    paddingLeft: "12px",
  },
  listView: {
    border: `2px solid ${colorPallete.btLightBlue}`,
    borderRadius: "0.3rem",
    cursor: "pointer",
    padding: "10px 8px 10px 8px",
    alignItems: "center",
    height: "36px",
    width: "36.049px",
    justifyContent: "center",
  },
  boxView: {
    border: `2px solid ${colorPallete.btLightBlue}`,
    borderRadius: "0.3rem",
    cursor: "pointer",
    padding: "7px",
    alignItems: "center",
    height: "36px",
    width: "36.049px",
    justifyContent: "center",
  },
};
