import { FC, useEffect } from "react";
import DragAndDrop from "../../../components/DragAndDrop/DragAndDrop";
import { HorizontalStack, VerticalStack } from "../../../components/Stack";
import {
  VerticalDivider,
  IVerticalDividerStyles,
  IStackStyles,
  IStackTokens,
  Text,
  mergeStyles,
  Stack,
} from "@fluentui/react";
import { PrecedentDocuments } from "../index";
import { ViewAll } from "../../../components/ViewAll";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useIsSignedIn from "../../../hooks/useIsSignedIn";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import "./index.css";
import { colorPallete } from "../../../assets/color";

const verticalDividerStyles: IVerticalDividerStyles = {
  divider: {
    height: "full",
    width: "1px",
  },
  wrapper: {
    backgroundColor: "rgba(93, 109, 249, 0.20)",
    height: "85%",
    alignSelf: "center",
  },
};

const styles = {
  IconStyle: {
    textAlign: "center",
    fontSize: "120px",
    color: colorPallete.btBlue,
    margintop: "4%",
  },
  textStyles: {
    fontSize: "20px",
    whiteSpace: "nowrap",
    fontFamily:"Poppins"
  },
  IconDivStyle: {
    alignItems: "flex-end",
    justifyContent: "center",
    height: "80%",
    width: "77%",
  },
  header: {
    color: colorPallete.black,
    fontSize: "20px",
    width: "100%",
    fontFamily:"Poppins"
  },
  PrecedentFilesTitleStack: {
    alignItems: "flex-start",
    justifyContent: "space-between",
    backgroundColor: "",
    paddingBottom: "0.4%",
    // minHeight:'20%',
  },
  PrecedentFilesStack: {
    width: "65%",
    height: "100%",
    backgroundColor: "",
    // padding:'1%',
  },
  precdentDocuments: {
    backgroundColor: "",
  },
};

const stackTokens: IStackTokens = {
  childrenGap: "2%",
};

export const DocumentStack: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  let isSignedIn = useIsSignedIn();
  function click() {
    navigate("precedent");
  }
  const functionalityState = useSelector(
    (state: RootState) => state.DocumentStack.value
  );

  useEffect(() => {}, [functionalityState]);

  const stackStyles: IStackStyles = {
    root: {
      backgroundColor:
        functionalityState === "draft" ? colorPallete.white : "#a0d8b9 ",
      padding: "1.5%",
      borderRadius: "8px",
      height: "285px",
      width: "98.5%",
      marginBottom: "1%",
      display: "flex",
      marginTop: "1%",
    },
  };
  return (
    <HorizontalStack
      className="DocStack"
      // horizontalAlign="space-between"
      styles={stackStyles}
      tokens={stackTokens}
    >
      {isSignedIn[0] ? (
        <>
          <div className="DragAndDrop">
            <DragAndDrop
              dropZoneText={
                functionalityState === "draft" ? (
                  <Text style={styles.header}>{t("draft_document")}</Text>
                ) : (
                  <Text style={styles.header}>{t("send_doc_for_review")}</Text>
                )
              }
            />
          </div>
          <Stack
            style={{
              height: "100%",
              justifyContent: "center",
              marginTop: "1.32%",
            }}
          >
            <VerticalDivider styles={verticalDividerStyles} />
          </Stack>
        </>
      ) : null}
      {functionalityState === "draft" ? (
        <VerticalStack className="PrecedentStack">
          <HorizontalStack style={styles.PrecedentFilesTitleStack}>
            <Text
              className={mergeStyles(styles.textStyles)}
              title={String(t("precedent"))}
            >
              {t("precedent_files")}
            </Text>
            {isSignedIn[0] && <ViewAll onClickFunction={click} />}
          </HorizontalStack>
          {isSignedIn[0] ? (
            <Stack style={styles.precdentDocuments}>
              <PrecedentDocuments />
            </Stack>
          ) : (
            <Stack style={styles.IconDivStyle}>
              <Text className={mergeStyles(styles.textStyles)}>
                {t("login_to_view_files")}
              </Text>
            </Stack>
          )}
        </VerticalStack>
      ) : (
        <Text className={mergeStyles(styles.textStyles)}>
          {t("documents_review")}
        </Text>
      )}
    </HorizontalStack>
  );
};
