import React, { useState, MouseEvent, useRef, useEffect } from "react";
import { Stack, Text } from "@fluentui/react";
import { HorizontalStack, VerticalStack } from "../Stack";
import "./dropdown.css";
import ArrowUpIcon from "../../assets/Icons/svg/arrowUpIcon";
import ArrowDownIcon from "../../assets/Icons/svg/arrowDown";
import SendIcon from "../../assets/Icons/svg/sendIcon";
import ReceivedIcon from "../../assets/Icons/svg/receivedIcon";
import DocumentIcon from "../../assets/Icons/svg/documentIcon";
import { getFormattedDateTime } from "../../utils";
import { useTranslation } from "react-i18next";

interface DropdownProps {
  options: string[] | any[];
  title: string;
  data: any;
  error?: any;
  doc?: boolean;
  folderName?: string;
  documentId?: any;
  documentItem?: any;
  setDocumentItem?: any;
}

const CustomDropdown: React.FC<DropdownProps> = ({
  options,
  title,
  data,
  error,
  doc,
  folderName,
  documentId,
  setDocumentItem,
  documentItem,
}) => {
  const [filteredOptions, setFilteredOptions] = useState<any[]>([]);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [dropdownItem, setDropdownItem] = useState<string | any>("");
  const ref = useRef<HTMLDivElement | null>(null);
  const { t } = useTranslation();
  const handleDropdownIconClick = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event: Event) => {
    if (ref.current && !ref.current.contains(event.target as HTMLDivElement)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [ref]);

  useEffect(() => {
    if (doc && folderName && folderName?.length > 0) {
      setDropdownItem(null);
      data("");
    }
  }, [folderName]);
  function handleClick(project: any, i?: any) {
    if (doc) {
      filteredOptions.length > 0 &&
        setDropdownItem(
          filteredOptions.filter(function (i) {
            return i === project;
          })[0]
        );
      filteredOptions.length > 0 &&
        data(
          filteredOptions
            .filter(function (i) {
              return i.documentName === project.documentName;
            })[0]
            .documentName.split(".docx")[0]
        );
      filteredOptions.length > 0 &&
        documentId(
          filteredOptions.filter(function (i) {
            return i === project;
          })[0].rootDocumentId
        );
      filteredOptions.length > 0 &&
        setDocumentItem(
          filteredOptions.filter(function (i) {
            return i === project;
          })[0]
        );
    } else {
      filteredOptions.length > 0 &&
        setDropdownItem(
          filteredOptions.filter(function (i) {
            return i === project;
          })[0]
        );
      filteredOptions.length > 0 &&
        data(
          filteredOptions.filter(function (i) {
            return i === project;
          })[0]
        );
    }
    error && error(false);
    setIsOpen(false);
  }

  function getIcon(fileType: string) {
    if (fileType == "sent") {
      return <SendIcon />;
    } else if (fileType == "received") {
      return <ReceivedIcon />;
    } else {
      return <DocumentIcon />;
    }
  }

  return (
    <div
      style={{
        ...styles.container,
        flexDirection: "row",
        position: "relative",
      }}
      ref={ref}
    >
      {isOpen ? (
        <HorizontalStack
          style={{
            ...styles.textContainer,
            paddingRight: "14px",
          }}
          onClick={handleDropdownIconClick}
        >
          <input
            type="text"
            placeholder={title}
            autoFocus
            onChange={(e) => {
              if (doc) {
                setFilteredOptions(
                  options.filter(function (i) {
                    return i.documentName
                      .toLowerCase()
                      .includes(e.target.value.toLowerCase());
                  })
                );
              } else {
                setFilteredOptions(
                  options.filter(function (i) {
                    return i
                      .toLowerCase()
                      .includes(e.target.value.toLowerCase());
                  })
                );
              }
            }}
            style={{
              ...styles.text,
              marginLeft: "14px",
              backgroundColor: "",
              width: "60%",
            }}
          />
          <HorizontalStack style={{ alignItems: "center" }}>
            {doc ? <Text className="documentFamily">{folderName}</Text> : null}
            <ArrowUpIcon />
          </HorizontalStack>
        </HorizontalStack>
      ) : (
        <HorizontalStack
          onClick={() => {
            setIsOpen(!isOpen);
            setFilteredOptions(options);
          }}
          style={{ ...styles.textContainer, padding: "10px 14px" }}
          title={
            doc && String(dropdownItem).length > 0
              ? dropdownItem?.documentName.split(".docx")[0]
              : dropdownItem
          }
        >
          {doc && documentItem !== null ? (
            <>
              <HorizontalStack className="documentName">
                {getIcon(documentItem.fileType)}
                <Text style={{ ...styles.text, whiteSpace: "nowrap" }}>
                  {documentItem.documentName.split(".docx")[0]}
                </Text>
                <Text className="versionTextSelect">
                  V{documentItem.documentVersion}&nbsp;({t("most_recent")})
                </Text>
              </HorizontalStack>
              <Text className="dateTime">
                {getFormattedDateTime(String(documentItem.lastUpdatedDate))[0]}
                &nbsp;|&nbsp;
                {getFormattedDateTime(String(documentItem.lastUpdatedDate))[1]}
              </Text>
            </>
          ) : doc &&
            !documentItem &&
            dropdownItem !== null &&
            String(dropdownItem).length > 0 ? (
            <>
              <HorizontalStack className="documentName">
                {getIcon(dropdownItem.fileType)}
                <Text style={{ ...styles.text, whiteSpace: "nowrap" }}>
                  {dropdownItem.documentName.split(".docx")[0]}
                </Text>
                <Text className="versionTextSelect">
                  V{dropdownItem.documentVersion}&nbsp;({t("most_recent")})
                </Text>
              </HorizontalStack>
              <Text className="dateTime">
                {getFormattedDateTime(String(dropdownItem.lastUpdatedDate))[0]}
                &nbsp;|&nbsp;
                {getFormattedDateTime(String(dropdownItem.lastUpdatedDate))[1]}
              </Text>
            </>
          ) : (
            <>
              {folderName && !doc ? (
                <Text style={styles.text}>{folderName}</Text>
              ) : dropdownItem ? (
                <Text style={styles.text}>{dropdownItem}</Text>
              ) : folderName && doc ? (
                <HorizontalStack
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Text style={styles.text}>{title}</Text>
                  <Text className="documentFamily">{folderName}</Text>
                </HorizontalStack>
              ) : (
                <Text style={styles.text}>{title}</Text>
              )}
            </>
          )}
          <ArrowDownIcon />
        </HorizontalStack>
      )}
      {isOpen && (
        <VerticalStack
          style={{
            ...styles.itemContainer,
            position: "absolute",
            maxHeight: "144px",
          }}
        >
          <VerticalStack
            style={{
              overflowY: "scroll",
              maxHeight: "144px",
              width: "99.8%",
              borderRadius: "8px",
              margin: "2px 0px",
            }}
          >
            {filteredOptions.map((item: any, index) => {
              if (doc) {
                return (
                  <HorizontalStack
                    key={item}
                    onClick={(e) => {
                      handleClick(item);
                    }}
                    className="dropdownCard"
                  >
                    <HorizontalStack className="documentName">
                      {getIcon(item.fileType)}
                      <Text style={{ ...styles.text, whiteSpace: "nowrap" }}>
                        {item.documentName.split(".docx")[0]}
                      </Text>
                      <Text className="versionText">
                        V{item.documentVersion}&nbsp;({t("most_recent")})
                      </Text>
                    </HorizontalStack>
                    <Text className="dateTime">
                      {getFormattedDateTime(String(item.lastUpdatedDate))[0]}
                      &nbsp;|&nbsp;
                      {getFormattedDateTime(String(item.lastUpdatedDate))[1]}
                    </Text>
                  </HorizontalStack>
                );
              } else {
                return (
                  <HorizontalStack
                    key={item}
                    onClick={(e) => {
                      handleClick(item);
                    }}
                    className="dropdownCard"
                  >
                    <Text style={{ ...styles.text, lineHeight: "24px" }}>
                      {item}
                    </Text>
                    <Stack className="tick">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M16.6673 5L7.50065 14.1667L3.33398 10"
                          stroke="#18468F"
                          stroke-width="1.66667"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </Stack>
                  </HorizontalStack>
                );
              }
            })}
          </VerticalStack>
        </VerticalStack>
      )}
    </div>
  );
};

export default CustomDropdown;

const styles = {
  container: {
    width: "100%",
    height: "40px",
    borderRadius: "5px",
    border: "1px solid #E8ECF4",
    background: "#F8F8F8",
    display: "flex",
    alignItems: "center",
  },
  text: {
    color: "#101828",
    fontFamily: "Poppins",
    fontSize: "14px",
    lineHeight: "16px",
    fontWeight: "400",
    textOverflow: "ellipsis",
    overflow: "hidden",
    maxWidth: "65%",
  },
  itemContainer: {
    zIndex: 2,
    marginTop: "20px",
    top: 23,
    alignItems: "flex-start",
    width: "100%",
    left: 0,
    border: "1px solid #F2F4F7",
    borderRadius: "8px",
    backgroundColor: "white",
  },
  textContainer: {
    width: "100%",
    cursor: "pointer",
    height: "100%",
    alignItems: "center",
    justifyContent: "space-between",
  },
};
