import { BtFileObject, response } from "./../models/index";
import { ApiClient } from "../network/ApiClient";
import { Document } from "../models";
import { ApiEndpoints } from "../network/ApiEndpoints";
import { Providers } from "@microsoft/mgt-element";

class DocumentService {
  //Fetching top 2 files inside a folder for Project Card
  public async getAll(
    folderName: string
  ): Promise<{ code: number; message: string; data: Document[] }> {
    const encodedFolderName = encodeURIComponent(folderName);
    let response = await ApiClient.get<any>(
      ApiEndpoints.getDocumentsInFolder(),
      {
        projectId: encodedFolderName
      },
      {}
    );
    return Promise.resolve(response);
  }

  //Fetching all documents inside a folder
  public async getAllDocs(folderName: string): Promise<Document[]> {
    const encodedFolderName = encodeURIComponent(folderName);
    let response = await ApiClient.get<any>(
      ApiEndpoints.getDocumentsInFolder(),
      {
        projectId:encodedFolderName
      },
      {}
    );
    try {
      return await Promise.resolve(response.data);
    } catch (e) {
      return Promise.reject("No documents found");
    }
  }

  public async upload(
    projectId: number,
    UploadedFiles: BtFileObject[]
  ): Promise<any> {
    const formData = new FormData();
    UploadedFiles.forEach((file) => {
      let fileName =
        file.fileName?.split(".docx")[0].split(".DOCX")[0].split(".DOCx")[0] +
        ".docx";

      formData.append("UploadedFiles", file.file);
      formData.append("fileNames", fileName);
    });
    const response: response = await ApiClient.post(
      ApiEndpoints.uploadMultiFile(),
      formData,
      {
        projectId: projectId,
      },
      {
        "Content-Type": "multipart/form-data",
      }
    );
    return Promise.resolve(response);
  }

  public async uploadNewVersion(
    UploadedFiles: BtFileObject[],
    rootDocumentId:number,
  ): Promise<any> {
    const formData = new FormData();
    UploadedFiles.forEach((file) => {
      formData.append("UploadedFile", file.file);
    });
    const response: response = await ApiClient.post(
      ApiEndpoints.uploadNewVersion(),
      formData,
      {
        rootDocumentId:rootDocumentId
      },
      {
        "Content-Type": "multipart/form-data",
      }
    );
    return Promise.resolve(response);
  }

  public async update(id: number, payload: Document): Promise<Document> {
    return Promise.resolve({ ...payload, documentId: id } as Document);
  }

  public async delete(id: string): Promise<void> {
    return ApiClient.delete("api-endpoint", { documentId: id });
  }

  public async grantUserPermission(
    documentUrl: string | undefined,
    type: string | null
  ): Promise<any> {
    let response = await ApiClient.get(
      ApiEndpoints.grantUserPermission(),
      {
        documentUrl: documentUrl,
        type: type,
      },
      {}
    );
    return response;
  }
}

const documentService = new DocumentService();
export { documentService as DocumentService };
