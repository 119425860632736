import { Stack, Text, mergeStyles } from "@fluentui/react";
import { FC } from "react";
import { HorizontalStack, VerticalStack } from "../../Stack";
import { BackButton } from "../../BackButton";
import { ProjectInCard } from "../ProjectInCard";
import { UserModel } from "../../../models";
import { useNavigate, useParams } from "react-router-dom";
import { colorPallete } from "../../../assets/color";
import "./index.css";
import { useTranslation } from "react-i18next";

const styles = {
  counterPartyLogo: {
    height: "1.6rem",
    minWidth: "1.6rem",
    backgroundColor: colorPallete.btBlue,
    borderRadius: "16px",
    alignItems: "center",
    justifyContent: "center",
  },
  membersLogo: {
    height: "16px",
    minWidth: "16px",
    borderRadius: "16px",
    alignItems: "center",
    justifyContent: "center",
  },
  Initial: {
    fontSize: "10px",
    lineHeight: "16px",
    color: "#F4F4F4",
    fontFamily: "Poppins",
  },
  text: {
    paddingLeft: "5%",
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: "400",
    color: colorPallete.black1,
    paddingRight: "2%",
    width: "max-content",
    fontFamily: "Poppins",
  },

  counterParty: {
    paddingLeft: "5%",
    fontSize: "14px",
    lineHeight: "16px",
    fontWeight: "400",
    color: colorPallete.black1,
    paddingRight: "2%",
    width: "max-content",
    fontFamily: "Poppins",
  },
  counterPartyObject: {
    marginLeft: "1%",
    marginRight: "0.25%",
    marginTop: "0.5%",
    border: "1px solid #D0D5DD",
    borderRadius: "4px",
    padding: "4px 10px 4px 4px",
    overflow: "hidden",
    height: "40px",
    backgroundColor: "",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  counterPartiesContainer: {
    display: "flex",
    width: "100%",
    overflow: "auto",
    height: "100%",
    backgroundColor: "",
  },
  descriptionStack: {
    width: "100%",
    alignSelf: "flex-end",
    marginBottom: "1%",
    padding: "1% 0%",
  },
  horizontalLine: {
    width: "100%",
    height: "2px",
    backgroundColor: colorPallete.grey2,
    alignSelf: "center",
  },
  loader: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    alignItems: "center",
    paddingTop: "10%",
  },
  bodyStyles: {
    fontSize: "15px",
    color: colorPallete.grey1,
    alignSelf: "flex-end",
    fontFamily: "Poppins",
  },
  projectOwnerStack: {
    width: "100%",
    alignSelf: "flex-end",
    marginTop: "1%",
    marginBottom: "1%",
  },
  textHead: {
    marginTop: "1%",
    fontWeight: 500,
    fontSize: "15px",
    minWidth: "9.5rem",
    width: "fit-content",
    color: colorPallete.grey1,
    fontFamily: "Poppins",
    lineHeightL: "20px",
  },
  textTail: {
    fontWeight: 500,
    fontSize: "15px",
    marginTop: "1%",
    fontFamily: "Poppins",
  },
  projectOwnerText: {
    marginLeft: "1%",
    marginRight: "1%",
    marginTop: "0.5%",
    padding: "0.5%",
    borderRadius: "1rem",
    fontFamily: "Poppins",
  },
  memberText: {
    width: "100%",
    alignSelf: "flex-end",
    justifyContent: "center",
    marginTop: "0.25%",
    fontFamily: "Poppins",
  },

  membersContainer: {
    display: "flex",
    width: "100%",
    overflow: "auto",
    height: "100%",
  },
  membersOfProject: {
    border: "1px solid #D0D5DD",
    borderRadius: "4px",
    padding: "4px 4px 4px 5px",
    overflow: "hidden",
    height: "45px",
    backgroundColor: "",
    marginRight: "10px",
    marginBottom: "10px",
  },
};

const colors = [
  "#101828",
  "#007CFF",
  "#750b1c",
  "#18468F",
  "#e3008c",
  "#5c2e91",
  "#986f0b",
];

const getColor = (name: string) => {
  return colors[name?.length % colors.length];
};

export const ProjectDetailsCard: FC<any> = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  function click() {
    navigate("/home");
  }

  const membersJsx = (user: UserModel) => {
    const domain = user.email?.split("@")[1];
    const organization = domain?.split(".com")[0];
    const orgName =
      organization?.charAt(0).toUpperCase() + organization?.slice(1);
    return (
      <Stack style={styles.membersOfProject} title={user.name} key={user.id}>
        <VerticalStack
          style={{ justifyContent: "space-evenly", width: "fit-content" }}
        >
          <HorizontalStack
            style={{ alignItems: "center", backgroundColor: "" }}
          >
            <Stack
              style={{
                ...styles.membersLogo,
                backgroundColor: getColor(user.name?.trim()),
              }}
            >
              <Text style={styles.Initial}>
                {user.name?.trim().charAt(0).toUpperCase()}
              </Text>
            </Stack>
            <Text
              style={{
                ...styles.text,
                whiteSpace: "nowrap",
                fontFamily: "Poppins",
                paddingRight: "28px",
              }}
            >
              {user.name?.trim()}
            </Text>
          </HorizontalStack>
          <Text
            style={{
              fontSize: "12px",
              color: "#707070",
              lineHeight: "16px",
              padding: "0px 23px",
              fontFamily: "Poppins",
            }}
          >
            {orgName}
          </Text>
        </VerticalStack>
      </Stack>
    );
  };

  const projectNameStyles = mergeStyles({
    fontSize: "24px",
    fontWeight: 500,
    fontFamily: "Poppins",
  });
  return (
    <>
      <Stack className="ProjectContainer">
        <VerticalStack className="ProjectCardStack">
          {props.projectData && (
            <ProjectInCard project={props.projectData} sender="AllProjects" />
          )}
        </VerticalStack>
        <div className="ProjectDetails">
          <VerticalStack
            style={{ justifyContent: "flex-start", alignItems: "flex-start" }}
          >
            <BackButton onClickFunction={click} />
            <HorizontalStack style={{ width: "100%" }}>
              <Text className={projectNameStyles}>
                {props.projectData?.projectName}
              </Text>
            </HorizontalStack>
            <HorizontalStack style={styles.descriptionStack}>
              <Text style={styles.bodyStyles}>
                {props.projectData?.description}
              </Text>
            </HorizontalStack>
          </VerticalStack>

          <Stack style={styles.horizontalLine}></Stack>
          <HorizontalStack
            style={styles.projectOwnerStack}
            title={props.projectData?.projectOwner}
          >
            <Text style={styles.textHead}>{t("project_owners")}:</Text>
            <Stack style={styles.projectOwnerText}>
              <HorizontalStack>
                {/* <Stack
                  style={{
                    ...styles.membersLogo,
                    backgroundColor: getColor(
                      props.projectData?.projectOwner?.trim()
                    ),
                  }}
                >
                  <Text style={styles.Initial}>
                    {props.projectData?.projectOwner
                      ?.trim()
                      .charAt(0)
                      .toUpperCase()}
                  </Text>
                </Stack> */}
                <Text
                  style={{
                    ...styles.text,
                    whiteSpace: "nowrap",
                    paddingTop: "4%",
                    fontWeight: 600,
                  }}
                >
                  {props.projectData?.projectOwner?.split("@")[0].toUpperCase()}
                </Text>
              </HorizontalStack>
            </Stack>
          </HorizontalStack>
          {/* <HorizontalStack
            style={styles.projectOwnerStack}
            title={props.projectData?.projectOwner}
          >
            <Text style={styles.textHead}>{t("counter_party")}</Text>
            <Text style={styles.textTail}>:</Text>
            <Stack
              style={{
                ...styles.counterPartiesContainer,
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              {props.projectData?.counterParties
                ?.split(",")
                .map((name: any) => {
                  return (
                    <HorizontalStack
                      style={styles.counterPartyObject}
                      key={name}
                    >
                      <Stack style={styles.counterPartyLogo}>
                        <Text style={styles.Initial}>
                          {name?.trim().charAt(0)}
                        </Text>
                      </Stack>
                      <Text
                        style={{ ...styles.counterParty, whiteSpace: "nowrap" }}
                      >
                        {name?.trim()}
                      </Text>
                    </HorizontalStack>
                  );
                })}
            </Stack>
          </HorizontalStack> */}
          <HorizontalStack style={styles.memberText}>
            <Text style={styles.textHead}>{t("working_group")}:</Text>
            {/* <Text style={styles.textTail}>:</Text> */}
            <Stack
              style={{
                ...styles.membersContainer,
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              {props.projectData?.membersOfProject &&
                props.projectData.membersOfProject.map((user: UserModel) => {
                  return membersJsx(user);
                })}
            </Stack>
          </HorizontalStack>
        </div>
      </Stack>
    </>
  );
};
