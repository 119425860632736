import React from "react";

export default function Draft() {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="11"
        height="16"
        viewBox="0 0 11 16"
        fill="none"
      >
        <path
          d="M6.64986 1.19922H1.54794C1.22907 1.19922 0.910201 1.33527 0.655105 1.60737C0.400009 1.81145 0.272461 2.15158 0.272461 2.55973V13.4438C0.272461 13.784 0.400009 14.1241 0.655105 14.3962C0.910201 14.6683 1.22907 14.8043 1.54794 14.8043H9.20082C9.51969 14.8043 9.83856 14.6683 10.0937 14.3962C10.3488 14.1241 10.4763 13.784 10.4763 13.4438V5.28076M6.64986 1.19922L10.4763 5.28076L6.64986 1.19922ZM6.64986 1.19922V5.28076H10.4763"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.469287 1.40617C0.770258 1.092 1.15403 0.925781 1.54758 0.925781H6.6495C6.72472 0.925781 6.79657 0.956912 6.84801 1.01178L10.6745 5.09332C10.7217 5.14375 10.748 5.21029 10.748 5.27942V13.4425C10.748 13.8515 10.5944 14.2582 10.2918 14.581C9.98893 14.904 9.59972 15.0751 9.20046 15.0751H1.54758C1.14832 15.0751 0.759122 14.904 0.456238 14.581C0.153697 14.2582 0 13.8515 0 13.4425V2.5584C0 2.10577 0.139814 1.67926 0.469287 1.40617ZM1.54758 1.46999C1.3091 1.46999 1.06056 1.57101 0.853255 1.79214C0.844387 1.8016 0.834853 1.81041 0.824728 1.81851C0.655114 1.9542 0.544205 2.20174 0.544205 2.5584V13.4425C0.544205 13.7137 0.645604 13.9873 0.853255 14.2087C1.06056 14.4299 1.3091 14.5309 1.54758 14.5309H9.20046C9.43894 14.5309 9.68748 14.4299 9.89479 14.2087C10.1024 13.9873 10.2038 13.7137 10.2038 13.4425V5.55152H6.6495C6.49923 5.55152 6.3774 5.4297 6.3774 5.27942V1.46999H1.54758ZM6.92161 1.88597L9.84787 5.00732H6.92161V1.88597Z"
          fill="#18468F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.7207 9.36195C2.7207 9.21167 2.84253 9.08984 2.99281 9.08984H7.7546C7.90488 9.08984 8.0267 9.21167 8.0267 9.36195C8.0267 9.51222 7.90488 9.63405 7.7546 9.63405H2.99281C2.84253 9.63405 2.7207 9.51222 2.7207 9.36195Z"
          fill="#18468F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.7207 11.401C2.7207 11.2507 2.84253 11.1289 2.99281 11.1289H7.7546C7.90488 11.1289 8.0267 11.2507 8.0267 11.401C8.0267 11.5513 7.90488 11.6731 7.7546 11.6731H2.99281C2.84253 11.6731 2.7207 11.5513 2.7207 11.401Z"
          fill="#18468F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.7207 7.31898C2.7207 7.1687 2.84253 7.04688 2.99281 7.04688H4.35332C4.5036 7.04688 4.62542 7.1687 4.62542 7.31898C4.62542 7.46926 4.5036 7.59108 4.35332 7.59108H2.99281C2.84253 7.59108 2.7207 7.46926 2.7207 7.31898Z"
          fill="#18468F"
        />
      </svg>
    </div>
  );
}
