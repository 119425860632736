import * as React from "react";
import {
  HoverCard,
  IHoverCard,
  IPlainCardProps,
  HoverCardType,
  ThemeProvider,
  Text,
  mergeStyles,
  Link,
} from "@fluentui/react";
import { HorizontalStack, VerticalStack } from "../../Stack";
import { colorPallete } from "../../../assets/color";
import { UserModel } from "../../../models";
import { useTranslation } from "react-i18next";
import useModal from "../../../hooks/useModal";
import { EmailTemplateModal } from "../../modals/EmailTemplateModal";

interface HoverCardProps {
  notes: string | undefined;
  fileType: string | undefined;
  sharedBy: UserModel | undefined;
  sharedTo: UserModel[] | undefined;
  recepientsNames: React.ReactElement<any, any>;
  projectName:string |undefined; 
}

const onCardHide = (): void => {};

const styles = {
  Others: {
    fontSize: "14px",
    borderRadius: "8px",
    minWidth: "1px",
    maxWidth: "fit-content",
    color: "rgb(6 42 108)",
    cursor: "pointer",
    fontWeight: 500,
 
  },
  sentToList: {
    marginTop: "1%",
    display: "inline-block",
    height: "100%",
    width: "90%",

  },
  firstEmailInCard: {
    textDecorationLine: "underline",
    fontWeight: 500,
    color: "#18468F",
    fontSize: "12px",
    lineHeight: "18px",
    fontFamily:"Poppins"

  },
  card: {
    width: "328px",
    height: "max-content",
    padding: "10px",
    bottom: "70%",
    borderRadius: "8px",
 

  },
  sharedBy: {
    width: "324px",
    height: "18px",
    fontSize: "12px",
    lineHeight:"18px",
    fontWeight: 500,
    fontFamily:"Poppins",
  },
  sharedToStack: {
    gap: "2%",
    justifyContent: "flex-start",
    width: "98%",
    paddingTop: "2px",
    alignItems:"center"
  },
  sharedTo: {
    alignSelf: "flex-end",
    fontSize: "12px",
    lineHeight:"18px",
    fontWeight: 500,
    fontFamily:"Poppins",
    color: colorPallete.black,
    width:"fit-content",
 
 
  },

  sharedToList: {
   
    fontSize: "12px",
    lineHeight:"18px",
    fontWeight: 500,
    fontFamily:"Poppins",
    color: colorPallete.black,
    width:"80%"
  },
};
const notesStyles = mergeStyles({
  fontWeight: 400,
  fontSize: "12px",
  fontFamily:"Poppins",
  color: colorPallete.grey,
  lineHeight: "18px",
  display: "-webkit-box",
  WebkitBoxOrient: "vertical",
  overflow: "hidden",
  textOverflow: "ellipsis",
})

const RecipientHoverCard: React.FC<{ recipients: UserModel[] }> = ({
  recipients,
}) => {
    const { t } = useTranslation();

  const plainCardProps: IPlainCardProps = {
    onRenderPlainCard: () => (
      <div style={{ padding: "10px", backgroundColor: colorPallete.white }}>
        <ul>
          {recipients.map((recipient) => (
            <li key={recipient.id}>{recipient.email}</li>
          ))}
        </ul>
      </div>
    ),
  };

  return (
    <HoverCard type={HoverCardType.plain} plainCardProps={plainCardProps}>
      <div style={styles.Others}>
      { `,+${recipients.length} `}
      {t("people")}
      </div>
    </HoverCard>
  );
};
interface Props1 {
  recipients: UserModel[];
}
const RenderPersonEmails: React.FC<Props1> = (props) => {
  const { recipients } = props;
  const remainingCount = recipients.length - 1;

  return recipients.length ? (
    <div style={{ ...styles.sentToList, wordWrap: "break-word" }}>
      <HorizontalStack style={{ width: "100%", flexWrap: "wrap" }}>
        <Text style={styles.firstEmailInCard}>{recipients[0]?.email}</Text>
        {remainingCount > 0 && (
          <RecipientHoverCard recipients={recipients.slice(1)} />
        )}
      </HorizontalStack>
    </div>
  ) : (
    <></>
  );
};

export const EmailNotesHoverCard: React.FunctionComponent<HoverCardProps> = (
  props
) => {
  const hoverCard = React.useRef<IHoverCard>(null);
  const { t } = useTranslation();
  const { isModalOpen, showModal, hideModal } = useModal();

  const onRenderPlainCard = (): JSX.Element => {
    return (
      <div style={styles.card}>
        <HorizontalStack
          style={{ width: "304px", height: "max-content", backgroundColor: "",gap:'8px' }}
        >
           <Text
            className={notesStyles}
            style={{ WebkitLineClamp: 2 }}
          >
            {props.notes!}
          </Text>
          <Link style={{fontSize:"12px",fontFamily:"Poppins",lineHeight:"18px",fontWeight:400}} onClick={showModal} >
            {t("read_more")}
          </Link>
        </HorizontalStack>

        {props.fileType === "received" && props.sharedBy && (
          <VerticalStack style={{ gap: 4, paddingTop: "6px" }}>
            <Text
              style={{
                ...styles.sharedBy,
                color: colorPallete.black,
              }}
            >
              {t("shared_by")} {props.sharedBy?.name}
            </Text>
            <Text
              style={{
                ...styles.sharedBy,
                color: colorPallete.btBlue,
              }}
            >
              {props.sharedBy?.email}
            </Text>
          </VerticalStack>
        )}
        {props.fileType === "sent" && props.sharedTo && (
          <VerticalStack style={{gap:"4px" }}>
            <HorizontalStack style={styles.sharedToStack}>
              <Text style={{...styles.sharedTo,   whiteSpace:"nowrap"}}>{t("shared_to")}</Text>
              <Text style={styles.sharedToList}>{props.recepientsNames}</Text>
            </HorizontalStack>

            <Text style={{ backgroundColor: ""  }}>
              <RenderPersonEmails recipients={props.sharedTo} />
            </Text>
          </VerticalStack>
        )}
      </div>
    );
  };

  const plainCardProps: IPlainCardProps = {
    onRenderPlainCard: onRenderPlainCard,
  };

  return (
    <>
      {props.fileType === "received" && (
        <ThemeProvider>
          <HoverCard
            type={HoverCardType.plain}
            plainCardProps={plainCardProps}
            componentRef={hoverCard}
            onCardHide={onCardHide}
            style={{borderRadius:"8px",width:"328px"}}
          >
            <div style={{ backgroundColor: "rgba(223, 230, 239, 0.55)" }}>
              <img src={require("../../../assets/Icons/Envelop.png")} />
            </div>
          </HoverCard>
        </ThemeProvider>
      )}

      {props.fileType === "sent" && (
        <ThemeProvider>
          <HoverCard
            type={HoverCardType.plain}
            plainCardProps={plainCardProps}
            componentRef={hoverCard}
            onCardHide={onCardHide}
            style={{borderRadius:"8px",width:"328px"}}
          >
            <div style={{ backgroundColor: "rgba(223, 230, 239, 0.55)" }}>
              <img src={require("../../../assets/Icons/airplane.png")} />
            </div>
          </HoverCard>
        </ThemeProvider>
      )}
<EmailTemplateModal   
      isModalOpen={isModalOpen}
      showModal={showModal}
      hideModal={hideModal}
      sharedBy={props.sharedBy}
      sharedTo={props.sharedTo}
      projectName={props.projectName}
      emailNotes={props.notes}
/>
      
    </>
  );
};
