import { FC } from "react";
import { PageTitle } from "../../components/PageTitle";
import { useTranslation } from "react-i18next";
import { AllProjectData } from "../../modules/Projects/AllProjectData";
import { HorizontalStack, VerticalStack } from "../../components/Stack";
import { Stack } from "@fluentui/react";
import SearchBar from "../../components/SearchBox";
import FadeTransition from "../../components/FadeTransition";

const AllProjects: FC = () => {
  const { t } = useTranslation();
  return (
    <FadeTransition>
    <VerticalStack>
      <HorizontalStack style={{width:"100%", justifyContent:'space-between', height:'135px', alignItems:'flex-end', paddingTop:"10px"}}>
        <Stack style={{width:"60%",height:'100%'}}>
      <PageTitle
        pageTitle={t("all_projects")}
        search={t("search_project")}
        project={false}
      />
        </Stack>
        <Stack
          style={{
            backgroundColor: "",
            alignItems: "flex-end",
            width: "40%",
            justifyContent: "center",
            marginRight:"2%",
            height:'78%'
          }}
        >
          <SearchBar placeholder={t("search_project")}/>
        </Stack>
      </HorizontalStack>
      <div style={{ width: "100%" }}>
        <AllProjectData />
      </div>
    </VerticalStack>
    </FadeTransition>
  );
};

export default AllProjects;
