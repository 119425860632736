import {
  FontSizes,
  IButtonStyles,
  PrimaryButton,
  Stack,
  Text,
  mergeStyles,
} from "@fluentui/react";
import { FC, useEffect, useState } from "react";
import { HorizontalStack, VerticalStack } from "../../../components/Stack";
import { DocumentVersionInCard } from "../../../components/Cards/DocumentVersionInCard";
import Loader from "../../../components/Loader";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import { useTranslation } from "react-i18next";
import { ViewAll } from "../../../components/ViewAll";
import { Document } from "../../../models";
import { colorPallete } from "../../../assets/color";
import HandShakeIcon from "../../../assets/Icons/svg/handshakeIcon";
import SearchIcon from "../../../assets/Icons/svg/searchIcon";
import BoxIcon from "../../../assets/Icons/svg/boxIcon";
import ListIcon from "../../../assets/Icons/svg/listIcon";
import FilterIcon from "../../../assets/Icons/svg/filterIcon";
import "../index.css";
import VersionsListView from "./versionsListView";
import useModal from "../../../hooks/useModal";
import DeleteVersionModal from "../../../components/modals/DeleteVersionModal";
import { getSearchedVersions } from "../../../modules/redux/Documents/document";
import ReactPaginate from "react-paginate";
import "./index.css";


const textStyles = mergeStyles({
  fontSize: FontSizes.size24,
  fontFamily: "Poppins",
});

const retryButtonStyles: IButtonStyles = {
  root: {
    width: "20px",
    margin: "1%",
    alignItems: "center",
    fontFamily: "Poppins",
  },
};

const SearchComp = (props: any) => {
  const { t } = useTranslation();
  return (
    <HorizontalStack
      style={{
        width: "42%",
        justifyContent: "space-between",
        height: "100%",
        position: "relative",

      }}
    >
      <HorizontalStack style={styles.searchContainer}>
        <SearchIcon />
        <input
          style={{
            width: "350px",
            height: "18px",
            fontFamily: "Poppins",
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "16px",
          }}
          value={props.searchText}
          onChange={(e) => props.setSearchText(e.target.value)}
          // onChange={onChangeSearchField}
          placeholder={String(t("search_negotiation_history"))}
          // disabled
        />
      </HorizontalStack>
      <Stack
        style={{
          backgroundColor: props.listView ? colorPallete.btLightBlue : "white",
          ...styles.listView,
        }}
        title={String(t("list_view"))}
        onClick={() => {
          props.setListView(!props.listView);
        }}
      >
        <ListIcon />
      </Stack>
      <Stack
        style={{
          backgroundColor: !props.listView ? colorPallete.btLightBlue : "white",
          ...styles.boxView,
        }}
        onClick={() => {
          props.setListView(!props.listView);
        }}
        title={String(t("box"))}
      >
        <BoxIcon />
      </Stack>
      <Stack
        style={{
          backgroundColor:
            props.filter || props.filterOption !== 0
              ? colorPallete.btLightBlue
              : "white",
          position: "relative",
          ...styles.boxView,
        }}
        title={String(t("filter"))}
        onClick={() => {
          props.setFilter(!props.filter);
        }}
      >
        <FilterIcon />
        {props.filterOption !== 0 ? <Stack className="redDot"></Stack> : null}
      </Stack>
      {props.filter ? (
        <Stack
          style={{
            ...styles.filterContainer,
            position: "absolute",
          }}
        >
          {props.filterOptions.map((item: any, i: any) => {
            return (
              <Text
                key={item}
                className="filterItem"
                onClick={() => {
                  props.setFilterOption(i);
                  props.setFilter(!props.filter);
                }}
                style={{
                  backgroundColor:
                    props.filterOption == i ? colorPallete.btLightBlue : "",
                }}
              >
                {item}
              </Text>
            );
          })}
        </Stack>
      ) : null}
    </HorizontalStack>
  );
};

export const VersionsInDoc: FC<any> = (props) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [itemsToShow, setItemToShow] = useState(0);
  const [versionData, setVersionData] = useState<Document[]>([]);

  // const searchVersionState = useAppSelector(
  //   (state: { fetchSearchedVersions: any }) => state.fetchSearchedVersions
  // );
  const [listView, setListView] = useState<boolean>(false);
  const [filter, setFilter] = useState<boolean>(false);
  const [filterOption, setFilterOption] = useState<number>(0);
  const [view, setView] = useState<boolean>(false);
  const { isModalOpen, showModal, hideModal } = useModal();
  const [deleteVersion, setDeleteversion] = useState<Document>();
  const [searchText, setSearchText] = useState("");
  const [left, setLeft] = useState<number>(0);
  const element = document.getElementById("myVersionStack");
  let distance = left;
  const filterOptions = [
  t("all_documents"),
  t("draft_docs_only"),
  t("received_docs_only"),
  t("sent_docs_only")
  ];
  useEffect(() => {
    setSearchText("");
  }, [props?.selectedId]);

  
  const pageSize = 15;
  const [currentPage, setCurrentPage] = useState(0);

  const handlePageClick = (data: any) => {
    setCurrentPage(data.selected);
  }

  const versionState = useAppSelector(
    searchText.length === 0
      ? (state: { fetchDocumentVersion: any }) => state.fetchDocumentVersion
      : (state: { fetchSearchedVersions: any }) => state.fetchSearchedVersions
  );
  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    if (screenWidth < 500) {
      setItemToShow(1);
    } else if (screenWidth < 720 && screenWidth > 500) {
      setItemToShow(2);
    } else if (screenWidth > 720 && screenWidth < 965) {
      setItemToShow(3);
    } else if (screenWidth > 965 && screenWidth < 1210) {
      setItemToShow(4);
    } else if (screenWidth > 1210 && screenWidth < 1781) {
      setItemToShow(5);
    } else if (screenWidth > 1781 && screenWidth < 2000) {
      setItemToShow(6);
    } else {
      setItemToShow(10);
    }
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [screenWidth]);
  useEffect(() => {
    if (searchText.length) {
      dispatch(
        getSearchedVersions({
          rootDocumentId: props.selectedId,
          searchParam: searchText,
        })
      );
    }
  }, [searchText]);

  useEffect(() => {
    if (left && element) {
      element.scrollLeft += left;
    }
  }, [left]);

  useEffect(() => {
    if (versionState.data) {
      setVersionData(versionState.data);
    }
  }, [versionState]);

  function getFilterData() {
    let l = versionData.filter((version: Document) => {
    
      if (filterOption == 1 && version.fileType === "draft") {
        return version;
      } else if (filterOption == 2 && version.fileType === "received") {
        return version;
      } else if (filterOption == 3 && version.fileType === "sent") {
        return version;
      } else if (filterOption == 0) {
        return version;
      }
     
    });

    if (l.length > 0) {
    
      if (!listView) {
  
        if(!view){
 
          return l .map(
            (version: Document,index:any) =>
              version.documentVersion ? (
                <DocumentVersionInCard
                  key={index}
                  version={version}
                  members={props.projectData.membersOfProject}
                  length={versionState.data.length}
                  showModal={showModal}
                  setDeleteversion={setDeleteversion}
                />
              ) : null
          );
        }
        else{
          return(
          <div className="container" style={{backgroundColor:"inherit"}}>
            <div className="stack">
            {
             l 
              .slice(currentPage * pageSize, (currentPage + 1) * pageSize)
              .map(
                (version: Document,index:any) =>
                version.documentVersion ? (
                    <DocumentVersionInCard
                      key={index}
                      version={version}
                      members={props.projectData.membersOfProject}
                      length={versionState.data.length}
                      showModal={showModal}
                      setDeleteversion={setDeleteversion}
                    />
                  ) : null
              )
            }
            </div>
         
            { 
            l.length > pageSize && (
                  <ReactPaginate
                    previousLabel={'<'}
                    nextLabel={'>'}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={Math.ceil(l.length / pageSize)}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={1}
                    onPageChange={handlePageClick}
                    containerClassName={'pagination'}
                  activeClassName={'active'}
                  />
                )
                }
            </div>

          )
        }
      } else {
        return (
          <VerticalStack
            style={{
              alignSelf: "flex-start",
              width: "100%",
              height: view ? "" : "21.3rem",
              overflowY: view ? "visible" : "scroll",
              borderRadius: "8px",
              padding: "10px",
              gap: "10px",
              backgroundColor: "#E8ECF4",
              boxShadow: "0px 4px 10px 0px #0000001F",
              marginTop: "14px",
              marginBottom: "15px",
              marginLeft: "0.6%",
              marginRight: "0.6%",
              border: "1px solid #FFFFFF",
            }}
          >
            {l.map(
              (version: Document) =>
                version.documentVersion && (
                  <VersionsListView
                    key={version.documentId}
                    version={version}
                    view={view}
                    showModal={showModal}
                    setDeleteversion={setDeleteversion}
                  />
                )
            )}
          </VerticalStack>
        );
      }
    } else {
      return (
        <Stack style={styles.emptyContainer}>
          <Text style={{ ...styles.headerStyle, fontSize: "28px" }}>
            {t("no_versions_found")}
          </Text>
        </Stack>
      );
    }
  }

  const loaderJSX = (
    <Stack className={mergeStyles(styles.loaderStyles)}>
      <Loader />
    </Stack>
  );

  const versionErrorJSX =
    !props.documentState.isLoading &&
    !versionState.isLoading &&
    versionState.error !== "" ? (
      <Stack className={mergeStyles(styles.errorStyles)}>
        <Text className={textStyles}>{t("loader")}</Text>
        <PrimaryButton
          styles={retryButtonStyles}
          onClick={() => props.setVersionError(!props.versionError)}
        >
          {t("retry")}
        </PrimaryButton>
      </Stack>
    ) : (
      !props.documentState.isLoading &&
      !versionState.isLoading &&
      props.documentState.data?.length === 0 && (
        <Stack className={mergeStyles(styles.errorStyles)}>
          <Text className={textStyles}>{t("no_versions_found")}</Text>
        </Stack>
      )
    );

  const versionDataJSX =
    !props.documentState.isLoading && versionState.isLoading && loaderJSX ? (
      loaderJSX
    ) : versionErrorJSX ? (
      versionErrorJSX
    ) : (
      <>
        {!versionState.isLoading &&
          versionData &&
          versionData.length !== 0 &&
          versionData[0].folderName === props.projectData.projectName && (
            <>{getFilterData()}</>
          )}
        {versionData.length === 0 && (
          <Stack className={mergeStyles(styles.errorStyles)}>
            <Text className={textStyles}>{t("no_versions_found")}</Text>
          </Stack>
        )}
      </>
    );

    const Buttons = () => {
      return (
        <>
          <Stack
            style={{
              ...styles.ScrollStyles,
              position: "absolute",
              left:0
            }}
            onClick={() => {
              if (left > 0) {
                let a = 0;
                setLeft((a -= 1200));
              } else {
                setLeft((distance -= 400));
              }
            }}
          >
            {versionState.data && versionState.data.length !== 0 && (
              <img
                src={require("../../../assets/Icons/LeftScroll.png")}
                style={{ cursor: "pointer" }}
                alt=""
              />
            )}
          </Stack>
          <Stack
            style={{
              ...styles.ScrollStyles,
              position: "absolute",
              right: 10,
            }}
            onClick={() => {
              if (left < 0) {
                let a = 0;
                setLeft((a += 1200));
              } else {
                setLeft((distance += 400));
              }
            }}
          >
            {versionState.data && versionState.data.length !== 0 && (
              <img
                src={require("../../../assets/Icons/RightScroll.png")}
                style={{ cursor: "pointer" }}
                alt=""
              />
            )}
          </Stack>
        </>
      );
    };

  return (
    <>
      {
        <Stack className={mergeStyles({...styles.versionContainer,position:'relative'})}>
          <HorizontalStack horizontalAlign="space-between">
            <HorizontalStack
              horizontalAlign="space-between"
              style={{
                width: "99%",
                height: "100%",
                backgroundColor: "",
              }}
            >
              <VerticalStack style={{ width: "100%", zIndex: 5 }}>
                <HorizontalStack
                  style={{
                    width: "100%",
                    paddingBottom: "2px",
                    justifyContent: "space-between",
                  }}
                >
                  <HorizontalStack style={{ width: "60%" }}>
                    <HandShakeIcon />
                    <Text style={styles.headerStyle}>
                      {!versionState.isLoading &&
                        props.selectedDocument?.split(".docx")}
                    </Text>
                  </HorizontalStack>
                  <SearchComp
                    searchText={searchText}
                    setSearchText={setSearchText}
                    listView={listView}
                    setListView={setListView}
                    filter={filter}
                    setFilter={setFilter}
                    filterOption={filterOption}
                    setFilterOption={setFilterOption}
                    filterOptions={filterOptions}
                  />
                </HorizontalStack>
                <HorizontalStack style={{ alignItems: "center" }}>
                  <Text style={styles.DocNameStyle}>
                    {t("negotiation_history")}
                  </Text>
                  {versionData && (
                    <ViewAll
                      onClickFunction={() => {
                        setView(!view);
                      }}
                      collapsed={view}
                    />
                  )}
                </HorizontalStack>
              </VerticalStack>
            </HorizontalStack>
          </HorizontalStack>
          {versionState.data && versionState.data.length > 0 && !view ? (
            <HorizontalStack className={mergeStyles(styles.versionCard)} id ='myVersionStack'>
              {!listView ? <Buttons/> : null}
              {versionDataJSX}
            </HorizontalStack>
          ) : (
            <HorizontalStack
              className={mergeStyles(styles.expandedDocumentContainer)}
            >
              {versionDataJSX}
            </HorizontalStack>
          )}
        </Stack>
      }
      <DeleteVersionModal
        isModalOpen={isModalOpen}
        showModal={showModal}
        hideModal={hideModal}
        version={deleteVersion}
        refresh={props.refresh}
      />
    </>
  );
};

const styles = {
  versionContainer: {
    // height: "27rem",
    backgroundColor: colorPallete.white,
    width: "99%",
    marginBottom: 20,
    marginTop: "20px",
    paddingLeft: 18,
    paddingTop: 20,
    paddingBottom: 10,
    borderRadius: "10px",
    alignSelf: "center",
  },
  versionCard: {
    display: "flex",
    width: "99%",
    alignItems: "center",
    justifyContent: "flex-start",
    height: "23rem",
    overflowY: "hidden",
    backgroundColor: colorPallete.white,
    paddingBottom: "1%",
    paddingTop: "0.5%",
    borderRadius: "1px",
    gap: "20px",
    overflowX: 'hidden',
  },
  loaderStyles: {
    display: "flex",
    height: "100%",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  headerStyle: {
    fontSize: "20px",
    color: colorPallete.black1,
    fontWeight: 500,
    paddingLeft: "1%",
    fontFamily: "Poppins",
    lineHeight: "22px",
  },
  DocNameStyle: {
    fontSize: "18px",
    color: colorPallete.grey1,
    fontWeight: 400,
    fontFamily: "Poppins",
  },
  ScrollStyles: {
    height: "10%",
    backgroundColor: "",
    justifyContent: "flex-start",
    zIndex: 2,
    marginBottom: "20px",
  },
  errorStyles: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    height: "18rem",
    alignItems: "center",
    fontFamily: "Poppins",
  },
  listView: {
    border: `2px solid ${colorPallete.btLightBlue}`,
    borderRadius: "0.3rem",
    cursor: "pointer",
    padding: "10px 8px 10px 8px",
    alignItems: "center",
    height: "36px",
    width: "36.049px",
    justifyContent: "center",
  },
  boxView: {
    border: `2px solid ${colorPallete.btLightBlue}`,
    borderRadius: "0.3rem",
    cursor: "pointer",
    padding: "7px",
    alignItems: "center",
    height: "36px",
    width: "36.049px",
    justifyContent: "center",
  },
  searchContainer: {
    height: "36px",
    backgroundColor: "white",
    borderRadius: "4px",
    width: "375px",
    alignItems: "center",
    border: "1px solid #EAEAEA",
    paddingLeft: "12px",
  },
  filterContainer: {
    height: "150px",
    width: "213px",
    borderRadius: "4px",
    border: "1px solid #E8ECE4",
    background: "white",
    boxShadow: "0px 12px 16px -4px rgba(0,0,0,0.08)",
    right: 0,
    top: 37,
    justifyContent: "center",
  },
  emptyContainer: {
    display: "flex",
    width: "99%",
    alignItems: "center",
    justifyContent: "center",
    height: "24rem",
  },
  expandedDocumentContainer: {
    width: "100%",
    height: "auto",
    alignItems: "center",
    paddingBottom: "1%",
    paddingTop: "1%",
    paddingRight: "2%",
    flexWrap: "wrap",
    display: "flex",
    backgroundColor: "",
    gap: "20px",
    minHeight: "20rem",
  },
};
