/* eslint-disable react/prop-types */
import { IconButton, Stack, Text } from "@fluentui/react";
import React, { Dispatch, FC, SetStateAction, useState } from "react";
import EmailCircleIcon from "../../../assets/Icons/svg/emailCircleIcon";
import { useTranslation } from "react-i18next";



function validateInput(input:any) {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/.test(input)) {
    return true;
  } else {
    // error(true);
    // message("Invalid Email");
    return false;
  }
}
type EmailTextBoxProps={
    emails: string[];
    setEmails:Dispatch<SetStateAction<string[]>>;
    error: boolean;
    setError :Dispatch<SetStateAction<boolean>>;
    message:string;
    setMessage:Dispatch<SetStateAction<string>>;
}
const EmailTextBox:FC<EmailTextBoxProps>=({ emails, setEmails, error, setError, message, setMessage })=>{
  const { t } = useTranslation();

// export default function EmailTextBox({ emails, setEmails, error, setError, message, setMessage }) {
  const [input, setInput] = useState("");

  const handleKeyPress = (e:any) => {
    if (e.key === "Backspace" && input === "" && emails.length > 0) {
      // Remove the last email when Backspace is pressed with an empty input field
      const updatedEmails = [...emails];
      updatedEmails.pop();
      setEmails(updatedEmails);
    }
    if (e.key === "Enter") {
      if (validateInput(input)) {
        setError(false);
        if (!emails.includes(input)) setEmails((emails) => [...emails, input]);
        setInput("");
      } else {
        setError(true);
        setMessage("Enter Correct Email");
      }
    }
  };

  const handleRemove = (email:any) => {
    const uniqueItems = emails.filter((item) => item !== email);
    setEmails(uniqueItems);
  };
  const Email = (props:{email:string}) => {
    return (
      <Stack horizontal key={props?.email} style={{ alignItems: "center", justifyContent: "center" }}>
        <Stack
          style={{
            paddingLeft: "2px",
            paddingRight: "4px",
            marginTop: "5px",
            cursor: "pointer",
          }}
          onClick={() => {
            handleRemove(props?.email);
          }}
        >
       <IconButton style={{width:"14px",height:"14px"}} iconProps={{iconName:"StatusCircleErrorX",style:{width:"16px",height:'16px',fontSize:"17px",color:"#18468F"}}}/>
        </Stack>
        <Text
          style={{
            textDecorationLine: "underline",
            fontSize: "14px",
            fontWeight: "400",
            lineHeight: "28px",
            textUnderlinePosition: "under",
          }}
        >
          {props?.email}
          {";"}&nbsp;
        </Text>
      </Stack>
    );
  };
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "0.25rem", width: "100%", height: "fit-content" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          flexWrap: "wrap",
          alignItems: "center",
          borderBottom: "1px solid var(--LIGHT-BLUE, #E8ECF4)",
        }}
      >
        {emails?.map((email) => (
          <Email key={email} email={email} />
        ))}
        <input
          style={{ outline: "none", border: "none",  flexGrow: 1, height: "32px",minWidth:"120px" }}
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyDown={handleKeyPress}
        />
      </div>
      {error === true && (
        <Text style={{ color: "#A82C0C", marginLeft: "1%", fontSize: "9px", fontFamily: "Poppins" }}>{message}</Text>
      )}
    </div>
  );
}

export default EmailTextBox;