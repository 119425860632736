import React from "react";

function ArrowUpIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="8"
      viewBox="0 0 12 8"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.21967 7.4236C-0.0732233 7.09777 -0.0732233 6.5695 0.21967 6.24367L5.46967 0.403346C5.76256 0.0775185 6.23744 0.0775185 6.53033 0.403346L11.7803 6.24367C12.0732 6.5695 12.0732 7.09777 11.7803 7.4236C11.4874 7.74943 11.0126 7.74943 10.7197 7.4236L6 2.17323L1.28033 7.4236C0.987437 7.74943 0.512563 7.74943 0.21967 7.4236Z"
        fill="#18468F"
      />
    </svg>
  );
}

export default ArrowUpIcon;
