import { Modal, PrimaryButton, Stack, Text } from "@fluentui/react";
import React, { FC } from "react";
import SelectDocumentGroup from "./SelectDocument";
import { HorizontalStack, VerticalStack } from "../../Stack";
import CrossIcon from "../../../assets/Icons/svg/crossIcon";
import { t } from "i18next";

const selectedTextStyle = {
  color: "#323232",
  fontFamily: "Poppins",
  fontSize: "12px",
  lineHeight: "26px",
};
const DocumentIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="16"
      viewBox="0 0 12 16"
      fill="none"
    >
      <path
        d="M7.42493 0.402344H1.72874C1.37272 0.402344 1.01671 0.554242 0.731902 0.858039C0.447092 1.08589 0.304688 1.46563 0.304688 1.92133V14.0732C0.304688 14.453 0.447092 14.8327 0.731902 15.1365C1.01671 15.4403 1.37272 15.5922 1.72874 15.5922H10.273C10.629 15.5922 10.9851 15.4403 11.2699 15.1365C11.5547 14.8327 11.6971 14.453 11.6971 14.0732V4.9593M7.42493 0.402344L11.6971 4.9593L7.42493 0.402344ZM7.42493 0.402344V4.9593H11.6971"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.52395 0.637902C0.859978 0.287142 1.28846 0.101562 1.72785 0.101562H7.42404C7.50802 0.101562 7.58824 0.136319 7.64568 0.19758L11.9178 4.75454C11.9706 4.81085 12 4.88513 12 4.96232V14.0762C12 14.5329 11.8284 14.987 11.4906 15.3473C11.1524 15.708 10.7179 15.899 10.2721 15.899H1.72785C1.28208 15.899 0.847545 15.708 0.509381 15.3473C0.171599 14.987 0 14.5329 0 14.0762V1.92435C0 1.41899 0.156099 0.942804 0.52395 0.637902ZM1.72785 0.709157C1.46159 0.709157 1.1841 0.821949 0.952643 1.06883C0.942742 1.0794 0.932098 1.08924 0.920793 1.09828C0.731422 1.24978 0.607594 1.52615 0.607594 1.92435V14.0762C0.607594 14.3791 0.720805 14.6845 0.952643 14.9317C1.1841 15.1786 1.46159 15.2914 1.72785 15.2914H10.2721C10.5384 15.2914 10.8159 15.1786 11.0473 14.9317C11.2792 14.6845 11.3924 14.3791 11.3924 14.0762V5.26611H7.42404C7.25626 5.26611 7.12025 5.1301 7.12025 4.96232V0.709157H1.72785ZM7.72784 1.1736L10.995 4.65852H7.72784V1.1736Z"
        fill="#323232"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.03711 9.51864C3.03711 9.35086 3.17312 9.21484 3.34091 9.21484H8.65736C8.82514 9.21484 8.96115 9.35086 8.96115 9.51864C8.96115 9.68642 8.82514 9.82244 8.65736 9.82244H3.34091C3.17312 9.82244 3.03711 9.68642 3.03711 9.51864Z"
        fill="#323232"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.03711 11.796C3.03711 11.6282 3.17312 11.4922 3.34091 11.4922H8.65736C8.82514 11.4922 8.96115 11.6282 8.96115 11.796C8.96115 11.9638 8.82514 12.0998 8.65736 12.0998H3.34091C3.17312 12.0998 3.03711 11.9638 3.03711 11.796Z"
        fill="#323232"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.03711 7.2413C3.03711 7.07351 3.17312 6.9375 3.34091 6.9375H4.85989C5.02768 6.9375 5.16369 7.07351 5.16369 7.2413C5.16369 7.40908 5.02768 7.54509 4.85989 7.54509H3.34091C3.17312 7.54509 3.03711 7.40908 3.03711 7.2413Z"
        fill="#323232"
      />
    </svg>
  );
};
const modalStyles = {
    root: {
      background: "rgba(0, 0, 0, 0.5)",
    },
    main: {
      borderRadius: "12px",
      width: "752px",
      padding:"18px 8px 8px 8px",
      alignItems:"center",
      background:"linear-gradient(90deg, #ECF0F9 0%, #EFECF9 100%)",
 
    },
  };
const SelectedDocument: FC<any> = (props) => {
  return (
    <HorizontalStack style={{ justifyContent: "flex-start", gap: "4px",alignItems:"center",marginLeft:"2px" }}>
      <DocumentIcon />
      <Text style={selectedTextStyle}>{`${props.num}`} </Text>
      <Text style={selectedTextStyle}> {props.num > 1 ? t("documents_selected") : t("document_selected")} </Text>
    </HorizontalStack>
  );
};
const SelectDocumentModal: FC<any> = (props) => {
    const Title = () => {
        return (
          <HorizontalStack style={{justifyContent:"space-between",alignItems:"center",padding:"0 14px 10px 16px"}}>
            <Text styles={{root:{
                color:"#323232",
                fontFamily:"Poppins",
                fontSize:"18px",
                fontWeight:500,
                lineHeight:"26px"
            }}}>
              {t("selected_documents")}
              </Text>
            <Stack
              onClick={() => {
                props.hideModal();
              }}
              style={{ cursor: "pointer" }}
            >
              <CrossIcon />
            </Stack>
          </HorizontalStack>
        );
      };
  return (
    <Modal
      styles={modalStyles}
      isOpen={props.isModalOpen}
      onDismiss={props.hideModal}
      isBlocking={true}
    >
        <Title/>
      <VerticalStack styles={{root:{
        border:"1px solid #D8E1EB",
        background:"#FFF",
        boxShadow:"0px 4px 10px 0px rgba(0, 0, 0, 0.08)",
        borderRadius:"8px",
        alignItems:"center",
      }}}>
        <SelectDocumentGroup documents={props?.documents} selectedDocuments={props?.selectedDocuments} setSelectedDocuments={props?.setSelectedDocuments}/>
        <HorizontalStack style={{ justifyContent: "space-between" ,width:"100%",padding:"8px 20px",alignItems:"center"}}>
          <SelectedDocument  num={props?.selectedDocuments?.length}/>
          <HorizontalStack style={{  gap: "8px" ,alignItems:"center"}}>
            <PrimaryButton text={String(t("prepare_email"))} styles={{root:{
                padding:"12px 24px",
                backgroundColor:"#18468F",
                borderRadius:"4px",
                minWidth:"120px",
                height:"48px",
            },label:{
                color:"#F4F4F4",
                fontFamily:"Poppins",
                fontSize:"14px",
                fontWeight:500,
                lineHeight:"16px",
                margin:0
            },rootHovered:{
                backgroundColor:"#18468F",
            }}}
            onClick={()=>{props?.hideModal();props?.showShareModal();}}
            />
            <PrimaryButton text={String(t("cancel"))} styles={{root:{
                padding:"12px 24px",
                backgroundColor:"#E8ECF4",
                borderRadius:"4px",
                minWidth:"120px",
                height:"48px",
                border:"1px solid #E8ECF4"
            },label:{
                color:"#323232",
                fontFamily:"Poppins",
                fontSize:"14px",
                fontWeight:500,
                lineHeight:"16px",
                margin:0
            },rootHovered:{
                backgroundColor:"#E8ECF4",
                border:"1px solid #E8ECF4",
            }}}
            onClick={()=>{props?.setSelectedDocuments([]);props.hideModal();}}
            />
          </HorizontalStack>
        </HorizontalStack>
      </VerticalStack>
    </Modal>
  );
};

export default SelectDocumentModal;
