import {
  FontSizes,
  IButtonStyles,
  mergeStyles,
  PrimaryButton,
  Stack,
  Text,
} from "@fluentui/react";
import { FC, useEffect, useState } from "react";
import { Document } from "../../models";
import { VerticalStack } from "../../components/Stack";
import { DocumentInGrid } from "../../components/Cards/DocumentInGrid";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import {
  getSearchedDocuments,
  getPrecedentDocuments,
} from "../redux/Documents/document";
import { useTranslation } from "react-i18next";
import Loader from "../../components/Loader";
import InfiniteScroll from "react-infinite-scroll-component";
import { motion } from "framer-motion";
import { PrecedentCard } from "../../components/Cards/PrecedentCard";
import FadeTransition from "../../components/FadeTransition";
import { DocumentInCard } from "../../components/Cards/DocumentInCard";

const styles = {
  textStyles: {
    fontSize: FontSizes.size18,
  },
  errorStyles: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    height: "19rem",
    alignItems: "center",
  },
  loaderStyles: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    height: "19rem",
    alignItems: "center",
    marginTop: "1.5%",
  },
};

const retryButtonStyles: IButtonStyles = {
  root: {
    width: "20px",
    margin: "1%",
    alignItems: "center",
  },
};

export const AllPrecedentDoc: FC = () => {
  const isSearchEnabled = useAppSelector(
    (state) => state.isSearchEnabled.value
  );
  const [isError, setIsError] = useState<boolean>(false);
  const searchParam = useAppSelector((state) => state.isSearchEnabled.text);
  const view = useAppSelector((state) => state.DocumentView.value);
  const { isLoading, data, error, metadata } = useAppSelector((state) =>
    isSearchEnabled ? state.fetchSearchedDocument : state.fetchPrecedentDocument
  );

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [docData, setDocData] = useState<Document[]>([]);

  useEffect(() => {
    if (data && data.length > 0) {
      setDocData(data);
    } else {
      setDocData([]);
    }
  }, [data]);

  useEffect(() => {
    if (searchParam !== "") {
      dispatch(getSearchedDocuments({
        documentName:searchParam,
        limit: 21,
        offset: 0,
      }));
    }
  }, [searchParam]);

  useEffect(() => {
    if(data.length === 0 || isError || metadata==null)
    dispatch(
      getPrecedentDocuments({
        limit: 30,
        offset: 0,
      })
    );
  }, [isError]);

  const fetchMoreData = () => {

    let newOffSet = metadata.offset + metadata.limit;
    let searchPayload={
      documentName:searchParam ,
      limit: 21,
      offset: newOffSet,
    };
    let payload = {
      limit: 12,
      offset: newOffSet,
    };

    if (metadata.offset < metadata.count) {
      dispatch(isSearchEnabled ? getSearchedDocuments(searchPayload) : getPrecedentDocuments(payload)).then((res: any) => {
        if (res.payload.data && res.payload.data.length > 0) {
          setDocData([...docData, ...res.payload.data]);
        }
      });
    }
  };
  const loaderJSX =
    isLoading && docData.length === 0 ? (
      <Stack className={mergeStyles(styles.loaderStyles)}>
        <Loader />
      </Stack>
    ) : null;

  const errorJSX =
    error === "Network Error" ? (
      <Stack className={mergeStyles(styles.errorStyles)}>
        <Text className={mergeStyles(styles.textStyles)}>{t("loader")}</Text>
        <PrimaryButton
          styles={retryButtonStyles}
          onClick={() => setIsError(!isError)}
        >
          {t("retry")}
        </PrimaryButton>
      </Stack>
    ) : (
      data.length === 0 && (
        <Stack className={mergeStyles(styles.errorStyles)}>
          <Text className={mergeStyles(styles.textStyles)}>
            {t("no_prededent_files_found")}
          </Text>
        </Stack>
      )
    );

  return (
    <>
      {loaderJSX ? (
        loaderJSX
      ) : !isLoading && errorJSX ? (
        errorJSX
      ) : docData.length > 0 ? (
        view ? (
          <Stack style={{width:"110%"}}>
            <InfiniteScroll
              dataLength={docData.length}
              next={fetchMoreData}
              hasMore={docData.length < metadata.count}
              loader={
                <Stack
                  style={{
                    padding: "5%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Loader />
                </Stack>
              }
              style={{
                width: "95%",
                height: "100%",
                display: "flex",
                backgroundColor: "",
                flexWrap: "wrap",
                // paddingLeft: "1rem",
                overflowY: "hidden",
                gap:"18px"
              }}
            >
              {docData.map((document: Document, i) => {
                return (
                  document.fileExtension !== "pdf" && 
                  document.documentVersion && (
                    <motion.div
                      key={document.documentId}
                      animate={{
                        opacity: 1,
                        translateX: 0,
                        transition: {
                          duration: 0.4,
                          delay: i * 0.3,
                        },
                      }}
                      initial={{
                        opacity: 0,
                        translateX: 50,
                        x: 0,
                      }}
                      style={{
                        backgroundColor: "",
                        height: "208px",
                        width: "154px",
                      }}
                    >
                      <DocumentInCard
                        key={document.documentId}
                        document={document}
                      />
                    </motion.div>
                  )
                );
              })}
            </InfiniteScroll>
          </Stack>
        ) : (
          <FadeTransition>
          <VerticalStack
            style={{
              backgroundColor: "",
              alignItems: "center",
              paddingBottom: 30,
            }}
          >
            <InfiniteScroll
              dataLength={docData.length}
              next={fetchMoreData}
              hasMore={docData.length < metadata.count}
              loader={
                <Stack
                  style={{
                    padding: "5%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Loader />
                </Stack>
              }
              style={{
                display: "flex",
                width: "100%",
                height: "100%",
                backgroundColor: "",
                flexWrap: "wrap",
              }}
            >
              {docData.map((document: Document) => {
                return (
                  document.fileExtension !== "pdf" && (
                    <Stack
                      style={{
                        backgroundColor: "",
                        width: "100%",
                        alignItems: "center",
                      }}
                    >
                      <DocumentInGrid
                        key={document.documentId}
                        document={document}
                      />
                    </Stack>
                  )
                );
              })}
            </InfiniteScroll>
          </VerticalStack>
          </FadeTransition>
        )
      ) : null}
    </>
  );
};
