import React from "react";

function ArrowDownIcon(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="8"
      viewBox="0 0 12 8"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.7803 0.40355C12.0732 0.729377 12.0732 1.25765 11.7803 1.58347L6.53033 7.4238C6.23744 7.74963 5.76256 7.74963 5.46967 7.4238L0.21967 1.58347C-0.0732232 1.25765 -0.0732231 0.729377 0.21967 0.40355C0.512563 0.0777224 0.987437 0.0777224 1.28033 0.40355L6 5.65392L10.7197 0.40355C11.0126 0.0777228 11.4874 0.0777229 11.7803 0.40355Z"
        fill="#18468F"
      />
    </svg>
  );
}

export default ArrowDownIcon;
