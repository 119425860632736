import React from "react";

function ProjectIcon() {
  return (
    <svg
      width="29"
      height="38"
      viewBox="0 0 29 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.7"
        y="0.7"
        width="27.6"
        height="36.6"
        fill="white"
        stroke="white"
        stroke-width="0.6"
      />
      <rect
        x="1.5"
        y="1.5"
        width="26"
        height="35"
        fill="white"
        stroke="#323232"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.7996 15.4C11.7996 14.9029 12.1331 14.5 12.5445 14.5H21.8548C22.2662 14.5 22.5996 14.9029 22.5996 15.4C22.5996 15.8971 22.2662 16.3 21.8548 16.3H12.5445C12.1331 16.3 11.7996 15.8971 11.7996 15.4Z"
        fill="#323232"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.39982 27.0992C6.39982 26.6022 6.73329 26.1992 7.14465 26.1992H16.455C16.8663 26.1992 17.1998 26.6022 17.1998 27.0992C17.1998 27.5963 16.8663 27.9992 16.455 27.9992H7.14465C6.73329 27.9992 6.39982 27.5963 6.39982 27.0992Z"
        fill="#E0E0E0"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.39982 30.7008C6.39982 30.2037 6.73329 29.8008 7.14465 29.8008H16.455C16.8663 29.8008 17.1998 30.2037 17.1998 30.7008C17.1998 31.1978 16.8663 31.6008 16.455 31.6008H7.14465C6.73329 31.6008 6.39982 31.1978 6.39982 30.7008Z"
        fill="#E0E0E0"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.39982 9.10117C6.39982 8.60412 6.90002 8.20117 7.51706 8.20117H21.4826C22.0996 8.20117 22.5998 8.60412 22.5998 9.10117C22.5998 9.59823 22.0996 10.0012 21.4826 10.0012H7.51706C6.90002 10.0012 6.39982 9.59823 6.39982 9.10117Z"
        fill="#323232"
      />
      <circle
        cx="1.8"
        cy="1.8"
        r="1.8"
        transform="matrix(-1 0 0 1 10.0003 13.6016)"
        fill="#753815"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.7998 21.4C11.7998 20.9029 12.1333 20.5 12.5446 20.5H21.855C22.2663 20.5 22.5998 20.9029 22.5998 21.4C22.5998 21.8971 22.2663 22.3 21.855 22.3H12.5446C12.1333 22.3 11.7998 21.8971 11.7998 21.4Z"
        fill="#323232"
      />
      <circle
        cx="1.8"
        cy="1.8"
        r="1.8"
        transform="matrix(-1 0 0 1 10 19.6016)"
        fill="#18468F"
      />
    </svg>
  );
}

export default ProjectIcon;
