import { FC, FormEvent, useCallback, useEffect, useState } from "react";
import { Document } from "../../../models";
import "./SelectedDocument.css";
import Draft from "./icons/Draft";
import Recieved from "./icons/Received";
import Sent from "./icons/Sent";
import { Text } from "@fluentui/react";
import Cross from "./icons/Cross";
import { HorizontalStack } from "../../Stack";
import { t } from "i18next";

type SelectedDocumentProps = {
  document: Document;
  setSelectedDocuments: React.Dispatch<React.SetStateAction<Document[]>>;
};
type SelectedDocumentsProps = {
  documents: Document[];
  setSelectedDocuments: React.Dispatch<React.SetStateAction<Document[]>>;
};
const SelectedDocumentStyles = {
  width: "208px",
  height: "32px",
  padding: "6px 10px",
  alignItems: "center",
  justifyContent: "space-between",
  borderRadius: "6px",
  border: "1px solid var(--LIGHT-BLUE, #E8ECF4)",
  background: "#F3F5F9",
  fontFamily: "Poppins",
  fontSize: "9px",
  fontWeight: 500,
};

const moreSelectedStyles = {
  height: "32px",
  padding: "6px 9px",
  alignItems: "center",
  gap: "6px",
  borderRadius: "6px",
  border: "1px solid var(--LIGHT-BLUE, #E8ECF4)",
  backgroundColor: "#F3F5F9",
  color: "#18468F",
  fontFamily: "Poppins",
  fontSize: "9px",
  fontWeight: 500,
  cursor: "pointer",
};

const versionDocumentStyle = {
  padding: "4px",
  justifyContent: "center",
  alignItems: "center",
  gap: "8px",
  borderRadius: "4px",
  border: "1px solid var(--LIGHT-BLUE, #E8ECF4)",
  backgroundColor: "#FFF",
  fontFamily: "Poppins",
  fontSize: "10px",
  fontWeight: 500,
  color: "#18468F",
};

const SelectedDocument: FC<SelectedDocumentProps> = (props) => {

  const handleRemoveDocument = () => {
    props?.setSelectedDocuments((prevSelectedDocuments) =>
      prevSelectedDocuments.filter(
        (doc) => doc.rootDocumentId !== props.document.rootDocumentId
      )
    );
  };
  return (
    <div style={{ position: "relative" }}>
      <div
        style={{
          position: "absolute",
          top: "-7px",
          right: "0",
          transform: "translate(50%, 0)",
          cursor: "pointer",
        }}
        onClick={handleRemoveDocument}
      >
        <Cross />
      </div>
      <HorizontalStack style={SelectedDocumentStyles}>
        <HorizontalStack style={{  gap: "5px", alignItems: "center" }}>
          {props?.document?.fileType === "draft" ? (
            <Draft />
          ) : props?.document?.fileType === "received" ? (
            <Recieved />
          ) : (
            <Sent />
          )}
          <Text
            style={{ fontFamily: "Poppins", fontSize: "10px", fontWeight: 600,  overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: 'nowrap',width:"150px" }}
          >
            {props?.document?.documentName?.replace(/\.[^/.]+$/, "")}
          </Text>
        </HorizontalStack>
        <HorizontalStack style={versionDocumentStyle}>
          {`V${props?.document?.documentVersion}`}
        </HorizontalStack>
      </HorizontalStack>
    </div>
  );
};

const MoreSelectedDocuments: FC<any> = (props) => {
  return (
    <HorizontalStack
      style={moreSelectedStyles}
      onClick={() => {
        props.setIsExpanded(!props.isExpanded);
      }}
    >
      {props?.isExpanded && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="6"
          height="10"
          viewBox="0 0 6 10"
          fill="none"
        >
          <path
            d="M5 9L1 5L5 1"
            stroke="#18468F"
            strokeWidth="1.2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}
      {props?.isExpanded ? t("less") : (
        <Text style={{ 
           color: "#18468F",
        fontFamily: "Poppins",
        fontSize: "9px",
        fontWeight: 500,}} >
      { `${props?.extraCount} `} {t("more")}
        </Text>
         
         )}
      {!props?.isExpanded && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="6"
          height="10"
          viewBox="0 0 6 10"
          fill="none"
        >
          <path
            d="M1 1L5 5L1 9"
            stroke="#18468F"
            strokeWidth="1.2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}
    </HorizontalStack>
  );
};

const SelectedDocuments: FC<SelectedDocumentsProps> = (props) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <HorizontalStack
      style={{
        gap: "8px",
        flexWrap: "wrap",
      }}
    >
      {props.documents.map((selectedDoc, index) => {
        if (isExpanded || index < 3) {
          return (
            <SelectedDocument
              document={selectedDoc}
              key={index}
              setSelectedDocuments={props?.setSelectedDocuments}
            />
          );
        }
        return null;
      })}
      {props.documents.length > 3 ? (
        <MoreSelectedDocuments
          extraCount={props.documents.length - 3}
          isExpanded={isExpanded}
          setIsExpanded={setIsExpanded}
        />
      ) : null}
    </HorizontalStack>
  );
};

export default SelectedDocuments;
